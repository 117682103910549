import React from 'react';
import './EnvironmentBanner.scss';

const BannerContainer = (props) => (
  <div className="env-banner" style={{ backgroundColor: props.bgColor, color: props.color }}>
    MediaVu {props.env} Environment
  </div>
);

export default BannerContainer;
