import ActionTypes from '../actions/ActionTypes';

export const PlaybackReducer = (
  state = {
    url: '',
    token: '',
    mediaGroup: {},
    playingMedia: {},
    canCaptureNewFrame: true,
    width: '800',
    height: '600',
    screenX: 0,
    screenY: 0
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_PLAYER_DIMENTION_SIZE: {
      return {
        ...state,
        width: action.size.width,
        height: action.size.height,
        screenX: action.location.screenX,
        screenY: action.location.screenY
      };
    }
    case ActionTypes.ENABLE_CAPTURE_FRAME: {
      return {
        ...state,
        canCaptureNewFrame: action.payload
      };
    }
    case ActionTypes.PLAYBACK_TOKEN:
    case ActionTypes.PLAYBACK_TOKEN_CLEAR: {
      return {
        ...state,
        url: action.payload,
        token: action.token
      };
    }
    case ActionTypes.PLAYBACK_ACTIVATE: {
      return {
        ...state,
        token: action.token
      };
    }
    case ActionTypes.PLAYBACK_MEDIA: {
      return {
        ...state,
        mediaGroup: action.payload,
        playingMedia: action.playingMedia
      };
    }
    case ActionTypes.UPDATE_PLAYING_MEDIA: {
      return {
        ...state,
        playingMedia: action.payload
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        url: '',
        token: '',
        mediaGroup: {},
        playingMedia: {},
        canCaptureNewFrame: true,
        width: '800',
        height: '600',
        screenX: 0,
        screenY: 0
      };
    }
  }

  return state;
};
