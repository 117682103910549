import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

// Routes
import * as ROUTES from 'src/constants/Routes';

class MessagePreview extends Component {
  formatDate = (date) => {
    return moment(date).isBefore(moment().subtract(3, 'd')) ? moment(date).format('MMM DD') : moment(date).fromNow();
  };

  openMessages = () => {
    this.props.history.replace(`${ROUTES.MESSAGES}/${this.props.message.messageID}`);
  };

  stripHTMLTagsfromString = (value) => {
    return value.replace(/&#160;/g, ' ').replace(/<[^>]+>/g, '');
  };

  render() {
    return (
      <li className="message-preview " onClick={this.openMessages}>
        <div className="message-heading">
          <span className="message-preview__sender">{this.props.message.sender}</span>
          <span className="timestamp">
            {' '}
            {this.props.message.hasAttachment && <i className="fa fa-paperclip has-attachment" />}
            {this.formatDate(this.props.message.date)}
          </span>
          <div className="subject message-preview__subject message-preview__item--truncate">{this.props.message.subject}</div>
        </div>
        <div className="message-content message-preview__content message-preview__item--truncate">
          {this.stripHTMLTagsfromString(this.props.message.message)}
        </div>
      </li>
    );
  }
}
export default withRouter(MessagePreview);
