export const VALIDATED_DEP_IDS = ['3', '6264', '6267', '6266', '6230', '6265', '6077'];

export const VETTED_CATEGORIES = [
    {Ids: ['6264', '6265'], name: 'PEACOCK'},
    {Ids: ['3'], name:  'BROADCAST'},
    {Ids: ['6077'], name: 'HISPANIC'},
    {Ids: ['6230'], name: 'ELG'},
    {Ids: ['6266'], name: 'GOLF'},
    {Ids: ['6267'], name: 'NEWS'},
    {ids: ['375'], name: 'SPECIAL EVENT'},
];

export const SPECIAL_EVENT = {
    TYPE: ['375', '376'],
    NAME: 'SPECIAL EVENT',
}

// MVU-5622 todo: revert after olympics
export const VETTED_CATEGORIES_OLYMPICS = [
    ...VETTED_CATEGORIES.slice(0, VETTED_CATEGORIES.length - 1),
    {ids: ['375'], name: 'OLYMPICS'},
]

export const SPECIAL_EVENT_OLYMPICS = {
    TYPE: ['375', '376'],
    NAME:  'OLYMPICS',
}

export const APPROVED_RATINGS = ['Approved', 'Approved with Restrictions', 'Not Approved'];

export const APPROVAL_GROUP_ID = ['5', '375', '376','377'];

export const APPROVED_NBC_DEP_IDS = {
    '3': 'NBC',
    '6264': 'NBC Peacock',
    '6267': 'News',
    '6266': 'Oxygen',
    '6230': 'NBC Cable',
    '6257': 'Uni Kids',
    '6265': 'Peacock - Hispanic',
    '6077': 'Telemundo'
}

export const PEACOCK_EXCLUSIVE_MSG = "Note, PEACOCK and PEACOCK-HISPANIC cannot both be selected."

export const PEACOCK_IDS = {
    PEACOCK: '6264',
    PEACOCK_H: '6265'
}

// mvu-5615 olympics 2024
export const SPECIAL_EVENT_DEPTS = [
    // NBC
    '3', '6264',
    // NBC Cable
    '6230', '6257',
    // Telemundo
    '6077', '6265',
    // NY Test2
    '6250', '6251', '6254'
]