export function parseMediaRatings(broadcasterType, staticData, mediaTypeId, authUser, departmentId) {
  const { mediaRatingTypes, mediaRatings } = staticData.data.broadcasterUserStaticInfo[broadcasterType];
  const MediaRatingTypes = mediaRatingTypes.slice().sort((a, b) => 2 * (parseInt(a.displayOrder, 10) > parseInt(b.displayOrder, 10)) - 1);
  const userDepartmentIds = departmentId
    ? departmentId
    : authUser.broadcasterUsersInfo[broadcasterType].user.departments.map(({ departmentID }) => departmentID);

  MediaRatingTypes.forEach((mediaRatingType) => {
    mediaRatingType.mediaRatings = mediaRatings
      .filter(
        (mediaRating) =>
          mediaRating.departments.find(({ departmentID }) => userDepartmentIds.indexOf(departmentID) !== -1) &&
          parseInt(mediaRating.type, 10) === parseInt(mediaRatingType.id, 10) &&
          mediaRating.active.toString() === 'true'
      )
      .sort((a, b) => 2 * (parseInt(a.displayOrder, 10) > parseInt(b.displayOrder, 10)) - 1);
  });

  return MediaRatingTypes.filter((mediaRatingType) => mediaRatingType.mediaRatings.length);
}

export function parseSpecialEventRatings(deptIds, staticData) {
  const { mediaRatingTypes } = staticData;
  const deptIdsMapped = deptIds.map((d) => d.departmentID);

  if (Array.isArray(mediaRatingTypes)) {
    const olympicRatingType = mediaRatingTypes.find((type) => type.name.toLowerCase().includes('special'));
    if (olympicRatingType) {
      return olympicRatingType.mediaRatings.filter((rating) => {
        const departments = rating.departments.map((d) => d.departmentID);

        return deptIdsMapped.some((id) => departments.includes(id));
      });
    }
  }

  return [];
}

export function parseRequestedMaterials(broadcasterType, staticData) {
  const { mediaTypes } = staticData.data.broadcasterUserStaticInfo[broadcasterType];

  return mediaTypes
    .filter((mediaType) => mediaType.attachable.toString() === 'true')
    .sort((a, b) => 2 * (a.name.toLowerCase() > b.name.toLowerCase()) - 1)
    .map((mediaType) => ({
      id: mediaType.id,
      value: mediaType.name,
      label: mediaType.name
    }));
}

export const getDepartmentsName = (selectedDepartments, departments) => {
  let deptNames = {};
  if (selectedDepartments.length > 0) {
    selectedDepartments.forEach((deptId) => {
      const departmentInfo = departments.find(({ departmentID }) => departmentID === deptId);
      if (departmentInfo) {
        deptNames[deptId] = departmentInfo.departmentName;
      }
    });
  } else {
    deptNames = '';
  }

  return deptNames;
};

export const filteredRatingsDepartments = (ratings, deptId) => {
  if (deptId) {
    return ratings.filter(({ departmentId, departmentID }) => departmentId === deptId || departmentID === deptId);
  } else {
    return ratings;
  }
};
