import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/ErrorActions';
import * as Utils from '../../utils/Utils';

class GenericErrorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      id: null
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState(
      {
        error: error,
        errorInfo: errorInfo,
        id: Utils.randomID()
      },
      function () {
        var stack = JSON.stringify(this.state.error.stack);
        var message = JSON.stringify(this.state.errorInfo);

        var errorPayload = {
          id: this.state.id,
          message: stack + '\n' + message
        };

        this.props.logError(errorPayload, null, null, null);
      }
    );
  }

  render() {
    //UI Error
    if (this.state.errorInfo) {
      return (
        <div className="card">
          <h4 className="card-heading">Sorry, we have encountered an error. Please contact Client Services if this persists.</h4>
          <p className="card-body" style={{ whiteSpace: 'pre-wrap' }}>
            Error ID: {this.state.id}
          </p>
        </div>
      );
    } else if (this.props.error.errorType === this.props.errorType) {
      //API error
      return (
        <div className="card">
          <h4 className="card-heading">Sorry, we have encountered an error. Please contact Client Services if this persists.</h4>
          <p className="card-body" style={{ whiteSpace: 'pre-wrap' }}>
            Error ID: {this.props.error.errorCode}
          </p>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

function mapStateToProps(state) {
  return {
    error: state.error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logError: function (error, type, requestPayload, headers) {
      return dispatch(actions.logError(error, type, requestPayload, headers));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GenericErrorComponent);
