import React from 'react';

const LightTheme = React.lazy(() => import('./lightTheme'));
const DarkTheme = React.lazy(() => import('./darkTheme'));
const theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';

const ThemeSelector = ({ children }) => (
  <>
    <React.Suspense fallback={<></>}>
      {theme === 'dark' ? <DarkTheme /> : <LightTheme />}
      {children}
    </React.Suspense>
  </>
);

export default ThemeSelector;
