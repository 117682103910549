/* eslint-disable */
/*
 *
 * Reducers
 *
 *   Reducers take in actions and update part of application state.
 *
 *   We combine all reducers into a single object before updated data is dispatched (sent) to store
 *   Your entire applications state (store) is just whatever gets returned from all your reducers
 *
 *     const allReducers = combineReducers({
 *      users
 *   });
 *
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

import ActionTypes from '../actions/ActionTypes';
import * as constants from '../components/package/Constants';

export const UploadSubmissionFileReducer = (
  state = {
    error: '',
    data: ''
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.SUBMISSION_FILE_STAGE_SUCCESS: {
      return {
        ...state,
        error: '',
        data: {
          ...state.data,
          [action.payload.fileName]: action.payload
        }
      };
    }

    case ActionTypes.SUBMISSION_FILE_UPLOAD_PROGRESS:
    case ActionTypes.SUBMISSION_FILE_UPLOAD_SUCCESS: {
      state.data[action.payload.fileName] = {
        ...state.data[action.payload.fileName],
        ...action.payload
      };
      return {
        ...state
      };
    }
    case ActionTypes.SUBMISSION_FILE_STAGE_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case ActionTypes.SUBMISSION_FILE_UPLOAD_FAILURE: {
      state.error[action.payload.error] = {
        ...state.error[action.payload.error],
        ...action.payload
      };
      return {
        ...state
      };
    }

    case ActionTypes.SUBMISSION_FILE_METADATA_UPDATE:
    case ActionTypes.SUBMISSION_FILE_APPLY_SUCCESS: {
      state.data[action.payload.fileName] = {
        ...state.data[action.payload.fileName],
        ...action.payload
      };
      return {
        ...state
      };
    }

    case ActionTypes.SUBMISSION_FILE_DELETE_SUCCESS: {
      return {
        ...state,
        error: '',
        data: action.payload
      };
    }

    case ActionTypes.SUBMISSION_FILES_CLEAR: {
      return {
        ...state,
        error: '',
        data: action.payload
      };
    }
    case ActionTypes.SIGN_OUT: {
      return {
        error: '',
        data: ''
      };
    }
  }

  return state;
};

export const UploadAttachmentFileReducer = (
  state = {
    error: '',
    data: ''
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.ATTACHMENT_FILE_STAGE_SUCCESS: {
      return {
        ...state,
        error: '',
        data: {
          ...state.data,
          [action.payload.fileName]: action.payload
        }
      };
    }

    case ActionTypes.ATTACHMENT_FILE_UPLOAD_PROGRESS:
    case ActionTypes.ATTACHMENT_FILE_UPLOAD_SUCCESS: {
      state.data[action.payload.fileName] = {
        ...state.data[action.payload.fileName],
        ...action.payload
      };
      return {
        ...state
      };
    }
    case ActionTypes.ATTACHMENT_FILE_STAGE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
      break;

    case ActionTypes.ATTACHMENT_FILE_UPLOAD_FAILURE:
      state.error[action.payload.fileName] = {
        ...state.error[action.payload.fileName],
        ...action.payload
      };
      return {
        ...state
      };

    case ActionTypes.ATTACHMENT_FILE_METADATA_UPDATE:
    case ActionTypes.ATTACHMENT_FILE_APPLY_SUCCESS:
      state.data[action.payload.fileName] = {
        ...state.data[action.payload.fileName],
        ...action.payload
      };
      return {
        ...state
      };

    case ActionTypes.ATTACHMENT_FILE_DELETE_SUCCESS: {
      return {
        ...state,
        error: '',
        data: action.payload
      };
    }

    case ActionTypes.ATTACHMENT_FILE_CLEAR: {
      return {
        ...state,
        error: '',
        data: action.payload
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        error: '',
        data: ''
      };
    }
  }

  return state;
};

export const PopupToggleReducer = (
  state = {
    visible: false,
    recordIndex: 0
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_POPUP: {
      return {
        ...state,
        visible: action.payload.visible,
        recordIndex: action.payload.index
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        visible: false,
        recordIndex: 0
      };
    }
  }
  return state;
};

// TODO: remove this reducer since its handling local component state
export const CancelPackageDialogReducer = (
  state = {
    visible: false
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.OPEN_CANCEL_DIALOG:
    case ActionTypes.CLOSE_CANCEL_DIALOG: {
      return {
        ...state,
        visible: action.payload
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        visible: false
      };
    }
  }

  return state;
};

export const RelatedCodesToggleReducer = (
  state = {
    visible: false,
    recordIndex: 0,
    currentValue: '',
    currentDescription: ''
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_RELATED_CODES:
      return {
        ...state,
        visible: action.payload.visible,
        recordIndex: action.payload.index
      };

    case ActionTypes.TOGGLE_RELATED_CODES_VALUE:
      return {
        ...state,
        currentValue: action.payload.currentValue
      };

    case ActionTypes.TOGGLE_RELATED_CODES_DESCRIPTION:
      return {
        ...state,
        currentDescription: action.payload.currentDescription
      };

    case ActionTypes.SIGN_OUT: {
      return {
        visible: false,
        recordIndex: 0,
        currentValue: '',
        currentDescription: ''
      };
    }
  }
  return state;
};

export const RecipientsReducer = (
  state = {
    allowedDestinations: '',
    allowedRecipients: '',
    generalComment: '',
    selectAll: false
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.SELECT_RECIPIENT: {
      return {
        ...state,
        allowedRecipients: action.payload
      };
    }
    case ActionTypes.UNSELECT_ALL_RECIPIENTS: {
      if (!Array.isArray(state.allowedRecipients)) {
        return state;
      }

      const result = state.allowedRecipients.map((recipient) => {
        return { ...recipient, selected: false };
      });

      return {
        ...state,
        allowedRecipients: result
      };
    }
    case ActionTypes.ALLOWED_DESTINATIONS: {
      return {
        ...state,
        allowedDestinations: action.payload,
      };
    }
    case ActionTypes.ALLOWED_RECIPIENTS: {
      return {
        ...state,
        allowedRecipients: action.payload,
        generalComment: '',
        selectAll: false
      };
    }
    case ActionTypes.SELECT_ALL_RECIPIENTS: {
      return {
        ...state,
        selectAll: action.payload
      };
    }
    case ActionTypes.ADD_GENERAL_COMMENT: {
      return {
        ...state,
        generalComment: action.payload
      };
    }

    case ActionTypes.ADD_PO_STATUS: {
      return {
        ...state,
        poStatus: action.payload
      };
    }

    case ActionTypes.ADD_PO_NUMBER: {
      return {
        ...state,
        po: action.payload
      };
    }

    case ActionTypes.CLEAR_RECIPIENTS: {
      if (state['po']) {
        return {
          ...state,
          allowedRecipients: action.payload,
          generalComment: action.payload,
          selectAll: false,
          po: '',
          poStatus: ''
        };
      }
      return {
        ...state,
        allowedRecipients: action.payload,
        generalComment: action.payload,
        selectAll: false
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        allowedRecipients: '',
        generalComment: '',
        selectAll: false
      };
    }
  }
  return state;
};

export const PackageReducer = (
  state = {
    account: {},
    product: {},
    releaseDateStatus: '',
    releaseDate: '',
    ratingID: '',
    isOneAccountOnly: false
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.SELECT_ACCOUNT: {
      return {
        ...state,
        account: action.payload
      };
    }
    case ActionTypes.SET_ONE_ACCOUNT_ONLY: {
      return {
        ...state,
        isOneAccountOnly: action.payload
      };
    }
    case ActionTypes.SELECT_PRODUCT: {
      return {
        ...state,
        product: action.payload
      };
    }
    case ActionTypes.SELECT_RELEASE_DATE_STATUS: {
      return {
        ...state,
        releaseDateStatus: action.payload
      };
    }
    case ActionTypes.SELECT_RELEASE_DATE: {
      return {
        ...state,
        releaseDate: action.payload
      };
    }
    case ActionTypes.SELECT_RATING: {
      return {
        ...state,
        ratingID: action.payload
      };
    }
    case ActionTypes.CLEAR_PACKAGE_CONTENT: {
      return {
        ...state,
        account: action.payload.account,
        product: action.payload.product,
        releaseDateStatus: action.payload.releaseDateStatus,
        releaseDate: action.payload.releaseDate,
        ratingID: action.payload.rating,
        isOneAccountOnly: false
      };
    }
    case ActionTypes.CLEAR_PACKAGE_CONTENT_EXCEPT_ACCOUNT: {
      return {
        ...state,
        product: action.payload.product,
        releaseDateStatus: action.payload.releaseDateStatus,
        releaseDate: action.payload.releaseDate,
        ratingID: action.payload.rating
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        account: {},
        product: {},
        releaseDateStatus: '',
        releaseDate: '',
        ratingID: '',
        isOneAccountOnly: false
      };
    }
  }
  return state;
};

export const SaveMediaRequestIDReducer = (state = { mediaRequestID: '', attachmentRequestID: '', error: '' }, action) => {
  switch (action.type) {
    case ActionTypes.SAVE_MEDIA_REQUEST_ID:
      return {
        ...state,
        mediaRequestID: action.payload
      };
    case ActionTypes.SAVE_ATTACHMENT_REQUEST_ID:
      return {
        ...state,
        attachmentRequestID: action.payload
      };
    case ActionTypes.CLEAR_ALL_REQUEST_ID:
      return {
        ...state,
        mediaRequestID: action.payload,
        attachmentRequestID: action.payload
      };
    case ActionTypes.SIGN_OUT: {
      return {
        mediaRequestID: '',
        attachmentRequestID: '',
        error: ''
      };
    }
  }

  return state;
};

// todo cleanup
export const SaveSubmissionReducer = (
  state = {
    submissionPackage: '',
    error: '',
    result: '',
    status: ''
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.SAVE_SUBMISSION_MEDIA_STAGE:
      return {
        ...state,
        submissionPackage: action.payload
      };

    case ActionTypes.SAVE_SUBMISSION_MEDIA_SUCCESS:
      return {
        ...state,
        result: action.payload
      };

    case ActionTypes.SAVE_SUBMISSION_MEDIA_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case ActionTypes.SAVE_SUBMISSION_STATUS:
      return {
        ...state,
        status: action.payload
      };

    case ActionTypes.CLEAR_SAVE_SUBMISSION:
      return {
        ...state,
        submissionPackage: action.payload,
        error: action.payload,
        result: action.payload,
        status: action.payload
      };

    case ActionTypes.SIGN_OUT: {
      return {
        submissionPackage: '',
        error: '',
        result: '',
        status: ''
      };
    }
  }
  return state;
};

export const ReviewerAssignmentsReducer = (
  state = {
    error: '',
    data: {}
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.REVIEWER_ASSIGNMENT_SUCCESS: {
      return {
        ...state,
        error: null,
        data: action.payload
      };
    }
    case ActionTypes.REVIEWER_ASSIGNMENT_FAILURE: {
      return {
        ...state,
        error: '',
        data: action.payload
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        error: '',
        data: {}
      };
    }
  }
  return state;
};

export const MediaVersionReducer = (
  state = {
    data: {},
    transcoding: '',
    error: ''
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.MEDIA_GROUP_VERSION_DELETE: {
      return {
        ...state,
        error: null,
        data: action.payload,
        transcoding: ''
      };
    }
    case ActionTypes.MEDIA_GROUP_VERSION_SUCCESS: {
      return {
        ...state,
        error: null,
        data: action.payload
      };
    }
    case ActionTypes.MEDIA_GROUP_VERSION_ERROR: {
      return {
        ...state,
        data: null,
        error: action.payload
      };
    }

    case ActionTypes.TRANCODING_IN_PORGRESS: {
      return {
        ...state,
        transcoding: action.payload
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        data: {},
        transcoding: '',
        error: ''
      };
    }
  }

  return state;
};

export const RelatedCodeWarningReducer = (
  state = {
    visible: false,
    submitter: '',
    product: ''
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.RELATED_CODE_WARNING:
      return {
        ...state,
        visible: action.payload.visible,
        submitter: action.payload.submitter,
        product: action.payload.product
      };

    case ActionTypes.CLEAR_RELATED_CODE_WARNING:
      return {
        ...state,
        visible: false,
        submitter: '',
        product: ''
      };

    case ActionTypes.SIGN_OUT: {
      return {
        visible: false,
        submitter: '',
        product: ''
      };
    }
  }

  return state;
};

export const PackageActionButtonsReducer = (
  state = {
    save: true,
    send: true,
    cancel: true
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_CAN_SAVE:
      return {
        ...state,
        save: action.payload
      };

    case ActionTypes.SIGN_OUT: {
      return {
        save: true,
        send: true,
        cancel: true
      };
    }
  }
  return state;
};

export const LoadingPackageReducer = (
  state = {
    visible: false
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_LOADING_PACKAGE:
      return {
        ...state,
        visible: action.payload
      };

    case ActionTypes.SIGN_OUT: {
      return {
        visible: false
      };
    }
  }

  return state;
};

/* eslint-enable */
