import React from 'react';

// Components
import BannerContainer from './BannerContainer';

const envColors = {
  'DEV': {
    bgColor: '#ffdc7d',
    color: '#911'
  },
  'STAGE': {
    bgColor: '#bb99ff',
    color: '#fff'
  },
  'DEMO': {
    bgColor: '#a2e6ec',
    color: '#166'
  },
  'QA': {
    bgColor: '#ef8269',
    color: '#fff'
  },
  'AUTOMATION': {
    bgColor: '#ef8269',
    color: '#fff'
  },
}

const EnvironmentBanner = () => {
  let currentEnv = process.env.REACT_APP_ENV_LABEL;

  if (process.env.NODE_ENV === 'development') currentEnv = 'DEV';

  const colors = envColors[currentEnv];

  // If not valid environment return early.
  if (!colors) return null;

  return <BannerContainer bgColor={colors.bgColor} color={colors.color} env={currentEnv} />
};

export default EnvironmentBanner;
