//this is hard coded in legacy client
export const CUT_TYPES = [
  {
    value: 'Final cut',
    label: 'Final'
  },
  {
    value: 'Rough cut',
    label: 'Rough'
  },
  {
    value: 'Concept',
    label: 'Concept'
  },
  {
    value: 'N/A',
    label: 'N/A'
  }
];

export const LENGTH = [
  {
    value: '0',
    label: 'N/A'
  },
  {
    value: '10',
    label: '0:10'
  },
  {
    value: '15',
    label: '0:15'
  },
  {
    value: '30',
    label: '0:30'
  },
  {
    value: '45',
    label: '0:45'
  },
  {
    value: '60',
    label: '1:00'
  },
  {
    value: '90',
    label: '1:30'
  },
  {
    value: '120',
    label: '2:00'
  },
  {
    value: '150',
    label: '2:30'
  },
  {
    value: '180',
    label: '3:00'
  },
  {
    value: '200',
    label: '3:20'
  }
];

export const REGIONS = [
  {
    value: '',
    label: '(empty)'
  },
  {
    value: 'International',
    label: 'International'
  },
  {
    value: 'Domestic',
    label: 'Domestic'
  }
];

export const MEDIA_TYPE = [
  {
    value: 'TYPE_01',
    text: 'TYPE_01'
  },
  {
    value: 'TYPE_02',
    text: 'TYPE_02'
  }
];

export const TO = [
  {
    value: 'CBS',
    text: 'CBS'
  },
  {
    value: 'MPAA',
    text: 'MPAA'
  }
];

export const SEND = 0;
export const SAVE = 2;

export const PACKAGE_EDIT = 0;
export const PACKAGE_FORWARD = 1;

export const PO_STATUS_REQUIRED = 'Job#/PO#';
export const PO_STATUS_NOT_REQUIRED = 'PO# not required';
export const PO_STATUS_PROVIDED_LATER = 'PO# to be provided later';

export const APPLY_TO_ALL_SUBMISSION_FIELDS = [
  'cutType',
  'adCode',
  'length',
  'region',
  'mediaTypeString',
  'mediaTypeID',
  'comment',
  'relatedCodes'
];

export const FILE_DOWNLOAD_PERMITTED = 'fileDownloadPermitted';
export const COMMON_OUTBOX_VIEW_PERMITTED = 'commonOutboxViewPermitted';
export const MANAGEMENT_GENERAL_ACCESS_PERMITTED = 'managementGeneralAccessPermitted';

export const MANAGEMENT_MOVIE_ACCESS_PERMITTED = 'managementMovieAccessPermitted';

export const MANAGEMENT_MOVIE_ADD_PERMITTED = 'managementMovieAddPermitted';
export const MANAGEMENT_MOVIE_EDIT_PERMITTED = 'managementMovieEditPermitted';
export const CAPABLE_OF_RECALLING_SUBMISSIONS = 'capableOfRecallingSubmissions';
export const PERMITTED_TO_DOWNLOAD_MEDIA = 'permittedToDownloadMedia';
export const PERMITTED_TO_EXPORT_MEDIA = 'permittedToExportMedia';
export const CAPABLE_OF_WORKING_WITH_DRAFTS = 'capableOfWorkingWithDrafts';

export const REVIEWER_ASSIGNMENT_MODIFICATION_PERMITTED = 'reviewerAssignmentModificationPermitted';

export const APPLY_TO_ALL_ATTACHMENT_FIELDS = ['attachmentType', 'to'];

export const DEFAULT_ATTACHMENT_TYPE_ID = 31;