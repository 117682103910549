import ActionTypes from '../actions/ActionTypes';
import ErrorTypes from '../actions/ErrorTypes';
import axios from 'axios';
import * as PackageUtils from '../utils/PackageUtils';
import * as Utils from '../utils/Utils';
import * as constants from '../components/package/Constants';
import * as errorActions from '../actions/ErrorActions';
import APIS from 'src/constants/api';

// Components
import { toast } from '@d3sw/one-ui-components';

// Helpersß
import {
  getSubmitterProductName,
  getSubmitterAccountName,
  getSubmitterAgency,
  getSubmitterUsername,
  getRecipientNames,
  getUnifiedStatus,
  getLatestReviewedDate,
  getReviewerMovieName,
  getReviewerRecipient,
  getReviewerCompany
} from '../components/datagrid/DashboardUtils';

export const fetchStaticData = () => {
  return async (dispatch, getState) => {
    const { authToken, userName } = getState().authUser;
    const url = APIS.DASHBOARD.STATIC_DATA;

    const payload = {
      authToken,
      doCompressOutboxData: Utils.Boolean(process.env.REACT_APP_DO_COMPRESS_OUTBOX_DATA),
      userName,
      version: process.env.REACT_APP_VERSION
    };

    return axios
      .post(url, payload)
      .then((response) => {
        return dispatch({
          type: ActionTypes.INBOX_STATIC_DATA_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.INBOX_STATIC_DATA_FAILURE,
          payload: null
        });
        if (error.response && error.response.headers) {
          dispatch(errorActions.logError(error, ErrorTypes.STATIC_INFO_FAILURE, payload, error.response.headers));
        }
      });
  };
};

// TEMP
export const fetchInbox = () => {
  return async (dispatch, getState) => {
    const { authToken, userName, submitterUser } = getState().authUser;
    const { renderDashboard } = getState().userSettings.data;

    const url = APIS.DASHBOARD.INBOX;

    const outboxBehavior = parseInt(localStorage.getItem('outboxBehavior'), 10) || parseInt(renderDashboard, 10) || 1;
    const payload = {
      authToken,
      doCompressOutboxData: Utils.Boolean(process.env.REACT_APP_DO_COMPRESS_OUTBOX_DATA),
      initialLoad: Utils.Boolean(process.env.REACT_APP_INITIAL_LOAD),
      outboxBehavior,
      outboxCapacity: Number(process.env.REACT_APP_OUTBOX_CAPACITY),
      sessionOverride: Utils.Boolean(process.env.REACT_APP_SESSION_OVERRIDE),
      userName,
      version: process.env.REACT_APP_VERSION
    };

    // ADVANCE SEARCH

    const currentDate = new Date();
    const newDate = new Date();
    newDate.setDate(newDate.getDate() - '14');
    const startDate = newDate.toLocaleString().split(',')[0];
    const endDate = currentDate.toLocaleString().split(',')[0];

    const payloadS = {
      authToken: authToken,
      doCompressOutboxData: 0,
      showTop: 100,
      perPage: 25,
      cutTypeId: '-1',
      projectId: '0',
      mediaTypeID: '0',
      productOrMovieId: '0',
      submitterId: '0',
      reviewerId: '0',
      reviewedOnly: null,
      searchMode: outboxBehavior,
      userName: userName,
      version: process.env.REACT_APP_VERSION,
      dateSubmittedFrom: startDate,
      dateSubmittedTo: endDate
    };

    return axios
      .post(submitterUser ? 'advanceSearch' : url, submitterUser ? payloadS : payload)
      .then((response) => {
        dispatch({
          type: ActionTypes.TOGGLE_LOADING_PACKAGE,
          payload: false
        });

        return dispatch(formatInbox(response.data));
      })
      .catch((error) => {
        console.error(error);

        dispatch({
          type: ActionTypes.INBOX_FAILURE,
          payload: null
        });

        dispatch({
          type: ActionTypes.TOGGLE_LOADING_PACKAGE,
          payload: false
        });

        if (error.hasOwnProperty('response')) {
          dispatch(errorActions.logError(error, ErrorTypes.INBOX_FAILURE, payload, error.response.headers));
        }
      });
  };
};

export const advancedSearch = (payload) => {
  return async (dispatch, getState) => {
    const { authToken, broadcasterUsersInfo, submitterUser, submitterUserInfo, userName } = getState().authUser;
    const url = APIS.DASHBOARD.ADVANCE_SEARCH;
    const defaultValues = {
      authToken: authToken,
      doCompressOutboxData: 0,
      showTop: 250,
      perPage: 25,
      cutTypeId: '-1',
      projectId: '0',
      mediaTypeID: '0',
      productOrMovieId: '0',
      submitterId: '0',
      reviewerId: '0',
      reviewedOnly: null,
      searchMode: 2,
      userName: userName,
      version: process.env.REACT_APP_VERSION
    };

    if (payload.searchMode) {
      payload.searchMode = parseInt(payload.searchMode, 10);
    }

    if (payload.reviewedOnly) {
      payload.reviewedOnly = payload.reviewedOnly;
    }

    if (submitterUser) {
      defaultValues.userId = submitterUserInfo.submitterUser.id;
    } else {
      const broadcasterType = Object.keys(broadcasterUsersInfo).pop();
      defaultValues.userId = broadcasterUsersInfo[broadcasterType].user.id;
    }

    return axios
      .post(url, { ...defaultValues, ...payload })
      .then(async (response) => {
        return await dispatch(formatInbox(response.data));
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          toast.error(getState().localizedLabels.notifications.searchError);
        }
      });
  };
};

export const formatInbox = (data) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(clearPackageStore());

      const { authUser, staticData } = getState();

      if (data.hasOwnProperty('submitterUserInboxData')) {
        const { accounts, broadcasters } = staticData.data.submitterUserStaticInfo.submitterUserStaticData;
        data.submitterUserInboxData.mediaGroups = data.submitterUserInboxData.mediaGroups.map((mediaGroup) => {
          mediaGroup.type = 'Submitter';

          mediaGroup.name = getSubmitterProductName(
            mediaGroup.media[0].productID,
            staticData.data.submitterUserStaticInfo.submitterUserStaticData
          );
          mediaGroup.account = getSubmitterAccountName(mediaGroup.submitterId, authUser.submitterUserInfo.submitterUser);
          mediaGroup.agency = getSubmitterAgency(mediaGroup.media[0].agencyID, data.submitterUserInboxData);
          mediaGroup.submittingUserName = getSubmitterUsername(
            mediaGroup.submittingUserId,
            staticData.data.submitterUserStaticInfo.submitterUserStaticData
          );
          mediaGroup.dateReviewed = getLatestReviewedDate(mediaGroup.media);

          const recepientInfo = Utils.getRecipients(
            mediaGroup.media[0].broadcasterResponses,
            mediaGroup.submitterId,
            broadcasters,
            accounts
          );
          mediaGroup.recipients = getRecipientNames(recepientInfo);
          mediaGroup.unifiedStatus = getUnifiedStatus(recepientInfo, mediaGroup.media[0]);

          return mediaGroup;
        });

        if (!data.submitterUserInboxData.submitterUserInboxMessages) {
          data.submitterUserInboxData.submitterUserInboxMessages = [];
        }

        data.inboxSize = data.submitterUserInboxData.mediaGroups.length;
      }

      if (data.hasOwnProperty('broadcasterDocuments')) {
        data.broadcasterType = Object.keys(data.broadcasterDocuments).pop();
        if (data.broadcasterDocuments[data.broadcasterType].mediaGroups) {
          data.broadcasterDocuments[data.broadcasterType].mediaGroups = data.broadcasterDocuments[data.broadcasterType].mediaGroups.map(
            (mediaGroup) => ({
              ...mediaGroup,

              type: 'Broadcaster',
              movieName: getReviewerMovieName(mediaGroup.movieID, data.broadcasterDocuments[data.broadcasterType]),
              name: getReviewerCompany(mediaGroup.movieID, data.broadcasterDocuments[data.broadcasterType]),
              network: data.broadcasterType,
              packageSubmitted: mediaGroup.media[0].created,
              recipient: getReviewerRecipient(
                mediaGroup.media[0].mediaRecipients[0],
                staticData.data.broadcasterUserStaticInfo[data.broadcasterType]
              )
            })
          );

          data.inboxSize = data.broadcasterDocuments[data.broadcasterType].mediaGroups.length;
        } else {
          data.broadcasterDocuments[data.broadcasterType] = {
            submitters: [],
            agencies: [],
            movies: [],
            mediaGroups: [],
            messages: [],
            claims: []
          };

          data.inboxSize = 0;
        }
      }

      dispatch({
        type: ActionTypes.INBOX_SUCCESS,
        payload: data
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const openRecallSubmissionPicker = (mediaGroup, destinations) => {
  return async (dispatch) => {
    const data = {
      isVisible: true,
      mediaGroup: mediaGroup,
      recallDestinations: destinations
    };

    dispatch({
      type: ActionTypes.RECALL_DESTINATION_PICKER,
      payload: data
    });
  };
};

export const closeRecallSubmissionPicker = () => {
  return async (dispatch) => {
    const data = {
      isVisible: false,
      mediaGroup: '',
      recallDestinations: []
    };
    dispatch({
      type: ActionTypes.RECALL_DESTINATION_PICKER,
      payload: data
    });
  };
};

export const selectDestinations = (destinations) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SELECT_RECALL_DESTINATIONS,
      payload: destinations
    });
  };
};

export const recallSubmission = (mediaGroup, destinations) => {
  return async (dispatch, getState) => {
    const { authToken, submitterUserInfo, userName } = getState().authUser;
    const { recallError, recallSuccess } = getState().localizedLabels.notifications;

    const renderDashboard = getState().userSettings.data.renderDashboard;

    const submissionToRecall = PackageUtils.buildSubmissionPackage(mediaGroup, destinations);

    const userId = submitterUserInfo.submitterUser.id;
    const sessionId = submitterUserInfo.sessionID;

    submissionToRecall.authToken = authToken;
    submissionToRecall.userName = userName;
    submissionToRecall.userId = userId;
    submissionToRecall.sessionID = sessionId;
    submissionToRecall.actionID = 2; //recall todo-->consolidate action ids

    const url = APIS.SUBMISSION.RECALL_SUBMISSION;

    return axios
      .put(url, submissionToRecall)
      .then(() => {
        toast.success(recallSuccess);

        dispatch(fetchInbox(renderDashboard));
      })
      .catch((error) => {
        toast.error(recallError);

        dispatch({
          type: ActionTypes.RECALL_FAILURE,
          payload: recallError
        });

        dispatch(errorActions.logError(error, ErrorTypes.RECALL_FAILURE, submissionToRecall, error.response.headers));
      });
  };
};

export const deleteSubmission = (mediaGroup, destinations) => {
  return async (dispatch, getState) => {
    const { authToken, submitterUserInfo, userName } = getState().authUser;

    const deleteSuccess = getState().localizedLabels.notifications.deleteSuccess;

    const submissionToRecall = PackageUtils.buildSubmissionPackage(mediaGroup, destinations);
    const userId = submitterUserInfo.submitterUser.id;
    const sessionId = submitterUserInfo.sessionID;

    submissionToRecall.authToken = authToken;
    submissionToRecall.userName = userName;
    submissionToRecall.userId = userId;
    submissionToRecall.sessionID = sessionId;
    submissionToRecall.actionID = 2; //recall todo-->consolidate action ids

    const url = APIS.SUBMISSION.RECALL_SUBMISSION;

    return axios
      .put(url, submissionToRecall)
      .then(() => {
        toast.success(deleteSuccess);
      })
      .catch((error) => {
        dispatch(errorActions.logError(error, ErrorTypes.DELETE_FAILURE, submissionToRecall, error.response.headers));
      });
  };
};

export const editDraftPackage = (mediaGroup) => {
  return async (dispatch, getState) => {
    //todo duplicate from Package actions getMediaVersion
    //should move getMediaVersion() to Utils

    const { authToken, userName } = getState().authUser;
    const url = APIS.SUBMISSION.GET_MEDIA_VERSION;

    const payload = {
      authToken,
      groupId: mediaGroup.id,
      userName
    };

    return axios
      .post(url, payload)
      .then((response) => {
        dispatch({
          type: ActionTypes.MEDIA_GROUP_VERSION_SUCCESS,
          payload: response.data
        });
      })
      .then(async () => {
        await Utils.loadPackageForEditingOrForward(dispatch, getState, mediaGroup, constants.PACKAGE_EDIT);
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.MEDIA_GROUP_VERSION_ERROR,
          payload: error.data
        });

        dispatch(errorActions.logError(error, ErrorTypes.EDIT_DRAFT_FAILURE, payload, error.response.headers));
      });
  };
};

export const reassign = (body, network) => {
  return async (dispatch, getState) => {
    const { authToken, broadcasterUsersInfo, userName } = getState().authUser;
    const url = APIS.DASHBOARD.REASSIGN_REVIEWER;

    const n = broadcasterUsersInfo[network];

    const payload = {
      action: 3,
      authToken,
      mediaGroupReassignment: body,
      reviewerServer: network,
      sessionID: n.sessionID,
      userName
    };

    return axios.post(url, payload).catch(function (error) {
      dispatch(errorActions.logError(error, ErrorTypes.REASSIGN_FAILURE, payload, error.response.headers));
    });
  };
};

export const grab = (body, network) => {
  return async (dispatch, getState) => {
    const { authToken, broadcasterUsersInfo, userName } = getState().authUser;
    const url = APIS.DASHBOARD.GRAB_PACKAGE;

    const n = broadcasterUsersInfo[network];

    const payload = {
      action: 3,
      authToken,
      mediaGroupReassignment: body,
      reviewerServer: network,
      sessionID: n.sessionID,
      userName
    };

    return axios.post(url, payload).catch(function (error) {
      dispatch(errorActions.logError(error, ErrorTypes.GRAB_FAILURE, payload, error.response.headers));
    });
  };
};

//duplicate from PackageActions
export const isPackageAlreadySent = (mediaGroup) => {
  return async () => {
    let isSent = false;
    mediaGroup &&
      mediaGroup.media.forEach((m) => {
        if (m.draftStatus === 0) {
          isSent = true;
          return false;
        }
      });
    return isSent;
  };
};

export const forwardPackage = (mediaGroup) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.MEDIA_GROUP_VERSION_DELETE,
      payload: {}
    });

    await Utils.loadPackageForEditingOrForward(dispatch, getState, mediaGroup, constants.PACKAGE_FORWARD);

    const { submissionPackage } = getState().saveSubmissionReducer;
    if (submissionPackage && !submissionPackage.mediaGroup.assignedUsers.length) {
      toast.error('No default Reviewer available for selections.  Please contact Client Services for assistance.');
    }
  };
};

export const onToggleCancelConfirmationDialog = (visible) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_DELETE_CONFIRMATION_DIALOG,
      payload: visible
    });
  };
};

export const onLoadSelectedPackage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SELECTED_PACKAGE_ROW,
      payload: value
    });
  };
};

export const clearPackageStore = () => {
  return async (dispatch, getState) => {
    Utils.cleanupOnCancel(dispatch, getState);
  };
};

export const onToggleViewerPlayer = (visible) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_VIEWER_PLAYER,
      payload: visible
    });
  };
};

export const onSelectMediaToPlay = (value) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SELECTED_MEDIA_ROW,
      payload: value
    });
  };
};

export const onToggleReviewerAssign = (visible) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_REVIEWER_ASSIGN,
      payload: visible
    });
  };
};

export const onSelectReviewerMedia = (value) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SELECTED_REVIEWER_MEDIA_ROW,
      payload: value
    });
  };
};

export const onToggleGrabConfirmation = (visible) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_GRAB_CONFIRMATION,
      payload: visible
    });
  };
};

export const onSelectMediaToGrab = (value) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SELECTED_MEDIA_GRAB,
      payload: value
    });
  };
};

export const onBroadcasterArchive = (mediaGroup, broadcasterType) => {
  return async (dispatch, getState) => {
    const { authToken, userName, broadcasterUsersInfo } = getState().authUser;
    const url = APIS.ARCHIVE_REVIEWER_PACKAGE;

    const payload = {
      authToken,
      broadcasterType: broadcasterType,
      mediaUpdate: { mediaGroup: mediaGroup },
      reviewerSessionId: Object.values(broadcasterUsersInfo)[0].sessionID,
      userName
    };

    return axios.put(url, payload).catch((error) => {
      dispatch({
        type: ActionTypes.BROADCASTER_ARCHIVE_ERROR,
        payload: error.data
      });

      dispatch(errorActions.logError(error, ErrorTypes.ARCHIVE_FAILURE, payload, error.response.headers));
    });
  };
};

export const onToggleConfirmDeleteReviewerPackage = (visible) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_CONFIRM_DELETER_REVIEWER_PACKAGE,
      payload: visible
    });
  };
};

export const onSelectReviewerPackageDelete = (value) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SELECTED_MEDIA_REVIEWER_DELETE_ROW,
      payload: value
    });
  };
};

export const onDeleteReviewer = (body, _departments, network) => {
  return async (dispatch, getState) => {
    const { authToken, broadcasterUsersInfo, userName } = getState().authUser;
    const url = APIS.REVIEW.SAVE;

    const n = broadcasterUsersInfo[network];

    const payload = {
      action: '4',
      authToken,
      actionType: 'saveReviews',
      broadcasterType: network,
      departments: _departments,
      mediaStatus: '6',
      mediaUpdate: body,
      sessionId: n.sessionID,
      userName
    };

    return axios.post(url, payload).catch((error) => {
      dispatch(errorActions.logError(error, ErrorTypes.DELETE_REVIEWER_FAILURE, payload, error.response.headers));
    });
  };
};

export const reValidateUserSession = () => {
  return async (dispatch, getState) => {
    const { authToken, userName } = getState().authUser;
    const { activeAccountMoreThanOne, nonActiveAccount } = getState().localizedLabels.loginValidation;

    const url = APIS.AUTH.VALID_TOKEN;

    const payload = {
      authToken,
      userName
    };

    return axios
      .post(url, payload)
      .then((response) => response.data)
      .catch(function (error) {
        //TODO check why getState().localizedLabels
        //is null on the catch()

        let errorMsg = 'Email and/or Password are invalid. Please try again.';

        if (error.response.status === 401) {
          errorMsg = activeAccountMoreThanOne;
        } else {
          errorMsg = nonActiveAccount;
        }

        dispatch({
          type: ActionTypes.SIGN_IN_FAILURE,
          payload: {
            authorized: false,
            authToken: '',
            errorMessage: errorMsg,
            hasError: true,
            userName: ''
          }
        });
      });
  };
};
