import ErrorTypes from '../actions/ErrorTypes';

export const ErrorReducer = (
  state = {
    errorCode: '',
    errorMessage: '',
    errorType: ''
  },
  action
) => {
  switch (action.type) {
    case ErrorTypes.APP_ERROR:
      return {
        ...state,
        errorCode: action.payload.errorCode,
        errorMessage: action.payload.errorMessage,
        errorType: action.payload.errorType
      };

    default:
      return state;
  }
};
