// UTILS
import * as Utils from './Utils';

export const handleDocumentExtensions = ({ data }, mediaExportName, name) => {
  const extension = mediaExportName.split('.').pop();

  const fileName = name.includes('.') ? name : `${name}.${extension}`;
  switch (true) {
    case Utils.isImage(extension):
      Utils.previewImage(data, fileName);
      break;
    default:
      Utils.saveAs(data, fileName);
  }
};
