import axios from 'axios';
import TagManager from 'react-gtm-module';

import { logError } from './actions/ErrorActions';
import { onLogout } from './actions/AuthenticationActions';
import { v4 as uuidv4 } from 'uuid';

export default {
  setup: (store) => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['deviceType'] = 'WEB';

    axios.interceptors.request.use((config) => {
      config.headers.common['correlationId'] = uuidv4().replaceAll('-', '');
      return config;
    });

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { userName, reviewerUser } = store.getState().authUser;

        console.log(error);

        TagManager.dataLayer({
          dataLayer: {
            event: 'errorEvent',
            errorInfo: `${userName ? (reviewerUser ? 'reviewer' : 'submitter') : ''}${error.response.config.url}/${error.response.status}`
          },
          dataLayerName: 'PageDataLayer'
        });

        const status = error.response.status;
        store.dispatch(logError(error, status, error.response.config.data, error.response.headers));

        if (!error.response || status === 401) {
          store.dispatch(onLogout());
        }

        return Promise.reject(error);
      }
    );
  }
};
