import ActionTypes from '../actions/ActionTypes';

// TODO: Remove this reducer as its managing local state not shared
export const CreateProductReducers = (
  state = {
    visible: false,
    productMode: 'CREATE',

    productModeModalVisible: false,
    editProductModeModalVisible: false,
    titleChangeModalVisible: false,
    selectDestinationsModalVisible: false,
    videoGameModalVisible: false,
    editProductSelectableCategory: [],
    selectedDestinations: [],
    selectedPlatforms: [],

    accounts: [],
    selectedAccount: '',
    agencyId: '',
    accountId: '',
    certificate: '',
    advertisingPeriod: '',
    descriptors: '',

    products: [],
    editProduct: '',
    selectedProduct: '',

    abbrev: '',

    selectedCategory: '',
    categories: [],

    ratings: [],
    selectedRating: '',
    ratingReason: '',

    releaseDateStatus: 'TBD',
    releaseDate: '',

    billingOptions: [],

    selectedBilling: ''
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.SIGN_OUT:
    case ActionTypes.CREATE_PRODUCT_CLEAR: {
      return {
        ...state,
        productMode: 'CREATE',
        productModeModalVisible: false,
        editProductModeModalVisible: false,
        titleChangeModalVisible: false,
        selectDestinationsModalVisible: false,
        videoGameModalVisible: false,
        editProductSelectableCategory: [],
        selectedDestinations: [],
        selectedPlatforms: [],
        certificate: '',
        advertisingPeriod: '',
        descriptors: '',
        selectedAccount: '',
        //	products: [],
        editProduct: '',
        selectedProduct: '',

        abbrev: '',

        selectedCategory: '',
        categories: [],

        ratings: [],
        selectedRating: '',
        ratingReason: '',

        releaseDateStatus: 'TBD',
        releaseDate: '',

        billingOptions: [],

        selectedBilling: ''
      };
    }

    case ActionTypes.CLEAR_PRODUCTS_LIST: {
      return {
        ...state,
        products: []
      };
    }

    case ActionTypes.UPDATE_CERTIFICATE: {
      return {
        ...state,
        certificate: action.payload
      };
    }

    case ActionTypes.UPDATE_DESCRIPTORS: {
      return {
        ...state,
        descriptors: action.payload
      };
    }

    case ActionTypes.UPDATE_ADVERTISING_PERIOD: {
      return {
        ...state,
        advertisingPeriod: action.payload
      };
    }

    case ActionTypes.UPDATE_SELECTED_PLATFORM: {
      return {
        ...state,
        selectedPlatforms: action.payload
      };
    }
    case ActionTypes.HIDE_ALL_MODALS: {
      return {
        ...state,
        productModeModalVisible: false,
        editProductModeModalVisible: false,
        titleChangeModalVisible: false,
        selectDestinationsModalVisible: false,
        videoGameModalVisible: false
      };
    }

    case ActionTypes.TOGGLE_VIDEO_GAME_MODAL_VISIBLE: {
      return {
        ...state,
        videoGameModalVisible: action.payload
      };
    }

    case ActionTypes.EDIT_PRODUCT_SELECTED_DESTINATION: {
      return {
        ...state,
        selectedDestinations: action.payload
      };
    }
    case ActionTypes.EDIT_PRODUCT_SELECTABLE_CATEGORY: {
      return {
        ...state,
        editProductSelectableCategory: action.payload
      };
    }

    case ActionTypes.EDIT_PRODUCT_SET_RATINGS: {
      return Object.assign({}, state, {
        editProduct: Object.assign({}, state.editProduct, {
          ratingID: action.payload
        })
      });
    }

    case ActionTypes.EDIT_PRODUCT_SET_RELEASE_DATE: {
      return Object.assign({}, state, {
        editProduct: Object.assign({}, state.editProduct, {
          releaseDate: action.payload
        })
      });
    }

    case ActionTypes.EDIT_PRODUCT_TITLE_CHANGE: {
      return Object.assign({}, state, {
        editProduct: Object.assign({}, state.editProduct, {
          name: action.payload
        })
      });
    }

    case ActionTypes.EDIT_PRODUCT_SET_RELEASE_DATE_STATUS: {
      return {
        ...state,
        releaseDateStatus: action.payload
      };
    }
    case ActionTypes.EDIT_PRODUCT_CATEGORY_CHANGE: {
      return Object.assign({}, state, {
        editProduct: Object.assign({}, state.editProduct, {
          category: action.payload
        })
      });
    }
    case ActionTypes.EDIT_PRODUCT_ABBREV_CHANGE: {
      return Object.assign({}, state, {
        editProduct: Object.assign({}, state.editProduct, {
          abbrev: action.payload
        })
      });
    }

    case ActionTypes.EDIT_PRODUCT_TITLE_CHANGE_WARNING_MODAL_VISIBLE: {
      return {
        ...state,
        titleChangeModalVisible: action.payload
      };
    }

    case ActionTypes.EDIT_PRODUCT_SELECT_DESTINATION_MODAL_VISIBLE: {
      return {
        ...state,
        selectDestinationsModalVisible: action.payload
      };
    }

    case ActionTypes.EDIT_PRODUCT_MODAL_SHOW: {
      return {
        ...state,
        editProductModeModalVisible: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_SHOW: {
      return {
        ...state,
        visible: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_MODE: {
      return {
        ...state,
        productMode: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_MODE_SHOW: {
      return {
        ...state,
        productModeModalVisible: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_LOAD_ACCOUNTS: {
      return {
        ...state,
        accounts: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_LOAD_CATEGORIES: {
      return {
        ...state,
        categories: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_SELECT_ACCOUNT: {
      return {
        ...state,
        selectedAccount: action.payload.account,
        agencyId: action.payload.agencyId,
        accountId: action.payload.accountId
      };
    }

    case ActionTypes.CREATE_PRODUCT_LOAD_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };

    case ActionTypes.CREATE_PRODUCT_SELECT_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload
      };

    case ActionTypes.EDIT_PRODUCT_SELECT_PRODUCT:
      return {
        ...state,
        editProduct: action.payload
      };

    case ActionTypes.CREATE_PRODUCT_SELECT_CATEGORY: {
      return {
        ...state,
        selectedCategory: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_SET_ABBREVIATION: {
      return {
        ...state,
        abbrev: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_LOAD_RATINGS: {
      return {
        ...state,
        ratings: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_SET_RATINGS: {
      return {
        ...state,
        selectedRating: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_SET_RATINGS: {
      return {
        ...state,
        selectedRating: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_SET_RATING_REASON: {
      return {
        ...state,
        ratingReason: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_SET_RELEASE_DATE_STATUS: {
      return {
        ...state,
        releaseDateStatus: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_SET_RELEASE_DATE: {
      return {
        ...state,
        releaseDate: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_LOAD_BILLING_OPTIONS: {
      return {
        ...state,
        billingOptions: action.payload
      };
    }

    case ActionTypes.CREATE_PRODUCT_SET_BILLING_OPTION: {
      return {
        ...state,
        selectedBilling: action.payload
      };
    }

    default:
      return state;
  }
};
