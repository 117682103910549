// Framework
import axios from 'axios';
import ActionTypes from '../actions/ActionTypes';
import { toast } from '@d3sw/one-ui-components';
import APIS from 'src/constants/api';

export const fetchLocalRersources = () => {
  return async (dispatch, getState) => {
    const baseUrl = APIS.RESOURCES;
    const locale = getState().userSettings.data.language || 'en';
    const localizedResources = [
      { resource: 'dashboard', actionType: ActionTypes.LOCALIZED_DASHBOARD },
      { resource: 'notification', actionType: ActionTypes.LOCALIZED_NOTIFICATIONS },
      { resource: 'packageMgmt', actionType: ActionTypes.LOCALIZED_PACKAGE_MANAGEMENT },
      { resource: 'requestStatus', actionType: ActionTypes.REQUEST_STATUS_TEXT_SUCCESS },
      { resource: 'deliveryStatus', actionType: ActionTypes.DELIVERY_STATUS_TEXT_SUCCESS },
      { resource: 'draftStatus', actionType: ActionTypes.DRAFT_STATUS_TEXT_SUCCESS },
      { resource: 'uploadStatus', actionType: ActionTypes.UPLOAD_STATUS_TEXT_SUCCESS },
      { resource: 'unifiedStatus', actionType: ActionTypes.UNIFIED_STATUS_TEXT_SUCCESS },
      { resource: 'loginValidation', actionType: ActionTypes.LOCALIZED_LOGIN_VALIDATION },
      { resource: 'responses', actionType: ActionTypes.LOCALIZED_RESPONSES },
      { resource: 'inboxMessages', actionType: ActionTypes.LOCALIZED_INBOX_MESSAGES }
    ];

    const staticResources = [
      { resource: 'languages', actionType: ActionTypes.LOAD_LANGUAGE_OPTIONS },
      { resource: 'timezones', actionType: ActionTypes.LOAD_TIMEZONES }
    ];

    return await Promise.all([
      ...localizedResources.map(({ resource, actionType }) => dispatch(loadResource(`/${baseUrl}/${resource}-${locale}.json`, actionType))),
      ...staticResources.map(({ resource, actionType }) => dispatch(loadResource(`/${baseUrl}/${resource}.json`, actionType)))
    ]);
  };
};

export const fetchUserSettings = () => {
  return async (dispatch, getState) => {
    const url = APIS.USER.GET_SETTINGS;
    const { authToken, userName } = getState().authUser;
    const payload = {
      authToken,
      userName
    };

    return axios
      .post(url, payload)
      .then(async ({ data }) => {
        localStorage.setItem('outboxBehavior', data.renderDashboard || localStorage.getItem('outboxBehavior') || '1');

        await dispatch({
          type: ActionTypes.USER_SETTING_SUCCESS,
          payload: data
        });

        return data;
      })
      .catch(() => {
        dispatch({
          type: ActionTypes.USER_SETTING_FAILURE,
          payload: {}
        });
      });
  };
};

export const loadResource = (url, actionType) => {
  return async (dispatch) => {
    return axios.get(`${window.origin}${url}`).then(async ({ data }) => {
      return await dispatch({
        type: actionType,
        payload: data
      });
    });
  };
};

export const openSettingsCancelDialog = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.OPEN_CANCEL_DIALOG,
      payload: true
    });
  };
};

export const closeSettingsCancelDialog = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CLOSE_CANCEL_DIALOG,
      payload: false
    });
  };
};

export const setUserPreference = (settings) => {
  return async (dispatch, getState) => {
    const url = APIS.USER.SAVE_SETTINGS;

    const payload = {
      userName: getState().authUser.userName,
      authToken: getState().authUser.authToken,
      language: settings.language,
      dateFormat: settings.dateFormat,
      timeFormat: settings.timeFormat,
      timezone: settings.timeZone,
      renderDashboard: settings.renderDashboard
    };

    return axios
      .post(url, payload)
      .then(({ data }) => {
        localStorage.setItem('outboxBehavior', settings.renderDashboard);

        toast.success(getState().localizedLabels.notifications.userSettingsSaveSuccess);

        dispatch({
          type: ActionTypes.USER_SETTING_SUCCESS,
          payload: data
        });

        return data;
      })
      .catch(function (error) {
        toast.error(getState().localizedLabels.notifications.userSettingsSaveError);

        console.log(error);

        dispatch({
          type: ActionTypes.USER_SETTING_FAILURE,
          payload: null
        });
      });
  };
};

export const getChangeLog = () => {
  return async (dispatch) => {
    return axios
      .get(process.env.REACT_APP_API_VERSION)
      .then(function (res) {
        dispatch({
          type: ActionTypes.APP_VERSION,
          payload: res.data
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: ActionTypes.APP_VERSION,
          payload: {}
        });
      });
  };
};

export const setAppTheme = (selectedTheme) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_APP_THEME,
      payload: selectedTheme
    });
  };
};
