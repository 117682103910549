// Framework
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import loadable from '@loadable/component';

// Routes
import * as ROUTES from 'src/constants/Routes';

// Store & Actions
import { loadFingerprint, onIsValidToken, setLocalIP, syncWithLocalStorage } from './actions/AuthenticationActions';

import { fetchStaticData } from './actions/DashboardActions';

import { fetchMessages } from './actions/MessageActions';

import { getUserProfile } from './actions/ProfileActions';

import { fetchUserSettings, fetchLocalRersources, setAppTheme } from './actions/SettingsActions';

import { toggleTour } from './actions/TourActions';

// Components
import { LoadingSpinner } from '@d3sw/one-ui-components';
import AdblockDetect from './components/login/AdblockDetect';
import { ToastContainer } from 'react-toastify';
import GenericErrorComponent from './components/error/GenericErrorComponent';
import MainLayout from './components/layout/MainLayout';
import NewMessage from './components/messages/new-message/NewMessage';

// Helpers
import { Role } from './roles';

// Pages
import WithAuth from './components/withAuth';

const ErrorPage = loadable(() => import('src/pages/ErrorPage'));
const ForgotPassword = loadable(() => import('src/pages/Auth/ForgotPassword/ForgotPassword'));
const Login = loadable(() => import('src/pages/Auth/Login/Login'));
const Logout = loadable(() => import('src/pages/Auth/Logout/Logout'));
const NewPassword = loadable(() => import('src/pages/Auth/NewPassword/NewPassword'));
const CreatePackage = loadable(() => import('src/pages/Package/CreatePackage'));
const Message = loadable(() => import('src/pages/Messages/Messages'));
const UserSettings = loadable(() => import('src/pages/Settings/UserSettings'));
const UserProfile = loadable(() => import('src/pages/Settings/UserProfile'));
const ClaimsManagement = loadable(() => import('src/pages/ClaimsManagement/ClaimsManagement'));
const Responses = loadable(() => import('src/pages/Response/Responses'));
const Viewer = loadable(() => import('src/pages/Viewer/ViewerV2'));
const Datagrid = loadable(() => import('src/pages/Datagrid/Datagrid'));

class App extends Component {
  theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';
  constructor(props) {
    super(props);

    this.state = {
      hasStaticData: false,
      isLoading: true
    };

    if (process.env.REACT_APP_ENV_LABEL === 'LOCAL') {
      this.props.setLocalIP();
    }

    // Handle the '/' path to prevent a white screen from rendering
    if (this.props.location.pathname === ROUTES.ROOT) {
      this.props.history.replace(this.props.authUser.authorized ? ROUTES.DASHBOARD : ROUTES.LOGIN);
    }
  }

  componentDidMount = async () => {
    if (this.props.location.pathname !== ROUTES.LOGOUT) {
      await this.initializeApp();
    }

    if (!this.props.authUser.authorized) {
      this.setState({
        isLoading: false
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.authUser.authorized && !this.props.authUser.authorized) {
      this.setState({
        hasStaticData: false
      });
    }

    this.theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';

    // TODO: add an authUser initialized flag to use here instead of prevProps.authUser.authorized and .authToken
    if (
      prevProps.authUser.authorized === false &&
      this.props.authUser.authorized &&
      prevProps.authUser.emailId !== this.props.authUser.emailId
    ) {
      this.setState(
        {
          hasStaticData: false
        },
        async () => {
          await this.loadStaticData();

          let isTourOpen = JSON.parse(localStorage.getItem('mediavu-tour'));
          if (isTourOpen !== null) {
            localStorage.setItem('mediavu-tour', false);
            this.props.toggleTour(isTourOpen);
          }
        }
      );
    }
  };

  initializeApp = async () => {
    await this.props.loadFingerprint();
    await this.props.syncWithLocalStorage();
    await this.props.fetchLocalRersources();
  };

  loadStaticData = async () => {
    this.setState({
      isLoading: true
    });

    if (!this.state.hasStaticData) {
      await this.props.fetchStaticData();
      await this.props.fetchUserSettings();
      await this.props.getUserProfile();
      await this.props.fetchMessages();
    }

    this.setState({
      hasStaticData: true,
      isLoading: false
    });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner fullpage={true} />;
    }

    const layoutRender = (component) => (route) => <MainLayout component={component} route={route} />;
    return (
      <div id="app" className={`app ${this.theme}-theme`}>
        <GenericErrorComponent>
          <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} newestOnTop={false} closeOnClick pauseOnHover />

          <WithAuth exact path={ROUTES.DASHBOARD} component={layoutRender(Datagrid)} roles={[Role.Submitter, Role.Reviewer]} />
          <WithAuth exact path={ROUTES.ADVANCED_SEARCH} component={layoutRender(Datagrid)} roles={[Role.Submitter, Role.Reviewer]} />
          <WithAuth exact path={ROUTES.MESSAGES} component={layoutRender(Message)} roles={[Role.Submitter, Role.Reviewer]} />
          <WithAuth
            exact
            path={`${ROUTES.MESSAGES}/:messageId`}
            component={layoutRender(Message)}
            roles={[Role.Submitter, Role.Reviewer]}
          />
          <WithAuth exact path={ROUTES.USER_SETTINGS} component={layoutRender(UserSettings)} roles={[Role.Submitter, Role.Reviewer]} />
          <WithAuth exact path={ROUTES.USER_PROFILE} component={layoutRender(UserProfile)} roles={[Role.Submitter, Role.Reviewer]} />

          <WithAuth exact path={ROUTES.CREATE_PACKAGE} component={layoutRender(CreatePackage)} roles={[Role.Submitter]} />
          <WithAuth exact path={`${ROUTES.EDIT_PACKAGE}/:mediaGroupId`} component={layoutRender(CreatePackage)} roles={[Role.Submitter]} />
          <WithAuth
            exact
            path={`${ROUTES.FORWARD_PACKAGE}/:mediaGroupId`}
            component={layoutRender(CreatePackage)}
            roles={[Role.Submitter]}
          />
          <WithAuth exact path={`${ROUTES.RESPONSES}/:mediaGroupId`} component={layoutRender(Responses)} roles={[Role.Submitter]} />
          <WithAuth exact path={ROUTES.CLAIMS} component={layoutRender(ClaimsManagement)} roles={[Role.Reviewer]} />
          <WithAuth exact path={`${ROUTES.PLAY}/:mediaGroupId`} component={layoutRender(Viewer)} roles={[Role.Reviewer]} />

          <Route path={ROUTES.ERROR_403} component={ErrorPage} errorCode={'403'} />

          <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <Route exact path={ROUTES.NEW_PASSWORD} component={NewPassword} />
          <Route exact path={ROUTES.LOGOUT} component={Logout} />

          <AdblockDetect />
          <NewMessage />
        </GenericErrorComponent>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.authUser,
  error: state.error,
  theme: state.userSettings.theme
});

const mapDispatchToProps = (dispatch) => ({
  fetchLocalRersources: () => dispatch(fetchLocalRersources()),
  fetchStaticData: () => dispatch(fetchStaticData()),
  fetchUserSettings: () => dispatch(fetchUserSettings()),
  getUserProfile: () => dispatch(getUserProfile()),
  fetchMessages: () => dispatch(fetchMessages()),
  loadFingerprint: () => dispatch(loadFingerprint()),
  onIsValidToken: () => dispatch(onIsValidToken()),
  setLocalIP: () => dispatch(setLocalIP()),
  syncWithLocalStorage: () => dispatch(syncWithLocalStorage()),
  toggleTour: (show) => dispatch(toggleTour(show)),
  setAppTheme: (selectedTheme) => dispatch(setAppTheme(selectedTheme))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
