import _ from 'lodash';
import ActionTypes from '../actions/ActionTypes';
import * as Utils from '../utils/Utils';
import * as uploadType from '../components/package/UploadType';
import FileStatus from '../components/package/FileStatus';
import axios from 'axios';
import * as constants from '../components/package/Constants';
import APIS from 'src/constants/api';

// Components
import { toast } from '@d3sw/one-ui-components';

export const ACTIVE = 1;
export const DEACTIVE = 2;

export const buildSubmissionPackage = (mediaGroup, destinations) => {
  //todo--- on omitting filepath, gateway skips encryption
  // so need a flag to determine whether to include this
  // based on actions

  let submissionToBuild = {};

  let mediaGroupToBuild = {};

  mediaGroupToBuild.id = mediaGroup.id;
  mediaGroupToBuild.name = mediaGroup.name;
  mediaGroupToBuild.desc = mediaGroup.desc;
  mediaGroupToBuild.dateSubmitted = mediaGroup.dateSubmitted;
  mediaGroupToBuild.referenceCode = mediaGroup.referenceCode;
  mediaGroupToBuild.submitterComment = mediaGroup.submitterComment;
  mediaGroupToBuild.version = mediaGroup.version;
  mediaGroupToBuild.userId = mediaGroup.submittingUserId;
  mediaGroupToBuild.submitterId = mediaGroup.submitterId;
  mediaGroupToBuild.mediaUploadRequestIds = [];

  let mediasToBuild = [];

  _.forEach(mediaGroup.media, function (currentMedia) {
    let mediaToBuild = {
      mediaID: currentMedia.id,
      forwardedMediaID: 0, //todo
      // "name": currentMedia.name,
      desc: currentMedia.desc,
      status: currentMedia.status,
      draftStatus: currentMedia.draftStatus,
      orderInGroup: currentMedia.orderInGroup,
      // "fileName": currentMedia.mediaExportName,
      // "filePath": currentMedia.mediaLocation,
      mediaUploadRequestId: '', //todo,
      priority: currentMedia.priority,
      created: currentMedia.created,
      length: currentMedia.length,
      cutType: currentMedia.cutType,
      downloadableByReviewers: currentMedia.downloadableByReviewers,
      isci: currentMedia.isci,
      submitterMess: currentMedia.submitterMess,
      mediaTypeID: currentMedia.mediaTypeID,
      version: currentMedia.version,
      region: currentMedia.region,
      versionName: currentMedia.versionName,
      mediaIssues: [], //todo
      verification: '', //todo
      results: '', //todo
      product: {
        id: currentMedia.productID,
        version: 2, //todo
        releaseDate: null //todo
      },
      agency: {
        id: currentMedia.agencyID
      }
    };

    let destinationDepartments = [];

    _.forEach(currentMedia.broadcasterResponses, function (response) {
      _.forEach(destinations, function (currentDestination) {
        if (currentDestination.departmentID === response.departmentID) {
          let currentDepartment = {
            deliveryID: response.deliveryID,
            id: response.departmentID,
            receivedStatus: response.receivedStatus,
            requestStatus: 1, //todo status 1 means PENDING
            restrictedToRecipient: response.restrictedToRecipient
          };

          destinationDepartments.push(currentDepartment);
        }
      });
    });

    mediaToBuild.destinationDepartments = destinationDepartments;

    mediasToBuild.push(mediaToBuild);
  });

  mediaGroupToBuild.medias = mediasToBuild;

  submissionToBuild.mediaGroup = mediaGroupToBuild;

  return submissionToBuild;
};

export const pruneSubmissionsIfNeeded = (accepted, currentCount, maxCount, type, notifications) => {
  var acceptedWithoutSpecialChars = [];
  var specialCharacterError = 'File not uploaded, file name contains prohibited character(s).';
  accepted.forEach((file) => {
    if (Utils.isValidName(file.name)) {
      acceptedWithoutSpecialChars.push(file);
    } else {
      toast.error(specialCharacterError);
    }
  });

  var canSend = maxCount - currentCount;
  var lastIndex = accepted.length;
  if (canSend <= 0) {
    let message = type === uploadType.SUBMISSION_FILE ? notifications.maxSubmissionLimit : notifications.maxAttachmentLimit;
    toast.error(message);
    return undefined;
  }

  var couldNotSend = acceptedWithoutSpecialChars.slice(canSend, lastIndex);
  if (couldNotSend.length > 0) {
    let message = type === uploadType.SUBMISSION_FILE ? notifications.maxSubmissionLimit : notifications.maxAttachmentLimit;
    toast.error(message);
  }

  var okToSend = acceptedWithoutSpecialChars.slice(0, canSend);
  return okToSend;
};

export const preventSpecialChars = (files) => {
  const acceptedFilesRenamed = files.map(({ file }) => new File([file], Utils.replaceSpecialChars(file.name), { type: file.type }));

  return acceptedFilesRenamed;
};

export const replaceSpecialChars = (fileName) => {
  const noSpaces = fileName.replace(/([^ -~])/g, '-');
  const reg = /[^á-üÁ-Üa-zA-Z0-9_\s]/g;
  const regNoSpaces = /[^a-zA-Z0-9]{3,4}$/g;
  const splitedName = noSpaces.split(/\.(?=[^\.]+$)/);
  let newFileName = '';

  if (splitedName.length > 1) {
    newFileName = splitedName[0].replace(reg, '-') + '.' + splitedName[1].replace(regNoSpaces, '');
  } else {
    newFileName = splitedName[0].replace(reg, '-');
  }

  return newFileName;
};

export const submitFilesSequentially = (fileQ, type, dispatch, getState) => {
  var url = type === uploadType.SUBMISSION_FILE ? APIS.SUBMISSION.FILE_UPLOAD : APIS.SUBMISSION.ATTACHMENT_UPLOAD;

  if (!fileQ || fileQ.length === 0) {
    return;
  }

  var UPLOAD_SUCCESS =
    type === uploadType.SUBMISSION_FILE ? ActionTypes.SUBMISSION_FILE_UPLOAD_SUCCESS : ActionTypes.ATTACHMENT_FILE_UPLOAD_SUCCESS;
  var UPLOAD_FAILURE =
    type === uploadType.SUBMISSION_FILE ? ActionTypes.SUBMISSION_FILE_UPLOAD_FAILURE : ActionTypes.ATTACHMENT_FILE_UPLOAD_FAILURE;
  var UPLOAD_PROGRESS =
    type === uploadType.SUBMISSION_FILE ? ActionTypes.SUBMISSION_FILE_UPLOAD_PROGRESS : ActionTypes.ATTACHMENT_FILE_UPLOAD_PROGRESS;

  var fileEntry = fileQ.shift();

  if (fileEntry.uploadStatus === FileStatus.PENDING) {
    const formData = new FormData();
    formData.append('mediaFile', fileEntry.file);
    formData.append('userName', getState().authUser.userName);

    const config = {
      headers: {
        ...axios.defaults.headers.common,
        authToken: getState().authUser.authToken
      },

      onUploadProgress: async function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileEntry.uploadStatus = FileStatus.PROGRESSING;
        fileEntry.uploadProgress = percentCompleted;
        dispatch({
          type: UPLOAD_PROGRESS,
          payload: fileEntry
        });
      }
    };

    return axios
      .post(url, formData, config)
      .then((response) => {
        //------------------------------------------------
        // Check for Related Code Match
        //--------------------------------
        let fileName = response.data.uploadedFileNametoFilePaths[0].fileName;
        let accounts = getState().staticData.data.submitterUserStaticInfo.submitterUserStaticData.accounts;

        let selectedAccount = getState().packages.account;
        let selectedProduct = getState().packages.product;

        let _accounts = [];
        let _products = [];

        accounts.map(function (account) {
          _accounts.push({
            id: account.id,
            abbreviation: account.abbreviation,
            name: account.name
          });

          account.products.map(function (product) {
            _products.push({
              id: product.id,
              abbreviation: product.abbrev,
              productStatus: product.status,
              name: product.name,
              submitterId: product.submitterId,
              releaseDate: product.releaseDate
            });
          });
        });

        var url = APIS.SUBMISSION.GET_RELATED_CODE;
        var payload = {
          userName: getState().authUser.userName,
          authToken: getState().authUser.authToken,
          fileName: fileName,
          products: _products,
          accounts: _accounts
        };

        return axios
          .post(url, payload, config)
          .then(function (response) {
            var data = response.data;

            //length
            //product
            //relatedCodes
            //submitter
            //tag
            //tagIndex
            //version
            //format

            if (data.format) {
              let warning = false;
              let submitter = '';
              let product = '';

              if (selectedAccount.id === undefined) {
                submitter = data.submitter.name;
                warning = true;
              } else {
                if (data.submitter.id !== parseInt(selectedAccount.id)) {
                  submitter = data.submitter.name;
                  warning = true;
                }
              }

              if (selectedProduct.value == undefined) {
                product = data.product.name;
                warning = true;
              } else {
                if (data.product.id !== selectedProduct.value) {
                  product = data.product.name;
                  warning = true;
                }
              }

              if (warning) {
                let open = {
                  visible: true,
                  submitter: submitter,
                  product: product
                };

                dispatch({
                  type: ActionTypes.RELATED_CODE_WARNING,
                  payload: open
                });
              }

              fileEntry.displayName = data.name;
              fileEntry.length = data.length;
              fileEntry.version = data.version;
              fileEntry.cutType = 'Final cut';
              fileEntry.adCode = data.adCode;
              fileEntry.relatedCodes = data.relatedCodes;
            }
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              dispatch({
                type: ActionTypes.SIGN_IN_FAILURE,
                payload: {
                  authorized: false,
                  authToken: '',
                  userName: '',
                  hasError: true,
                  errorMessage: 'This session has ended for your security, as a new login has occurred with the same account.'
                }
              });
            }
          })
          .then(function (data) {
            //update status to success here
            fileEntry.uploadStatus = FileStatus.UPLOADED;
            fileEntry.serverMessage = response.data;
            dispatch({
              type: UPLOAD_SUCCESS,
              payload: fileEntry
            });

            let existingRequests = [];

            if (type === uploadType.SUBMISSION_FILE) {
              existingRequests = getState().saveMediaRequestID.mediaRequestID;
              existingRequests = _.compact(_.concat(existingRequests, response.data.uploadedFileNametoFilePaths[0].mediaUploadRequestId));

              dispatch({
                type: ActionTypes.SAVE_MEDIA_REQUEST_ID,
                payload: existingRequests
              });
            } else {
              existingRequests = getState().saveMediaRequestID.attachmentRequestID;
              existingRequests = _.compact(_.concat(existingRequests, response.data.uploadedFileNametoFilePaths[0].mediaUploadRequestId));

              dispatch({
                type: ActionTypes.SAVE_ATTACHMENT_REQUEST_ID,
                payload: existingRequests
              });
            }

            submitFilesSequentially(fileQ, type, dispatch, getState);
          });
        //------------------------------------------------
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: ActionTypes.SIGN_IN_FAILURE,
            payload: {
              authorized: false,
              authToken: '',
              userName: '',
              hasError: true,
              errorMessage: 'This session has ended for your security, as a new login has occurred with the same account.'
            }
          });
        } else {
          fileEntry.uploadStatus = FileStatus.ERROR;
          fileEntry.serverMessage = error.data;
          fileEntry.uploadProgress = 0;
          fileEntry.error = 'Error Uploading';
          dispatch({
            type: UPLOAD_FAILURE,
            payload: fileEntry
          });
          submitFilesSequentially(fileQ, type, dispatch, getState);
        }
      });
  } else {
    submitFilesSequentially(fileQ, type, dispatch, getState);
  }
};

export const cleanupOnSave = (dispatch, getState) => {
  //clear upload store
  // dispatch({
  //   type: ActionTypes.SUBMISSION_FILES_CLEAR,
  //   payload: ""
  // });
  // dispatch({
  //   type: ActionTypes.ATTACHMENT_FILE_CLEAR,
  //   payload: ""
  // });
};

export const cleanupOnSend = (dispatch, getState) => {
  //clear upload store
  cleanupOnCancel(dispatch, getState);
};

export const cleanupOnCancel = (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SUBMISSION_FILES_CLEAR,
    payload: ''
  });

  dispatch({
    type: ActionTypes.ATTACHMENT_FILE_CLEAR,
    payload: ''
  });

  dispatch({
    type: ActionTypes.MEDIA_GROUP_VERSION_DELETE,
    payload: ''
  });

  var data = {};
  data.account = {};
  data.product = {};
  data.releaseDateStatus = '';
  data.rating = '';

  if (getState().packages.isOneAccountOnly) {
    dispatch({
      type: ActionTypes.CLEAR_PACKAGE_CONTENT_EXCEPT_ACCOUNT,
      payload: data
    });
  } else {
    dispatch({
      type: ActionTypes.CLEAR_PACKAGE_CONTENT,
      payload: data
    });
  }

  dispatch({
    type: ActionTypes.CLEAR_SAVE_SUBMISSION,
    payload: ''
  });

  dispatch({
    type: ActionTypes.CLEAR_RECIPIENTS,
    payload: ''
  });

  dispatch({
    type: ActionTypes.CLEAR_ALL_REQUEST_ID,
    payload: ''
  });
};

//recreate submissionFile, attachmentFile, saveSubmission, saveMediaRequestID store
export const editPackageStoreUpdate = (dispatch, getState, response) => {
  var serverData = response.data.mediasUploaded;

  //clean up saveSubmission Store
  dispatch({
    type: ActionTypes.CLEAR_SAVE_SUBMISSION,
    payload: ''
  });

  //clean up uploadSubmissionFile Store
  dispatch({
    type: ActionTypes.SUBMISSION_FILE_DELETE_SUCCESS,
    payload: ''
  });

  //clean up uploadAttachmentFile Store
  dispatch({
    type: ActionTypes.ATTACHMENT_FILE_DELETE_SUCCESS,
    payload: ''
  });

  //cleanup mediaRequestIDs
  // dispatch({
  //   type: ActionTypes.CLEAR_ALL_REQUEST_ID,
  //   payload: ""
  // });

  //recreate mediaGroup in saveSubmissionReducer
  let submissionPackage = {};
  let mediaGroup = {};

  mediaGroup.id = serverData.mediaGroup.id;
  mediaGroup.name = serverData.mediaGroup.name;
  mediaGroup.desc = serverData.mediaGroup.desc;
  mediaGroup.dateSubmitted = serverData.mediaGroup.dateSubmitted;
  mediaGroup.submitterComment = serverData.mediaGroup.submitterComment;
  mediaGroup.referenceCode = serverData.mediaGroup.referenceCode;
  mediaGroup.version = serverData.mediaGroup.version;
  mediaGroup.userId = serverData.mediaGroup.userId;
  mediaGroup.submitterId = serverData.mediaGroup.submitterId;

  mediaGroup.medias = null;
  mediaGroup.attachments = null;

  submissionPackage.mediaGroup = mediaGroup;
  submissionPackage.productUpdate = serverData.productUpdate;

  dispatch({
    type: ActionTypes.SAVE_SUBMISSION_MEDIA_STAGE,
    payload: submissionPackage
  });

  _.forEach(serverData.mediaGroup.medias, function (media) {
    var currentFile = {
      mediaID: media.mediaID,
      forwardedMediaID: media.forwardedMediaID,
      forwardedRootMediaID: media.forwardedRootMediaID,
      desc: media.desc,
      status: media.status,
      draftStatus: media.draftStatus,
      orderInGroup: media.orderInGroup,
      fileName: media.fileName,
      filePath: media.filePath,
      mediaUploadRequestId: media.mediaUploadRequestId,
      priority: media.priority,
      created: media.created,
      length: media.length === null ? 0 : media.length,
      cutType: Utils.getCutTypeName(media.cutType),
      cutTypeID: media.cutType,
      downloadableByReviewers: media.downloadableByReviewers,
      adCode: media.isci,
      relatedCodes: media.relatedCodes === null ? [] : media.relatedCodes,
      comment: media.submitterMess,
      referenceCode: media.referenceCode,
      mediaTypeID: media.mediaTypeID,
      version: media.version,
      versionName: media.versionName,
      region: media.region === 'NOT_APPLICABLE' ? '' : media.region,
      mediaIssues: media.mediaIssues,

      delivery: media.delivery,
      results: media.results,
      mediaExportId: media.mediaExportId,
      mediaExportKey: media.mediaExportKey,
      mediaExportName: media.mediaExportName,
      verification: media.verification,

      displayName: media.name, //todo
      fileType: Utils.getFileType(media.fileName),

      mediaTypeString: Utils.getMediaStringForID(
        media.mediaTypeID,
        getState().staticData.data.submitterUserStaticInfo.submitterUserStaticData.allMediaTypes.mediaTypes
      ),

      uploadStatus: FileStatus.SAVED
    };

    dispatch({
      type: ActionTypes.SUBMISSION_FILE_STAGE_SUCCESS,
      payload: currentFile
    });
  });

  _.forEach(serverData.mediaGroup.attachments, function (attachment) {
    var currentAttachment = {
      id: attachment.id,
      forwardedID: attachment.forwardedID,
      fileName: attachment.fileName,
      attachmentRequestId: attachment.attachmentRequestId,
      fileChecksum: attachment.fileChecksum,
      fileSize: attachment.fileSize,
      filePath: attachment.fileType,
      destinations: attachment.destinations.destination,
      attachmentType: attachment.type,
      version: attachment.version,
      displayName: attachment.fileName,
      fileType: Utils.getFileType(attachment.fileName),
      uploadStatus: FileStatus.SAVED,
      asset: attachment.asset
    };

    dispatch({
      type: ActionTypes.ATTACHMENT_FILE_STAGE_SUCCESS,
      payload: currentAttachment
    });
  });
};

// todo RECONSTRUCTING THE PACKAGE should be consolidated to various
// generic functions
// recreate submissionFile, attachmentFile, saveSubmission, saveMediaRequestID store
export async function loadPackageForEditingOrForward(dispatch, getState, savedMediaGroup, editOrForward, withAttachments = true) {
  //recreate mediaGroup in saveSubmissionReducer
  const { mediaTypes } = getState().staticData.data.submitterUserStaticInfo.submitterUserStaticData.allMediaTypes;
  let submissionPackage = {};

  const assignedUsers = savedMediaGroup.media[0].broadcasterResponses.reduce((assignedUsers, { user }) => {
    if (user) {
      assignedUsers.push({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
        ...user
      });
    }
    return assignedUsers;
  }, []);

  const mediaGroup = {
    id: editOrForward === constants.PACKAGE_EDIT ? savedMediaGroup.id : 0,
    name: savedMediaGroup.name,
    desc: savedMediaGroup.desc,
    dateSubmitted: savedMediaGroup.dateSubmitted,
    submitterComment: savedMediaGroup.submitterComment,
    referenceCode: savedMediaGroup.referenceCode,
    version: editOrForward === constants.PACKAGE_EDIT ? savedMediaGroup.version : 0,
    userId: savedMediaGroup.userId,
    submitterId: savedMediaGroup.submitterId,
    medias: null,
    assignedUsers: assignedUsers,
    attachments: null
  };

  submissionPackage.mediaGroup = mediaGroup;
  // submissionPackage.productUpdate = serverData.productUpdate;

  dispatch({
    type: ActionTypes.SAVE_SUBMISSION_MEDIA_STAGE,
    payload: submissionPackage
  });

  dispatch({
    type: ActionTypes.ADD_PO_NUMBER,
    payload: savedMediaGroup.referenceCode
  });

  let poStatus = savedMediaGroup.referenceCode;
  if (poStatus !== constants.PO_STATUS_NOT_REQUIRED && poStatus !== constants.PO_STATUS_PROVIDED_LATER) {
    poStatus = constants.PO_STATUS_REQUIRED;
  }

  dispatch({
    type: ActionTypes.ADD_PO_STATUS,
    payload: poStatus
  });

  // debugger;

  savedMediaGroup.media.forEach((media) => {
    const { id } = media;
    const currentFile = {
      mediaID: editOrForward === constants.PACKAGE_EDIT ? id : 0,
      forwardedMediaID: editOrForward === constants.PACKAGE_EDIT ? '' : id,
      forwardedRootMediaID: editOrForward === constants.PACKAGE_EDIT ? '' : id,
      desc: media.desc,
      status: media.status,
      draftStatus: media.draftStatus,
      orderInGroup: media.orderInGroup,
      fileName: media.fileName,
      filePath: media.filePath,
      mediaUploadRequestId: media.mediaUploadRequestId,
      priority: media.priority,
      created: media.created,
      length: media.length === null ? 0 : media.length,
      cutType: Utils.getCutTypeName(media.cutType),
      cutTypeID: media.cutType,
      downloadableByReviewers: media.downloadableByReviewers,
      adCode: media.isci,
      relatedCodes: media.relatedCodes === null ? [] : media.relatedCodes,
      comment: media.submitterMess,
      referenceCode: media.referenceCode,
      mediaTypeID: media.mediaTypeID,
      version: media.version,
      versionName: media.versionName,
      region: media.region === 'NOT_APPLICABLE' ? '' : media.region,
      mediaIssues: media.mediaIssues,

      delivery: media.delivery,
      results: media.results,
      mediaExportKey: media.mediaExportKey,
      mediaExportName: media.mediaExportName,
      mediaExportId: media.mediaExportId,
      verification: media.verification,

      agencyID: media.agencyID,
      productID: media.productID,
      broadcasterResponses: media.broadcasterResponses,

      displayName: media.name, //mediaExportName, //todo
      fileType: Utils.getFileType(media.fileName),

      mediaTypeString: Utils.getMediaStringForID(
        media.mediaTypeID,
        getState().staticData.data.submitterUserStaticInfo.submitterUserStaticData.allMediaTypes.mediaTypes
      ),

      uploadStatus: FileStatus.SAVED
    };

    dispatch({
      type: ActionTypes.SUBMISSION_FILE_STAGE_SUCCESS,
      payload: currentFile
    });
  });
  if (withAttachments) {
    savedMediaGroup.attachments.forEach((attachment) => {
      const currentAttachment = {
        id: editOrForward === 0 ? attachment.id : '0',
        forwardedID: editOrForward === 0 ? '0' : attachment.id,
        fileName: attachment.fileName,
        attachmentRequestId: attachment.attachmentRequestID,
        fileChecksum: attachment.asset.fileChecksum,
        fileSize: attachment.asset.fileSize,
        filePath: attachment.asset.fileLocation,

        destinations: editOrForward === constants.PACKAGE_EDIT ? attachment.destinations.destination : [{ value: '-1', label: 'All' }],
        attachmentType: attachment.type,
        version: attachment.version,
        displayName: attachment.fileName,
        fileType: Utils.getFileType(attachment.fileName),
        uploadStatus: FileStatus.SAVED,
        asset: attachment.asset
      };

      dispatch({
        type: ActionTypes.ATTACHMENT_FILE_STAGE_SUCCESS,
        payload: currentAttachment
      });
    });
  }
}

export const getRecipients = (allRecipients, submitterId, broadcasters, accounts) => {
  let allRecipientsInfo = {};

  if (allRecipients !== undefined) {
    let availableDepartments = [];
    let destinations = [];

    //get sort order for all broadcasterIDs  [{broadcasterID : "4", sortOrder : "1"}, ...]
    let requiredRelationship = getSubmitterBroadcasterRelationShips(submitterId, accounts);

    //make list of all departments and assign sort order
    _.forEach(broadcasters, (broadcaster) => {
      _.forEach(broadcaster.departments, (department) => {
        department.sortOrder = getSortOrderForRecipient(broadcaster.broadcasterID, requiredRelationship);
        availableDepartments.push(department);
      });
    });

    //filter departments leaving only ones in the allRecipients
    _.forEach(allRecipients, (recipient) => {
      _.forEach(availableDepartments, (department) => {
        if (parseInt(recipient.departmentID, 10) === parseInt(department.departmentID, 10)) {
          department.receivedStatus = recipient.receivedStatus;
          department.requestStatus = recipient.requestStatus;
          destinations.push(department);
        }
      });
    });

    //sort according to sort order
    destinations.sort((a, b) => {
      var orderA = parseInt(a.sortOrder, 10);
      var orderB = parseInt(b.sortOrder, 10);
      return orderA - orderB;
    });

    //prepare output destinations
    let i = 0;
    destinations.map((p) => {
      //get only needed data to reduce memory
      let detail = {
        receivedStatus: p.receivedStatus,
        recipientName: p.departmentName,
        requestStatus: p.requestStatus
      };

      //add keys to each destination
      allRecipientsInfo[i] = detail;
      i++;
    });
  } //endif

  return allRecipientsInfo;
};

export const getSubmitterBroadcasterRelationShips = (submitterID, accounts) => {
  let relationship = '';

  _.forEach(accounts, function (currentAccount) {
    if (submitterID === parseInt(currentAccount.id, 10)) {
      relationship = currentAccount.submitterBroadcasterRelationships;
      return false;
    }
  });

  return relationship;
};

export const getSortOrderForRecipient = (broadcasterID, submitterBroadcasterRelations) => {
  let sortOrder = 0;
  _.forEach(submitterBroadcasterRelations, function (currentRelation) {
    if (parseInt(broadcasterID, 10) === parseInt(currentRelation.broadcasterID, 10)) {
      sortOrder = currentRelation.sortOrder;
      return false;
    }
  });

  return sortOrder;
};

export const isProductForAgency = (agencyID, product) => {
  var agencyRelationships = product.agencyRelationships;
  let agencyMatchFound = false;
  _.forEach(agencyRelationships, function (relation) {
    if (parseInt(relation.agencyID, 10) === parseInt(agencyID, 10)) {
      agencyMatchFound |= true;
      return false;
    }
  });

  return agencyMatchFound;
};

export const isProductActiveForAgency = (agencyID, product) => {
  var agencyRelationships = product.agencyRelationships;
  let agencyMatchFound = false;
  _.forEach(agencyRelationships, function (relation) {
    if (parseInt(relation.agencyID, 10) === parseInt(agencyID, 10)) {
      if (relation.status === ACTIVE) {
        agencyMatchFound |= true;
        return false;
      }
    }
  });

  return agencyMatchFound;
};

export const formatDestinations = (destString) => {
  // var destinationArray = []

  // if(_.size(destString) === 0)
  // 	destinationArray[0]="-1";
  // else if(typeof destString==="string")
  // 	destinationArray = destString.split(",");
  // else //it is an array already
  //   destinationArray = destString;

  const a = destString ? destString.map(({ value }) => value) : ['-1'];
  return { destination: a };
};

export const getDestinationsForSelec = (destinations, isEditView, broadcasters) => {
  if (isEditView) {
    const destArray = destinations?.map((destId) => {
      for (const { departments } of broadcasters) {
        const deptValue = departments.find(({ departmentID }) => departmentID === destId);
        if (deptValue) {
          return { value: deptValue.departmentID, label: deptValue.departmentName };
        }
      }
    });

    return destArray;
  } else {
    return [{ value: '-1', label: 'All' }];
  }
};

// export async function loadPackageForEditingOrForward(
//   dispatch,
//   getState,
//   savedMediaGroup,
//   editOrForward,
//   withAttachments = true,
// ) {
//   //recreate mediaGroup in saveSubmissionReducer
//   const { mediaTypes } = getState().staticData.data.submitterUserStaticInfo.submitterUserStaticData.allMediaTypes;
//   let submissionPackage = {};
//   let mediaGroup = {};

//   const assignedUsers = savedMediaGroup.media[0].broadcasterResponses.reduce((assignedUsers, { user }) => {
//     if (user) {
//       assignedUsers.push({
//         value: user.id,
//         label: `${user.firstName} ${user.lastName}`,
//         ...user
//       });
//     }
//     return assignedUsers;
//   }, []);

//   mediaGroup.id = editOrForward === constants.PACKAGE_EDIT ? savedMediaGroup.id : 0;
//   mediaGroup.name = savedMediaGroup.name;
//   mediaGroup.desc = savedMediaGroup.desc;
//   mediaGroup.dateSubmitted = savedMediaGroup.dateSubmitted;
//   mediaGroup.submitterComment = savedMediaGroup.submitterComment;
//   mediaGroup.referenceCode = savedMediaGroup.referenceCode;
//   mediaGroup.version = editOrForward === constants.PACKAGE_EDIT ? savedMediaGroup.version : 0;
//   mediaGroup.userId = savedMediaGroup.userId;
//   mediaGroup.submitterId = savedMediaGroup.submitterId;
//   mediaGroup.medias = null;
// 	mediaGroup.assignedUsers = assignedUsers;
// 	mediaGroup.attachments = null;

//   submissionPackage.mediaGroup = mediaGroup;
//   // submissionPackage.productUpdate = serverData.productUpdate;

//   dispatch({
//     type: ActionTypes.SAVE_SUBMISSION_MEDIA_STAGE,
//     payload: submissionPackage
//   });

//   dispatch({
//     type: ActionTypes.ADD_PO_NUMBER,
//     payload: savedMediaGroup.referenceCode
//   });

// 	let poStatus = savedMediaGroup.referenceCode
// 	if (poStatus !== constants.PO_STATUS_NOT_REQUIRED && poStatus !== constants.PO_STATUS_PROVIDED_LATER) {
// 		poStatus=constants.PO_STATUS_REQUIRED
//   }

//   dispatch({
//     type: ActionTypes.ADD_PO_STATUS,
//     payload: poStatus
//   });

//   savedMediaGroup.media.forEach((media) => {
//     const currentFile = { ...media };
//     currentFile.mediaID = editOrForward === constants.PACKAGE_EDIT ? media.id : 0;
//     currentFile.forwardedMediaID = constants.PACKAGE_EDIT ? '' : media.id;
//     currentFile.mediforwardedRootMediaIDaID = editOrForward === constants.PACKAGE_EDIT ? '' : media.id;
//     currentFile.length =  currentFile.length === null ? 0 : currentFile.length;
//     currentFile.cutType = Utils.getCutTypeName(media.cutType);
//     currentFile.adCode = currentFile.isci;
//     currentFile.relatedCodes = currentFile.relatedCodes === null ? {} : currentFile.relatedCodes;
//     currentFile.comment = currentFile.submitterMess;
//     currentFile.region = currentFile.region === 'NOT_APPLICABLE' ? '' : currentFile.region;
//     currentFile.displayName = currentFile.name;
//     currentFile.fileType = Utils.getFileType(currentFile.fileName);
//     currentFile.mediaTypeString =  Utils.getMediaStringForID(currentFile.mediaTypeID, mediaTypes);
//     currentFile.uploadStatus = FileStatus.SAVED;

//     dispatch({
//       type: ActionTypes.SUBMISSION_FILE_STAGE_SUCCESS,
//       payload: currentFile
//     });
//   });

//   if (withAttachments) {
//     savedMediaGroup.attachments.forEach((attachment) => {
//       const currentAttachment = { ...attachment };
//       if (editOrForward === 0) {
//         currentAttachment.id = '';
//         currentAttachment.forwardedID = '';
//       }
//       currentAttachment.attachmentRequestId = attachment.attachmentRequestID;
//       currentAttachment.fileChecksum = currentAttachment.asset.fileChecksum;
//       currentAttachment.fileSize = currentAttachment.asset.fileSize;
//       currentAttachment.filePath = currentAttachment.asset.fileLocation;
//       currentAttachment.destinations = currentAttachment.destinations.destination
//       currentAttachment.attachmentType = currentAttachment.type;
//       currentAttachment.displayName = currentAttachment.fileName;
//       currentAttachment.fileType = Utils.getFileType(currentAttachment.fileName);
//       currentAttachment.uploadStatus = FileStatus.SAVED;

//       dispatch({
//         type: ActionTypes.ATTACHMENT_FILE_STAGE_SUCCESS,
//         payload: currentAttachment
//       });
//     });
//   }
// }
