import React from 'react';
import { Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import addSeconds from 'date-fns/addSeconds';
import format from 'date-fns/format';
import * as ROUTES from 'src/constants/Routes';
import * as moment from 'moment';

//IE 9 support
export * from '../utils/CompatabilityUtils';

//Constants

//Validation utils

//UI utils

//ID Mappers

//General utils

//Special utils
export * from '../utils/PackageUtils';
export * from '../utils/DatagridUtils';
export * from '../utils/DataGridRowUtils';
export * from '../utils/BroadcasterUtils';
export * from './DownloadUtils';

//Constants
export const MAX_FILENAME_CHARACTER_LIMIT = 50;

//DELIVERY STATUS (receivedStatus)
export const NO_CONFIRMATION = 0;
export const METADATA_TRANSFERRED = 1;
export const FILE_TRANSFERRED = 2;
export const RESPONSE_RECEIVED = 3;

//REQUEST STATUS
export const NO_REQUEST = 0;
export const RECALL_PENDING = 1;
export const RECALL_FAIL = 2;
export const RECALL_SUCCESS = 3;
export const EDIT_PENDING = 4;
export const EDIT_FAIL = 5;
export const EDIT_SUCCESS = 6;

//UPLOAD STATUS
export const UPLOAD_COMPLETED = 6;
export const UPLOAD_NOTCOMPLETED = 7;
export const UPLOAD_RECALLED = 8;
export const UPLOAD_PENDING_TRANSCODING = 9;
export const UPLOAD_TRANSCODING_COMPLETE = 10;
export const UPLOAD_VERIFIED = 11;
export const UPLOAD_READY4DIST = 12; //this is from legacy. Yes, with '4' for 'FOR'

//DRAFT STATUS
export const FINALIZED = 0;
export const DRAFT_WITHOUT_FILE = 1;
export const DRAFT_WITH_FILE = 2;

//UNIFIED STATUS
export const PROCESSING = 0;
export const DRAFT = 1;
export const NOT_YET_RECEIVED = 2;
export const DELIVERY_IN_PROGRESS = 3;
export const RECEIVED = 4;
export const REVIEWS_IN_PROGRESS = 5;
export const REVIEWS_COMPLETED = 6;
export const DELIVERING_BUT_HAS_REVIEWS = 7;
export const OTHER = 8;

//Validation utils
const isVideoAsset = () => new RegExp(/(\w+)\.(mp4|mov|m4v)/g);
const imageJPG = () => new RegExp(/(w+)*(.jp)(e)*(g)/);
const imagePNG = () => new RegExp(/(w+)*(.png)/);
const audioMP3 = () => new RegExp(/(w+)*(.mp3)/);
const specialCharacters = () => new RegExp(/[~`’!#$%\^&*+=\\[\]\\';/{}|\\":<>\?]/); //eslint-disable-line
const validEmail = () => new RegExp(/[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-.]+)$/);
const validVersion = () => new RegExp(/^\d+\.\d+\.\d+$/);
const validStatus = ['SAVED', 'UPLOADED'];

export const isAssetEditable = (status) => !validStatus.includes(status);

export const formatSeconds = (seconds) => {
  if (Number(seconds)) {
    const helperDate = addSeconds(new Date(0), seconds);
    return format(helperDate, 'm:ss');
  } else {
    return 'N/A';
  }
};

export const isEmailValid = (email) => {
  return validEmail().exec(email) !== null;
};

export const isVersionValid = (version) => {
  return validVersion().test(version);
};

export const hasSingleDotOnly = (fileName) => {
  var subStrings = _.split(fileName, '.');
  if (subStrings.length !== 2) {
    return false;
  }
  return true;
};

export const hasWhiteSpaceAfterExtension = (fileName) => {
  const subStrings = fileName.split('.');
  const result = /\s/g.test(subStrings[1]);
  return result;
};

export const hasWhiteSpaceJustBeforeExtension = (fileName) => {
  const subStrings = fileName.split('.');
  const result = /.\s$/g.test(subStrings[0]);
  return result;
};

export const isValidName = (fileName) => {
  return (
    specialCharacters().exec(fileName) === null && !hasWhiteSpaceAfterExtension(fileName) && !hasWhiteSpaceJustBeforeExtension(fileName)
  );
};

export const isEditPath = (path) => {
  return _.includes(path, ROUTES.EDIT_PACKAGE);
};

export const isPlaybackPath = (path) => {
  return _.includes(path, ROUTES.PLAY);
};

export const isForwardPath = (path) => {
  return _.includes(path, ROUTES.FORWARD_PACKAGE);
};

export const isCreatePackagePath = (path) => {
  return _.includes(path, ROUTES.CREATE_PACKAGE);
};

export const isDashboardPath = (path) => {
  return _.includes(path, ROUTES.DASHBOARD);
};

export const diffArrays = (A, B) => {
  return A.filter((x) => !B.includes(x));
};

export const getLocalTime = (serverTime) => {
  let serverDate = moment(serverTime).local().format('YYYY-MM-DD HH:mm:ss');

  return serverDate;
};

//UI utils
export const createTitleTag = (title) => {
  let envPrefix = process.env.REACT_APP_ENV_LABEL;

  if (envPrefix !== undefined && envPrefix !== 'PROD') {
    envPrefix = '[' + envPrefix + '] ';
  } else {
    envPrefix = '';
  }

  return (
    <Helmet>
      <title>
        {envPrefix}
        {title}
      </title>
    </Helmet>
  );
};

export const createMetaTag = (name, content) => {
  return (
    <Helmet>
      <meta name={name} content={content} />
    </Helmet>
  );
};
export const createTooltip = (name, text) => {
  return <Tooltip id={`tooltip-${name}`}>{text}</Tooltip>;
};

export const createTooltip2 = (name, text1, text2) => {
  return (
    <Tooltip id={`tooltip-${name}`}>
      <div>{text1}</div>
      <br />
      <div> {text2}</div>
    </Tooltip>
  );
};

//ID Mappers

export const getDepartmentNameFromID = (deptIDs, broadcasterStatic) => {
  let departmentNames = [];

  //make list of all departments and assign sort order
  _.forEach(broadcasterStatic, (broadcaster) => {
    _.forEach(broadcaster.departments, (department) => {
      if (deptIDs.includes('' + department.departmentID) || deptIDs.includes(parseInt(department.departmentID, 10)))
        departmentNames.push(department.departmentNameShort);
    });
  });

  return departmentNames;
};

export const mapDestinationIDsToNames = (destinationIDs, allowedRecipients) => {
  let names = [];

  _.forEach(destinationIDs, function (d) {
    _.forEach(allowedRecipients, function (r) {
      if (d.destination === r.broadcasterID) {
        names.push(r.broadcasterName);
      }
    });
  });

  return names;
};

export const getCutTypeID = (cutType) => {
  switch (cutType) {
    case 'Rough cut':
      return 0;
    case 'Final cut':
      return 1;
    case 'Concept':
      return 2;
    default:
      return 3;
  }
};

export const getCutTypeName = (cutTypeID) => {
  switch (cutTypeID) {
    case 0:
      return 'Rough cut';
    case 1:
      return 'Final cut';
    case 2:
      return 'Concept';
    default:
      return 'N/A';
  }
};

export const getMediaTypeForString = (mediaTypeText, allMediaTypes) => {
  let id = -1;
  _.forEach(allMediaTypes, function (m) {
    if (m.name === mediaTypeText) {
      id = m.id;
    }
  });

  return id;
};

export const getMediaStringForID = (mediaTypeID, allMediaTypes) => {
  const mediaType = allMediaTypes.find(({ id }) => parseInt(id, 10) === parseInt(mediaTypeID, 10));
  return mediaType ? mediaType.name : 'unknown type';
};

export const getAttachmentTypeString = (attachmentTypeID) => {
  switch (attachmentTypeID) {
    case 0:
      return 'Animal Treatment Letter';
    case 1:
      return 'Product Sample';
    case 2:
      return 'Contest/Sweepstakes Rules';
    case 3:
      return 'Complete Prescribing Information';
    case 4:
      return 'Foreign Language Translation';
    default:
      return '';
  }
};

export const getAttachmentTypeID = (attachmentTypeString) => {
  switch (attachmentTypeString) {
    case 0:
      return 'Animal Treatment Letter';
    case 1:
      return 'Product Sample';
    case 2:
      return 'Contest/Sweepstakes Rules';
    case 3:
      return;
    case 4:
      return 'Foreign Language Translation';
    default:
      return -1;
  }
};

export const getBroadcasterNameForID = (allBroadcasters, departmentId, broadcasterId) => {
  if (departmentId === broadcasterId) {
    const broadcaster = allBroadcasters.find(({ broadcasterID }) => parseInt(broadcasterID, 10) === broadcasterId);
    return broadcaster ? broadcaster.broadcasterName : '';
  } else {
    const broadcaster = allBroadcasters.find(({ broadcasterID }) => parseInt(broadcasterID, 10) === broadcasterId);
    const department = broadcaster.departments.find(({ departmentID }) => parseInt(departmentID, 10) === departmentId);
    return department ? department.departmentName : '';
  }
};

export const lookupMediaGroup = (mediaGroupID, inboxData) => {
  let mediaGroup = undefined;
  _.forEach(inboxData, function (item) {
    if (item.id === parseInt(mediaGroupID, 10)) {
      mediaGroup = item;
      return false;
    }
  });
  return mediaGroup;
};

//General utils

export const sortAlphabetical = (input) => input.sort((a, b) => 2 * (a.displayName.toLowerCase() > b.displayName.toLowerCase()) - 1);

export const sortByTime = (input) => input.sort((a, b) => 2 * a.timeStamp > b.timeStamp - 1);

export const matchVideo = (input) => {
  const lowerInput = input.toLowerCase();
  return isVideoAsset().test(lowerInput);
};

export const matchAudio = (input) => {
  let lowerInput = input.toLowerCase();
  return audioMP3().test(lowerInput);
};

export const matchImage = (input) => {
  return imageJPG().test(input) || imagePNG().test(input);
};

export const trimFileName = (fileName) => {
  return fileName.length <= MAX_FILENAME_CHARACTER_LIMIT
    ? fileName
    : fileName.slice(0, MAX_FILENAME_CHARACTER_LIMIT - fileName.length) + '...';
};

export const getFileType = (fileName) => {
  return fileName.split('.').pop();
};

export const hasSpecialCharacters = (fileName) => {
  return specialCharacters().test(fileName);
};

export const bytesToKBytes = (sizeB) => Math.round(sizeB / 1024);

//todo this is a hack; must be a better way to check if data is available
export const getSafe = (fn) => {
  try {
    return fn();
  } catch (e) {
    return undefined;
  }
};

export const randomID = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export const Boolean = (boolean) => {
  return boolean === 'true' ? true : false;
};

export const toLowerCase = (label) => {
  if (label !== undefined && label.length > 2) {
    let firstChar = label.substring(0, 1);
    let tail = label.substring(1);
    return firstChar.toUpperCase() + tail.toLowerCase();
  } else {
    return label;
  }
};

export const isImage = (ext) => {
  switch (ext) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
      return true;
    default:
      return false;
  }
};

export const isPdf = (ext) => {
  return ext.split('.').pop().toLowerCase() === 'pdf';
};

export const isVideo = (ext) => {
  switch (ext.split('.').pop().toLowerCase()) {
    case 'mov':
    case 'mp4':
    case 'm4v':
      return true;
    default:
      return false;
  }
};

export const isAudio = (ext) => {
  return ext.split('.').pop().toLowerCase() === 'mp3';
};

export const isChrome = () => {
  var isChromium = window.chrome;
  var winNav = window.navigator;
  var vendorName = winNav.vendor;
  var isOpera = typeof window.opr !== 'undefined';
  var isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  var isIOSChrome = winNav.userAgent.match('CriOS');

  if (isIOSChrome) {
    // is Google Chrome on IOS
    return true;
  } else if (
    isChromium !== null &&
    typeof isChromium !== 'undefined' &&
    vendorName === 'Google Inc.' &&
    isOpera === false &&
    isIEedge === false
  ) {
    return true;
  } else {
    return false;
  }
};

//Special utils
export const createMediaTypes = (mediaTypes) => {
  const spotlightedMediaIds = ['1', '8'];
  const mediaTypesSorted = mediaTypes
    .reduce((resultingMediaTypes, { id: value, name, sendableIndividually }) => {
      if (sendableIndividually) {
        if (spotlightedMediaIds.findIndex((spotlightedMediaId) => spotlightedMediaId === value) === -1) {
          resultingMediaTypes.push({ value, name });
        }
      }
      return resultingMediaTypes;
    }, [])
    .sort((a, b) => 2 * (a.name.toLowerCase() > b.name.toLowerCase()) - 1);

  const spotlightedMediaTypes = spotlightedMediaIds.reduce((spotlightIdArray, spotlightId) => {
    const mediaType = mediaTypes.find(({ id, sendableIndividually }) => sendableIndividually && id === spotlightId);
    if (mediaType) {
      spotlightIdArray.push({
        value: mediaType.id,
        name: mediaType.name
      });
    }
    return spotlightIdArray;
  }, []);

  return spotlightedMediaTypes.concat(mediaTypesSorted);
};

export const createAttachmentTypes = (mediaTypes) =>
  mediaTypes
    .reduce((resultingMediaTypes, { id: value, attachable, name }) => {
      if (attachable && name !== 'Unspecified') {
        resultingMediaTypes.push({ value, name });
      }
      return resultingMediaTypes;
    }, [])
    .sort((a, b) => 2 * (a.name.toLowerCase() > b.name.toLowerCase()) - 1);

// TODO: refactor to switch statement

export const getStatusColors = (uploadStatus, requestStatus, deliveryStatus, draftStatus) => {
  var colorClass;

  if (uploadStatus === UPLOAD_PENDING_TRANSCODING || uploadStatus === UPLOAD_TRANSCODING_COMPLETE || uploadStatus === UPLOAD_VERIFIED) {
    colorClass = 'optimizing';
  } else if (draftStatus === DRAFT_WITHOUT_FILE || draftStatus === DRAFT_WITH_FILE) {
    colorClass = 'draft';
  } else if (
    (deliveryStatus === NO_CONFIRMATION && requestStatus === NO_REQUEST) ||
    (deliveryStatus === METADATA_TRANSFERRED && requestStatus === NO_REQUEST)
  ) {
    colorClass = 'not-yet-received';
  } else if (
    (deliveryStatus === NO_CONFIRMATION && requestStatus === RECALL_PENDING) ||
    (deliveryStatus === NO_CONFIRMATION && requestStatus === RECALL_FAIL) ||
    (deliveryStatus === METADATA_TRANSFERRED && requestStatus === RECALL_PENDING) ||
    (deliveryStatus === METADATA_TRANSFERRED && requestStatus === RECALL_FAIL)
  ) {
    colorClass = 'not-yet-received-recall';
  } else if (
    (deliveryStatus === NO_CONFIRMATION && requestStatus === RECALL_SUCCESS) ||
    (deliveryStatus === METADATA_TRANSFERRED && requestStatus === RECALL_SUCCESS)
  ) {
    colorClass = 'not-received-recall-success';
  } else if (deliveryStatus === FILE_TRANSFERRED && requestStatus === NO_REQUEST) {
    colorClass = 'received';
  } else if (
    (deliveryStatus === FILE_TRANSFERRED && requestStatus === RECALL_PENDING) ||
    (deliveryStatus === FILE_TRANSFERRED && requestStatus === RECALL_FAIL)
  ) {
    colorClass = 'received-recall';
  } else if (deliveryStatus === FILE_TRANSFERRED && requestStatus === RECALL_SUCCESS) {
    colorClass = 'received-recall-success';
  } else if (
    (deliveryStatus === FILE_TRANSFERRED || deliveryStatus === NO_CONFIRMATION) &&
    (requestStatus === EDIT_PENDING || requestStatus === EDIT_FAIL || requestStatus === EDIT_SUCCESS)
  ) {
    colorClass = 'received-edit';
  } else if (
    (deliveryStatus === RESPONSE_RECEIVED && requestStatus === NO_REQUEST) ||
    (deliveryStatus === RESPONSE_RECEIVED && requestStatus === EDIT_PENDING) ||
    (deliveryStatus === RESPONSE_RECEIVED && requestStatus === EDIT_FAIL) ||
    (deliveryStatus === RESPONSE_RECEIVED && requestStatus === EDIT_SUCCESS)
  ) {
    colorClass = 'reviewed';
  } else if (
    (deliveryStatus === RESPONSE_RECEIVED && requestStatus === RECALL_PENDING) ||
    (deliveryStatus === RESPONSE_RECEIVED && requestStatus === RECALL_FAIL) ||
    (deliveryStatus === RESPONSE_RECEIVED && requestStatus === RECALL_SUCCESS)
  ) {
    colorClass = 'reviewed-recall';
  } else {
    colorClass = 'other';
  }

  return colorClass;
};

export const generateToolTip = (deliveryStatus, requestStatus, uploadStatus, draftStatus, deliveryLabels, requestLabels, draftLabels) => {
  let deliveryText = '';
  let requestText = '';
  let unexpectedDeliveryStatus = false;
  let unexpectedRequestStatus = false;

  //Draft and Transcoding status ommits next switches:
  //need to pass media data from callee

  switch (deliveryStatus) {
    case NO_CONFIRMATION:
      deliveryText = deliveryLabels.NO_CONFIRMATION;
      break;
    case METADATA_TRANSFERRED:
      deliveryText = deliveryLabels.METADATA_TRANSFERRED;
      break;
    case FILE_TRANSFERRED:
      deliveryText = deliveryLabels.FILE_TRANSFERRED;
      break;
    case RESPONSE_RECEIVED:
      deliveryText = deliveryLabels.RESPONSE_RECEIVED;
      break;
    default:
      unexpectedDeliveryStatus = true;
      break;
  }

  switch (requestStatus) {
    case NO_REQUEST:
      requestText = requestLabels.NO_REQUEST;
      break;
    case RECALL_PENDING:
      requestText = requestLabels.RECALL_PENDING;
      break;
    case RECALL_FAIL:
      requestText = requestLabels.RECALL_FAIL;
      break;
    case RECALL_SUCCESS:
      requestText = requestLabels.RECALL_SUCCESS;
      break;
    case EDIT_PENDING:
      requestText = requestLabels.EDIT_PENDING;
      break;
    case EDIT_FAIL:
      requestText = requestLabels.EDIT_FAIL;
      break;
    case EDIT_SUCCESS:
      requestText = requestLabels.EDIT_SUCCESS;
      break;
    default:
      unexpectedRequestStatus = true;
      break;
  }

  if (requestText) {
    return deliveryText + '; ' + requestText;
  }

  if (unexpectedDeliveryStatus && !unexpectedRequestStatus) {
    deliveryText = 'Error Code 180';
  } else if (!unexpectedDeliveryStatus && unexpectedRequestStatus) {
    deliveryText = 'Error Code 181';
  } else if (unexpectedDeliveryStatus && unexpectedRequestStatus) {
    deliveryText = 'Error Code 182';
  }

  if (uploadStatus === UPLOAD_PENDING_TRANSCODING || uploadStatus === UPLOAD_TRANSCODING_COMPLETE || uploadStatus === UPLOAD_VERIFIED) {
    deliveryText = requestLabels.UPLOAD_TRANSCODING_GENERAL;
  }

  if (draftStatus === DRAFT_WITHOUT_FILE || draftStatus === DRAFT_WITH_FILE) {
    deliveryText = draftLabels.DRAFT_GENERAL;
  }

  return deliveryText;
};

export const previewPdf = (data, name) => {
  try {
    let reader = new FileReader();
    reader.readAsDataURL(new Blob([data]));
    reader.onloadend = () => {
      const base64data = reader.result;
      const base64 = base64data.split(',')[1];
      try {
        let pdfWindow = window.open('');
        pdfWindow.document.write(
          "<iframe frameborder='0' width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(base64) + "'></iframe>"
        );
        pdfWindow.document.write('<title>' + name + '</title>');
        // saveAs(data, name);
      } catch (error) {
        saveAs(data, name);
      }
    };
  } catch (error) {
    saveAs(data, name);
  }
};

export const previewImage = (data, name) => {
  var reader = new FileReader();
  reader.readAsDataURL(new Blob([data]));

  reader.onloadend = function () {
    var base64data = reader.result;
    var base64 = base64data.split(',')[1];

    var image = new Image();
    image.src = 'data:image/jpeg;base64,' + base64;

    try {
      var win = window.open('');
      win.document.write(image.outerHTML);
      win.document.write('<title>' + name + '</title>');
    } catch (error) {
      saveAs(data, name);
    }
  };
};

export const saveAs = (data, name) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
};

export const createPdfUrlToRender = ({ data }) => {
  return window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
};

export const getUserDepartmentNames = (broadcasterId, deptIds, broadcasters) => {
  const broadcaster = broadcasters.find(({ broadcasterID: id }) => parseInt(id, 10) === parseInt(broadcasterId, 10));
  return deptIds.map((deptId) => {
    return broadcaster.departments.find(({ departmentID }) => departmentID === deptId).departmentName;
  });
};
