import { portalIngestedFiles, missingNBCportalMediaIds } from './Constants';

import isWithinInterval from 'date-fns/isWithinInterval';

export const getCompany = (mediaGroup, broadcasterStatic) => {
  const movieID = mediaGroup.movieID;
  const movie = broadcasterStatic[mediaGroup.network].movies.find((m) => m.id === movieID);

  if (movie) {
    const submitter = broadcasterStatic[mediaGroup.network].submitters.find((s) => s.id === movie.productionCompanyID);

    return submitter ? submitter.name : 'N/A';
  }
};

export const getMediaRecipients = (mediaRecipients) => {
  return mediaRecipients.map(({ department, user, status }) => ({
    id: department.slice().shift().departmentID,
    status,
    userId: user.slice().shift().id
  }));
};

export const assignOrGrab = async (selectedUserId, selectedPackages, userAvailableDepartments, broadcasterDocuments, func) => {
  return selectedPackages
    .map((selectedPackage) => {
      const mediaDepartments = getMediaRecipients(
        broadcasterDocuments[selectedPackage.network].mediaGroups
          .find(({ id }) => id === selectedPackage.mediaGroupID)
          .media.slice()
          .shift().mediaRecipients
      ).filter(({ userId }) => userId !== selectedUserId);
      const allowedDepartments = mediaDepartments.reduce((acum, { id }) => {
        const departmentMatch = userAvailableDepartments.find(({ departmentID }) => departmentID === id);
        if (departmentMatch) {
          acum.push(departmentMatch);
        }
        return acum;
      }, []);
      if (allowedDepartments.length > 0) {
        allowedDepartments.forEach(async ({ departmentID }) => {
          const body = {
            departments: [{ departmentID }],
            mediaGroupID: selectedPackage.mediaGroupID,
            userID: selectedUserId
          };

          await func(body, selectedPackage.network);
        });
        return 'applicable';
      } else {
        return 'not applicable';
      }
    })
    .slice()
    .shift();
};

export const assignOrGrabPerDepartment = async (selectedPackages, departmentsAssignee, broadcasterDocuments, singleUserId, action) => {
  const keys = Object.keys(departmentsAssignee);
  for (const { network, mediaGroupID } of selectedPackages) {
    const mediaGroupRecipients = broadcasterDocuments[network].mediaGroups
      .find(({ id }) => id === mediaGroupID)
      .media.slice()
      .shift().mediaRecipients;
    const mediaRecipients = getMediaRecipients(mediaGroupRecipients);
    for (const { id } of mediaRecipients) {
      const keyIndex = keys.findIndex((key) => key === id);
      if (keyIndex !== -1) {
        const departmentId = keys[keyIndex];
        const body = {
          departments: [{ departmentID: departmentId }],
          mediaGroupID: mediaGroupID,
          userID: singleUserId ? singleUserId : departmentsAssignee[departmentId].value
        };
        await action(body, network);
      }
    }
  }
};

export const createOnDeletePayload = (selectedRows, user, userComment) => {
  return selectedRows.map((selectedRow) => {
    let comment = `Deleted from ${user.firstName} ${user.lastName}'s inbox.`;
    if (userComment) {
      comment += ` ${user.firstName}'s comment: ${userComment}.`;
    }
    const media = selectedRow.media.map((media) => {
      const highestReviewValue = media.reviews.reduce((prev, curr, i, reviews) => {
        const version = parseInt(reviews[i].version);
        return version > prev ? version : prev;
      }, 0);

      return {
        mediaId: media.id,
        fileStatusId: '20',
        mediaRecipients: media.mediaRecipients.map((mediaRecipient) => ({
          status: '10',
          department: {
            departmentId: mediaRecipient.department[0].departmentID
          },
          user: { id: user.id }
        })),
        reviews: media.mediaRecipients.map(({ department, user }) => ({
          id: '0',
          additionalReviewers: '',
          comment,
          department: { departmentId: department.slice().shift().departmentID },
          finalized: 'true',
          mediaRatings: [],
          privateComment: '',
          reviewerId: user.slice().shift().id,
          reviewed: '',
          status: '2',
          version: highestReviewValue ? (highestReviewValue + 1).toString() : '0'
        }))
      };
    });

    return {
      network: selectedRow.network,
      departmentID: selectedRow.media[0].mediaRecipients[0].department[0].departmentID,
      body: {
        mediaGroup: {
          mediaGroupId: selectedRow.id,
          media: media
        }
      }
    };
  });
};

// const

export const doesPhysicalFileExist = ({ fileTransferServerId, fileTransferServer, created, id }) => {
  return fileTransferServerId !== '5' && fileTransferServerId !== '4';

  const transferServer = parseInt(fileTransferServerId || fileTransferServer, 10);
  const isPortalIngested = portalIngestedFiles.find(({ serverTransferId }) => serverTransferId === transferServer);
  if (!isPortalIngested) {
    return true;
  }

  // Disabling as per MVU-5022
  // if (transferServer === 2) {
  //   return false;
  //   // this is needed in the future when we have the list of missing assets
  //   return !missingNBCportalMediaIds.includes(id);
  // }

  return portalIngestedFiles.some(({ dateRanges }) =>
    dateRanges.some(({ start, end }) => isWithinInterval(new Date(created), { start, end }))
  );
};
