import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Routes
import * as ROUTES from 'src/constants/Routes';

// Store & Actions
import { toggleTour } from 'src/actions/TourActions';
import { fetchMessages } from 'src/actions/MessageActions';

// Components
import { Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { Badge, Card, CardBody } from '@d3sw/one-ui-components';

import MessagesDropdown from '../messages/MessagesDropdown';

// Styles & Assets
import mvuLogo from 'src/assets/images/mediavu-logo.svg';
import './layout.scss';
import './navbar.scss';

// Helpers
import { shouldEnableMessaging } from 'src/utils/MessagesUtils';

// Constants
import { broadcastersWithClaims, STATIC_IMAGES_BASE_URL } from 'src/utils/Constants';

class MainNavbar extends Component {
  state = {
    unreadMessagesCount: false,
    shouldRenderMessage: false,
    userInfo: {}
  };

  isSubmitterUser = false;
  isReviewerUser = false;
  messagesPoller;
  network = this.props.authUser.reviewerUser ? Object.keys(this.props.authUser.broadcasterUsersInfo)[0] : '';
  hasClaimsAccess = false;

  componentDidMount = () => {
    this.isSubmitterUser = this.props.authUser.submitterUser;
    this.isReviewerUser = this.props.authUser.reviewerUser;
    if (this.isReviewerUser) {
      const { broadcasterID } = this.props.authUser.broadcasterUsersInfo[this.network].broadcaster;
      this.hasClaimsAccess = this.isReviewerUser && broadcastersWithClaims.includes(broadcasterID);
    }

    this.messagesPoller = setInterval(async () => {
      console.log('Fetching Messages via Poller!');
      await this.props.fetchMessages();

      this.setState({
        unreadMessagesCount: this.props.messages.unreadCount
      });
    }, 600000);

    const { firstName, lastName } = this.network ? this.props.authUser.broadcasterUsersInfo[this.network].user : this.props.authUser;
    const { emailId } = this.props.authUser;

    this.setState({
      unreadMessagesCount: this.props.messages.unreadCount,
      shouldRenderMessage: shouldEnableMessaging(this.props.authUser, this.props.userProfile.data),
      userInfo: { firstName, lastName, emailId }
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.messages.unreadCount !== this.props.messages.unreadCount) {
      this.setState({
        unreadMessagesCount: this.props.messages.unreadCount
      });
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.messagesPoller);
  };

  onLogout = () => {
    this.props.history.push(`${ROUTES.LOGOUT}?referrer=header-logout-link`);
  };

  getLogo = () => {
    if (!this.props.authUser && this.props.authUser.logo !== undefined && this.props.authUser.logo !== 'MEDIAVU.png')
      return STATIC_IMAGES_BASE_URL + 'submitter/' + encodeURI(this.props.authUser.logo);
    else return mvuLogo;
  };

  openTour = () => {
    this.props.toggleTour(true);
    const path = this.props.match.path;

    if (!ROUTES.CREATE_PACKAGE.includes(path) && !ROUTES.DASHBOARD.includes(path)) {
      this.props.history.push(ROUTES.DASHBOARD);
    }
  };

  handleRedirection = (path) => {
    this.props.history.push(path);
  };

  render() {
    return (
      <header>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <div className="container-fluid" id="navbar-container">
            <Navbar.Brand onClick={() => this.handleRedirection(ROUTES.DASHBOARD)}>
              <img src={this.getLogo()} alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto text--uppercase">
                <Nav.Link
                    data-testid="dashboard-link"
                    className="navbar-link--custom"
                    onClick={() => this.handleRedirection(ROUTES.DASHBOARD)}>
                  Dashboard
                </Nav.Link>
                {this.props.authUser.submitterUser && (
                  <Nav.Link
                    // className="navbar-link--custom"
                      data-testid="create-package-link"
                    onClick={() => this.handleRedirection(ROUTES.CREATE_PACKAGE)}
                    className="create-package-btn">
                    + Create Package
                  </Nav.Link>
                )}
                {this.hasClaimsAccess && (
                  <Nav.Link
                      data-testid="claims-link"
                      className="navbar-link--custom"
                      onClick={() => this.handleRedirection(ROUTES.CLAIMS)}>
                    Claims
                  </Nav.Link>
                )}
              </Nav>
              <Nav className="navbar-right">
                <Nav.Link
                    data-testid="getting-started-link"
                    className="navbar__item--padding-top"
                    onClick={this.openTour}>
                  GETTING STARTED
                </Nav.Link>
                {this.state.userInfo.firstName && (
                  <NavItem className="navbar__item-name">
                    <p className="navbar-text">
                      {this.state.userInfo.firstName && 'Hello,'} {this.state.userInfo.firstName} {this.state.userInfo.lastName}{' '}
                      <span>{this.state.userInfo.emailId}</span>
                    </p>
                  </NavItem>
                )}
                {this.state.shouldRenderMessage && (
                  <NavDropdown
                    className="messages"
                    id="basic-nav-dropdown"
                    title={
                      <React.Fragment>
                        <i className="fa fa fa-comments nav nav--icon" onClick={this.onMessage} />
                        {this.state.unreadMessagesCount > 0 && <Badge></Badge>}
                      </React.Fragment>
                    }>
                    <MessagesDropdown unreadMessagesCount={this.state.unreadMessagesCount} />
                  </NavDropdown>
                )}
                <NavDropdown id="basic-nav-dropdown" className="user-menu" title={<i className="fa fa-user nav nav--icon" />}>
                  <Card>
                    <CardBody>
                      <ul>
                        <li>
                          <Nav.Link
                              data-testid="user-profile-link"
                              onClick={() => this.handleRedirection(ROUTES.USER_PROFILE)}>
                            User Profile
                          </Nav.Link>
                        </li>
                        <li>
                          <Nav.Link
                              data-testid="user-settings-link"
                              onClick={() => this.handleRedirection(ROUTES.USER_SETTINGS)}>
                            User Settings
                          </Nav.Link>
                        </li>
                        <li data-testid="logout-link" onClick={this.onLogout}>Logout</li>
                      </ul>
                    </CardBody>
                  </Card>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.authUser,
  messages: state.messages,
  staticData: state.staticData,
  userProfile: state.userProfile
});

const mapDispatchToProps = (dispatch) => ({
  fetchMessages: () => dispatch(fetchMessages()),
  toggleTour: (value) => dispatch(toggleTour(value))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNavbar));
