// FrameWork
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import Axios from './axios';

// Components
import App from './App';
import EnvironmentBanner from './components/EnvironmentBanner/EnvironmentBanner';
import ThemeSelector from './themes/themeSelector';

// Utils
import history from './history';
import { createTitleTag } from './utils/Utils';
import DataDog from './utils/dataDog';

import { store } from 'src/store';

// Styles
import 'bootstrap-daterangepicker/daterangepicker.css';
import './assets/styles/main.scss';
import './Index.scss';
import './light-theme.scss';

TagManager.initialize({
  gtmId: 'GTM-PSCWPGJ',
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_ENV,
  dataLayerName: 'PageDataLayer'
});

DataDog.rumSetup();
Axios.setup(store, history);

ReactDOM.render(
  <React.Fragment>
    <ReduxProvider store={store}>
      <React.Fragment>
        {createTitleTag('MediaVu by Deluxe')}
        <EnvironmentBanner />
        <Router history={history}>
          <ThemeSelector>
            <App />
          </ThemeSelector>
        </Router>
      </React.Fragment>
    </ReduxProvider>
  </React.Fragment>,
  document.getElementById('root')
);
//registerServiceWorker();
