import ActionTypes from '../actions/ActionTypes';

export const TourReducer = (
  state = {
    isTourOpen: false
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.OPEN_CLOSE_TOUR: {
      return {
        ...state,
        isTourOpen: action.payload
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        isTourOpen: false
      };
    }
  }

  return state;
};
