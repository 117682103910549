import ActionTypes from '../actions/ActionTypes';

export const ReportReducers = (state = { visible: false, value: null, frameImage: null, reportMediaGroups: [] }, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_REPORT_DIALOG: {
      return {
        ...state,
        visible: action.payload
      };
    }
    case ActionTypes.SELECTED_ROW_REPORT: {
      return {
        ...state,
        value: action.payload
      };
    }
    case ActionTypes.SELECTED_ROW_FRAME_CAPTURE: {
      return {
        ...state,
        frameImage: action.payload
      };
    }
    case ActionTypes.CLEAR_REPORTS: {
      return {
        ...state,
        visible: false,
        value: null,
        frameImage: null
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        visible: false,
        value: null,
        frameImage: null
      };
    }

    case ActionTypes.SELECTED_ROW_PACKAGES: {
      return {
        reportMediaGroups: action.payload
      };
    }
  }
  return state;
};
