import ActionTypes from '../actions/ActionTypes';

export const ResponsesReducer = (
  state = {
    data: '',
    error: '',
    recipients: []
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.LOAD_RESPONSE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        recipients: action.payload.recipients,
        error: ''
      };
    }
    case ActionTypes.LOAD_RESPONSE_ERROR: {
      return {
        ...state,
        data: '',
        error: action.payload
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        data: '',
        error: ''
      };
    }
  }

  return state;
};
