import ActionTypes from '../actions/ActionTypes';
import axios from 'axios';
import * as Utils from '../utils/Utils';
import _ from 'lodash';
import { PEACOCK_IDS } from 'src/constants/common';
import * as uploadType from '../components/package/UploadType';
import FileStatus from '../components/package/FileStatus';
import moment from 'moment';
import * as constants from '../components/package/Constants';
import * as dashboardActions from '../actions/DashboardActions';
import APIS from 'src/constants/api';

// Components
import { toast } from '@d3sw/one-ui-components';

export const selectAccount = (account) => {
  //console.log("::> action selectAccount")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SELECT_ACCOUNT,
      payload: account
    });
  };
};

export const clearAccount = () => {
  return async (dispatch) => {
    const initialState = {
      account: {},
      product: {},
      releaseDateStatus: '',
      rating: ''
    };

    dispatch({
      type: ActionTypes.CLEAR_PACKAGE_CONTENT,
      payload: initialState
    });
  };
};

export const setOneAccountOnly = (flag) => {
  //console.log("::> action setOneAccountOnly")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SET_ONE_ACCOUNT_ONLY,
      payload: flag
    });
  };
};

export const setReleaseDate = (releaseDate) => {
  //console.log("::> action setReleaseDate")
  return async (dispatch) => {
    let formattedDate = '';
    if (releaseDate && releaseDate !== '') {
      formattedDate = moment(releaseDate).format('YYYY-MM-DD HH:mm:ss.SSS');
    }

    dispatch({
      type: ActionTypes.SELECT_RELEASE_DATE,
      payload: formattedDate
    });
  };
};

export const setRating = (rating) => {
  //console.log("::> action setRating")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SELECT_RATING,
      payload: parseInt(rating.value, 10)
    });
  };
};

export const transcodingActive = (flag) => {
  //console.log("::> action toggleSaveAbility")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TRANCODING_IN_PORGRESS,
      payload: flag
    });
  };
};

export const toggleSaveAbility = (flag) => {
  //console.log("::> action toggleSaveAbility")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_CAN_SAVE,
      payload: flag
    });
  };
};

export const doesSubmissionAlreadyExists = (file, filesToConsider) => {
  let fileMatch = _.find(filesToConsider, function (uploadedFile) {
    return uploadedFile.fileName === file.name || uploadedFile.displayName === file.name;
  });

  if (fileMatch === undefined) {
    return false;
  } else {
    return true;
  }
};

export const clearSubmissionFiles = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SUBMISSION_FILES_CLEAR,
      payload: ''
    });
  };
};

export const uploadSubmissionFiles = (files, type) => {
  //console.log("::> action uploadSubmissionFiles")
  var STAGE_SUCCESS =
    type === uploadType.SUBMISSION_FILE ? ActionTypes.SUBMISSION_FILE_STAGE_SUCCESS : ActionTypes.ATTACHMENT_FILE_STAGE_SUCCESS;
  var STAGE_FAILURE =
    type === uploadType.SUBMISSION_FILE ? ActionTypes.SUBMISSION_FILE_STAGE_FAILURE : ActionTypes.ATTACHMENT_FILE_STAGE_FAILURE;

  return async (dispatch, getState) => {
    // let defaultLength = getState().authUser.submitterUserInfo.submitterUser.settings['ui.create.media.length.default'];
    let defaultLength = '';
    let errorFileNames = [];

    files.forEach((file) => {
      var filesToConsider =
        type === uploadType.SUBMISSION_FILE ? getState().uploadSubmissionFile.data : getState().uploadAttachmentFile.data;

      if (
        file &&
        filesToConsider[file.name] === undefined &&
        ((type === uploadType.SUBMISSION_FILE && file && !doesSubmissionAlreadyExists(file, filesToConsider)) ||
          type !== uploadType.SUBMISSION_FILE)
      ) {
        var currentFile = {
          fileName: file.name,
          displayName: file.name,
          fileType: Utils.getFileType(file.name),
          cutType: '',
          cutTypeID: 0,
          adCode: '',
          length: defaultLength,
          region: '',
          mediaTypeString: '',
          mediaTypeID: 0,
          comment: '',
          relatedCodes: [],
          relatedCodesCount: 0,
          destinations: [{ value: '-1', label: 'All' }],
          actions: '',
          file: file,
          //----Saved media fields
          mediaID: 0,
          forwardedMediaID: 0,
          forwardedID: 0,
          status: 0,
          draftStatus: 2,
          orderInGroup: 0,
          created: '',
          downloadableByReviewers: false,
          version: 0,
          versionName: '',
          fileSize: Utils.bytesToKBytes(file.size),
          //----------------------
          attachmentType: 'Unspecified', //todo replace with constant
          uploadStatus: FileStatus.PENDING,
          error: '',
          serverMessage: '',
          uploadProgress: 0,
          timeStamp: moment()
        };

        dispatch({
          type: STAGE_SUCCESS,
          payload: currentFile
        });
      } else {
        errorFileNames.push(file?.name);
      }
    });

    var filesToConsider = type === uploadType.SUBMISSION_FILE ? getState().uploadSubmissionFile.data : getState().uploadAttachmentFile.data;

    errorFileNames.length > 0 &&
      dispatch({
        type: STAGE_FAILURE,
        payload: `Duplicate file name has been ignored - ${errorFileNames.join(', ')}`
      });

    var fileQ = [];
    for (var key in filesToConsider) {
      fileQ.push(filesToConsider[key]);
    }

    Utils.submitFilesSequentially(fileQ, type, dispatch, getState);
  };
};

export const updateSubmissionMetadata = (file, type) => {
  if (file) {
    if (type === uploadType.SUBMISSION_FILE) {
      return async (dispatch) => {
        dispatch({
          type: ActionTypes.SUBMISSION_FILE_METADATA_UPDATE,
          payload: file
        });
      };
    } else {
      return async (dispatch) => {
        dispatch({
          type: ActionTypes.ATTACHMENT_FILE_METADATA_UPDATE,
          payload: file
        });
      };
    }
  }
};

export const deleteFile = (file, type) => {
  //console.log("::> action deleteFile")
  return async (dispatch, getState) => {
    var existingFiles = type === uploadType.SUBMISSION_FILE ? getState().uploadSubmissionFile.data : getState().uploadAttachmentFile.data;

    var remainingFiles = {};

    for (var key in existingFiles) {
      var f = existingFiles[key];
      if (f.fileName !== file.fileName) {
        remainingFiles[key] = f;
      }
    }

    if (type === uploadType.SUBMISSION_FILE) {
      dispatch({
        type: ActionTypes.SUBMISSION_FILE_DELETE_SUCCESS,
        payload: remainingFiles
      });
    } else {
      dispatch({
        type: ActionTypes.ATTACHMENT_FILE_DELETE_SUCCESS,
        payload: remainingFiles
      });
    }
  };
};

export const viewFile = (file, type) => {
  //console.log("::> action viewFile")
  return async () => {
    console.log('VIEW FILE');
    console.log(file);

    if (type === uploadType.SUBMISSION_FILE) {
    } else {
    }
  };
};

export const updateSendToForAttachment = (file, sendTo) => {
  //console.log("::> action updateSendToForAttachment")
  return async (dispatch) => {
    file.destinations = sendTo;
    dispatch({
      type: ActionTypes.ATTACHMENT_FILE_METADATA_UPDATE,
      payload: file
    });
  };
};

export const updateLengthForSubmission = (file, length) => {
  //console.log("::> action updateLengthForSubmission")
  return async (dispatch) => {
    file.length = length;
    dispatch({
      type: ActionTypes.SUBMISSION_FILE_METADATA_UPDATE,
      payload: file
    });
  };
};

//for now index is enough. may need to add some reference to view to get
//a better handle on which object's state to show in the popup
export const openPopup = (index) => {
  //console.log("::> action openPopup")
  var ops = {
    visible: true,
    index: index
  };

  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_POPUP,
      payload: ops
    });
  };
};

export const openCancelPackageDialog = () => {
  //console.log("::> action openCancelPackageDialog")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.OPEN_CANCEL_DIALOG,
      payload: true
    });
  };
};

export const closeCancelPackageDialog = () => {
  //console.log("::> action closeCancelPackageDialog")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CLOSE_CANCEL_DIALOG,
      payload: false
    });
  };
};

export const updateRelatedCodesCurrentValue = (index, value) => {
  //console.log("::> action updateRelatedCodesCurrentValue")
  var ops = {
    index: index,
    currentValue: value
  };

  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_RELATED_CODES_VALUE,
      payload: ops
    });
  };
};

export const closeRelatedCodesPopup = () => {
  //console.log("::> action closeRelatedCodesPopup")
  var ops = {
    visible: false,
    index: 0,
    currentValue: '',
    currentDescription: ''
  };

  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_RELATED_CODES,
      payload: ops
    });
  };
};

export const updateRelatedCodesCurrentDescription = (index, description) => {
  //console.log("::> action updateRelatedCodesCurrentDescription")
  var ops = {
    index: index,
    currentDescription: description
  };

  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_RELATED_CODES_DESCRIPTION,
      payload: ops
    });
  };
};

export const closePopup = () => {
  //console.log("::> action closePopup")
  var ops = {
    visible: false,
    index: 0
  };
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_POPUP,
      payload: ops
    });
  };
};

export const openRelatedCodesPopup = (index) => {
  //console.log("::> action openRelatedCodesPopup")
  var ops = {
    visible: true,
    index: index,
    currentValue: '',
    currentDescription: ''
  };

  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_RELATED_CODES,
      payload: ops
    });
  };
};

export const clearRecipients = () => {
  //console.log("::> action clearRecipients")
  //return undefined
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ALLOWED_RECIPIENTS,
      payload: []
    });
  };
};

export const updateDefaultReviewerUser = (user, departmentId) => {
  return async (dispatch, getState) => {
    let allowedRecipients = getState().recipients.allowedRecipients;
    allowedRecipients.forEach((department) => {
      if (departmentId === department.departmentID) {
        department.defaultReviewerUser = user;
      }
    });

    dispatch({
      type: ActionTypes.ALLOWED_RECIPIENTS,
      payload: allowedRecipients
    });
  };
};

export const updateSaveAsDefault = (departmentID) => {
  //console.log("::> action updateSaveAsDefault")
  return async (dispatch, getState) => {
    let _allowedRecipients = getState().recipients.allowedRecipients;

    _.forEach(_allowedRecipients, function (department) {
      if (departmentID === department.departmentID) {
        department.saveAsDefault = !department.saveAsDefault;
      }
    });

    dispatch({
      type: ActionTypes.ALLOWED_RECIPIENTS,
      payload: _allowedRecipients
    });
  };
};

export const updateRestrictToMember = (departmentID) => {
  //console.log("::> action updateRestrictToMember")
  return async (dispatch, getState) => {
    let _allowedRecipients = getState().recipients.allowedRecipients;

    _.forEach(_allowedRecipients, function (department) {
      if (departmentID === department.departmentID) {
        department.restrictToMember = !department.restrictToMember;
      }
    });

    dispatch({
      type: ActionTypes.ALLOWED_RECIPIENTS,
      payload: _allowedRecipients
    });
  };
};

export const resetRecipients = () => {
  //console.log("::> action resetRecipients")
  return async (dispatch, getState) => {
    let _allowedRecipients = getState().recipients.allowedRecipients;
    let { reviewerAssignments } = getState().reviewerAssignments.data;

    _.forEach(_allowedRecipients, function (department) {
      let reviewerassignment = reviewerAssignments.filter((p) => p.departmentId === department.departmentID);

      if (reviewerassignment.length > 0) {
        let _defaultReviewerUser = department.broadcasterUsers.filter((p) => p.id === reviewerassignment[0].reviewerId);
        let _defaultUser = _defaultReviewerUser[0];
        department.defaultReviewerUser = _defaultUser;
      } else {
        department.defaultReviewerUser = {};
      }

      department.saveAsDefault = false;
      department.restrictToMember = false;
    });

    dispatch({
      type: ActionTypes.ALLOWED_RECIPIENTS,
      payload: _allowedRecipients
    });
  };
};

export const updateAllowedDestinations = () => {
  // console.log("::> action updateAllowedDestinations");
  return async (dispatch, getState ) => {
    const { productCategories } = getState().reviewerAssignments.data;
    dispatch({
      type: ActionTypes.ALLOWED_DESTINATIONS,
      payload: productCategories
    });
  }
}

export const updateRecipients = (recipients, withDefaultSelections) => {
  //console.log("::> action updateRecipients")
  return async (dispatch, getState) => {
    const broadcasters = getState().staticData.data.submitterUserStaticInfo.submitterUserStaticData.broadcasters;
    const { productCategories, reviewerAssignments } = getState().reviewerAssignments.data;

    let broadcasterIDSet = new Set();
    recipients.forEach(({ broadcasterID }) => {
      broadcasterIDSet.add(broadcasterID);
    });

    let allowedRecipients = [];

    broadcasters.forEach((broadcaster) => {
      if (broadcasterIDSet.has(broadcaster.broadcasterID)) {
        broadcaster.departments.forEach((department) => {
          const reviewerassignment = reviewerAssignments.find(({ departmentId }) => departmentId === department.departmentID);
          let broadcasterUsersFromDepartment = [];
          broadcaster.broadcasterUsers.forEach((broadcasterUser) => {
            if (broadcasterUser.departments.departmentId) {
              const dept = broadcasterUser.departments.departmentId.find((id) => id === department.departmentID);
              if (dept) {
                broadcasterUser.value = broadcasterUser.id;
                broadcasterUser.label = `${broadcasterUser.firstName} ${broadcasterUser.lastName}`;
                broadcasterUser.currentDepartmentID = department.departmentID;
                broadcasterUsersFromDepartment.push(broadcasterUser);
              }
            }
          });

          department.broadcasterUsers = broadcasterUsersFromDepartment;
          department.broadcasterName = broadcaster.broadcasterName;

          if (reviewerassignment) {
            const { submissionPackage } = getState().saveSubmissionReducer;
            const defaultReviewerUser = broadcaster.broadcasterUsers.find(({ id }) => id === reviewerassignment.reviewerId);
            let defaultUser = defaultReviewerUser;
            if (submissionPackage !== '') {
              //the package is in edit/forward state
              submissionPackage.mediaGroup.assignedUsers.forEach((user) => {
                if (user.departments.departmentId.includes(department.departmentID)) {
                  user.currentDepartmentID = department.departmentID;
                  defaultUser = user;
                }
              });

              if (reviewerassignment.serverDefaultReviewerId !== reviewerassignment.reviewerId) {
                if (reviewerassignment.reviewerId === defaultUser.value) {
                  defaultUser.label = `${defaultUser.firstName} ${defaultUser.lastName} *`;
                }
              }

              if (department.broadcasterUsers.length > 0) {
                department.broadcasterUsers.forEach((broadcasterUser) => {
                  if (broadcasterUser.value === reviewerassignment.serverDefaultReviewerId) {
                    broadcasterUser.label = `${broadcasterUser.label} (Default)`;
                  }
                  if (
                    reviewerassignment.serverDefaultReviewerId !== reviewerassignment.reviewerId &&
                    broadcasterUser.value === reviewerassignment.reviewerId
                  ) {
                    broadcasterUser.label = `${broadcasterUser.firstName} ${broadcasterUser.lastName} *`;
                  }
                });
              }
            } else {
              //new package
              if (defaultReviewerUser) {
                defaultUser.value = defaultUser.id;
                if (reviewerassignment.serverDefaultReviewerId !== reviewerassignment.reviewerId) {
                  if (reviewerassignment.reviewerId === defaultUser.value) {
                    defaultUser.label = `${defaultUser.firstName} ${defaultUser.lastName} *`;
                  }
                }

                if (department.broadcasterUsers.length > 0) {
                  department.broadcasterUsers.forEach((broadcasterUser) => {
                    if (broadcasterUser.value === reviewerassignment.serverDefaultReviewerId) {
                      broadcasterUser.label = `${broadcasterUser.label} (Default)`;
                    }
                  });
                }
                defaultUser.currentDepartmentID = department.departmentID;
              }
            }

            department.defaultReviewerUser = defaultUser;
          } else {
            department.defaultReviewerUser = {};
          }

          department.saveAsDefault = false;
          department.restrictToMember = false;

          if (department.defaultReviewerUser && Object.keys(department.defaultReviewerUser).length > 0) {
            allowedRecipients.push(department);
          }
        });
      }
    });

    allowedRecipients.forEach((r) => {
      r.selected = false;
      r.disabled = false;
      r.visible = true;
    });

    const selectedProductID = getState().packages.product.value;

    const productInfo = getState().packages.account.products.filter((product) => product.id === selectedProductID);

    if (productInfo && productInfo.length > 0 && withDefaultSelections) {
      const defaultDestination = productInfo[0].defaultDestinations.destinationID;
      allowedRecipients.forEach((r) => {
        r.selected = false;
        if (defaultDestination && defaultDestination.length > 0) {
          defaultDestination.forEach((destID) => {
            if (parseInt(r.departmentID, 10) === destID) {
              r.selected = true;
            }
          });
        }
      });
    }

    const peacockRecipient = allowedRecipients.find((r) => r.departmentID === PEACOCK_IDS.PEACOCK);
    const peacockHispRecipient = allowedRecipients.find((r) => r.departmentID === PEACOCK_IDS.PEACOCK_H);

    if (peacockRecipient && productInfo && productInfo.length > 0) {
      const categoryId = productInfo[0].productCategory.id;
      const isPeacockEligibleCategory = productCategories.find((category) => parseInt(category.id, 10) === categoryId);
      if (!isPeacockEligibleCategory || !isPeacockEligibleCategory?.allowedDestinations.includes(peacockRecipient.departmentID)) {
        peacockRecipient.visible = false;
      }
    }

    if (peacockRecipient?.selected) {
      peacockHispRecipient.disabled = true;
    } else if (peacockHispRecipient?.selected) {
      peacockRecipient.disabled = true;
    }

    // De-scoping, since we don';t want this in next release, but migh want it later
    // const sorted = allowedRecipients
    // .sort((a, b)=> 2 * (a.broadcasterName > b.broadcasterName) -1)
    // .reduce((accum, recipient, index) => {
    //   if (accum[recipient.broadcasterName]) {
    //     accum[recipient.broadcasterName].value.push(recipient)
    //   } else {
    //     accum[recipient.broadcasterName] = { value: [recipient] }
    //   }

    //   return accum;
    // }, {});

    // const allowedRecipientsSorted = Object.keys(sorted).map((key) => {
    //   return sorted[key].value.sort((a, b)=> 2 * (a.departmentName > b.departmentName) -1);
    // }).flat();

    dispatch({
      type: ActionTypes.ALLOWED_RECIPIENTS,
      payload: allowedRecipients.filter((r) => r.visible)
    });
  };
};

export const addGeneralComment = (comment) => {
  //console.log("::> action addGeneralComment")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_GENERAL_COMMENT,
      payload: comment
    });
  };
};

export const selectRecipient = (recipients) => {
  //console.log("::> action selectRecipient")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SELECT_RECIPIENT,
      payload: recipients
    });
  };
};

export const selectAllRecipients = (flag) => {
  //console.log("::> action selectAllRecipients")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SELECT_ALL_RECIPIENTS,
      payload: flag
    });
  };
};

export const updateSavedPackageVersion = (version) => {
  //console.log("::> action updateSavedPackageVersion")
  return async (dispatch, getState) => {
    let pkg = getState().saveSubmissionReducer.submissionPackage;
    pkg.mediaGroup.version = version;

    dispatch({
      type: ActionTypes.SAVE_SUBMISSION_MEDIA_STAGE,
      payload: pkg
    });
  };
};

export const isOutdated = (submissionPackage, mediaGroupVersionInfo) => {
  //console.log("::> action isOutdated")
  return async () => {
    if (!mediaGroupVersionInfo.mediaGroupVersion) {
      return false;
    }

    if (submissionPackage.mediaGroup.version !== mediaGroupVersionInfo.mediaGroupVersion) {
      return true;
    }

    var isOutdated = false;

    _.forEach(submissionPackage.mediaGroup.medias, function (m) {
      _.forEach(mediaGroupVersionInfo.mediaVersionInfos, function (vo) {
        if (m.mediaID === vo.mediaId) {
          if (m.version !== vo.mediaVersion || vo.draftStatus === 0) {
            isOutdated = true;
            return false;
          }

          /*    if(m.status != vo.status){
                        m.status = vo.status
                   } */
          if (vo.status === 12) {
            if (m.fileName != vo.fileName) {
              m.fileName = vo.fileName;
            }
          }
        }
      });
    });

    return isOutdated;
  };
};

export const isPacakgeInValidState = (submissionPackage, mediaGroupVersionInfo) => {
  //console.log("::> action isOutdated")
  return async () => {
    var isValidSend = true;
    _.forEach(submissionPackage.mediaGroup.medias, function (m) {
      /*    console.log("--> submission pacakge ->(media) " + m.mediaID + " :(status) " + m.status  + " :(fileName) " + m.fileName) */

      _.forEach(mediaGroupVersionInfo.mediaVersionInfos, function (vo) {
        /*      console.log("*******--> submission pacakge ->(media) " + vo.mediaId + " :(status) " + vo.status ) */
        if (m.mediaID === vo.mediaId) {
          /*      console.log("*******--> (match media ID)--> is Package Valid (media id ) ->(media) " + m.mediaID + " :(vo) " + vo.mediaId + " :(status) ->(media)  " + m.status  + " :(vo) " + vo.status + " :(file name) ->(media)  "+ m.fileName  + " :(vo) " + vo.fileName) */
          if (m.status !== 12) {
            isValidSend = false;
            /*      console.log("*******--> (ERROR) not a valid status for (media id ) ->(media) " + m.mediaID + " :(vo) " + vo.mediaId + " : " + m.status ) */
          }
        }
      });
    });

    return isValidSend;
  };
};

export const updateSubmissionPackage = (submissionPackage, mediaGroupVersionInfo) => {
  let updatedSubmissionPacakge = submissionPackage;

  _.forEach(updatedSubmissionPacakge.mediaGroup.medias, function (m) {
    /*  console.log("--> supdateSubmissionPackage ->(media) " + m.mediaID + " :(status) " + m.status  + " :(fileName) " + m.fileName)  */

    _.forEach(mediaGroupVersionInfo.mediaVersionInfos, function (vo) {
      /*    console.log("*******-->updateSubmissionPackage ->(media) " + vo.mediaId + " :(status) " + vo.status ) */
      if (m.mediaID === vo.mediaId) {
        /*    console.log("*******(updateSubmissionPackage)--> (match media ID)--> is Package Valid (media id ) ->(media) " + m.mediaID + " :(vo) " + vo.mediaId + " :(status) ->(media)  " + m.status  + " :(vo) " + vo.status + " :(file name) ->(media)  "+ m.fileName  + " :(vo) " + vo.fileName) */
        if (m.status === 12) {
          if (m.fileName !== vo.fileName) {
            m.fileName = vo.fileName;
            /*      console.log("*******(updateSubmissionPackage)--> (ERROR) not a valid status for (media id ) ->(media) " + m.mediaID + " :(vo) " + vo.mediaId + " : " + m.status ) */
          }
        } else {
          if (m.version !== vo.mediaVersion) {
            //   m.version !== vo.mediaVersion
            /*      console.log("*******(updateSubmissionPackage - version)--> (ERROR) not a valid status for (media id ) ->(media) " + m.version + " :(vo) " + vo.mediaVersion ) */
          }
          if (m.status !== vo.status) {
            //   m.status !== vo.status
            /*      console.log("*******(updateSubmissionPackage - status)--> (ERROR) not a valid status for (media id ) ->(media) " + m.status + " :(vo) " + vo.status ) */
          }
        }
      }
    });
  });

  return updatedSubmissionPacakge;
};

export const saveOrSendSubmissionPackage = (submissionPackage, sendOrSave) => {
  //console.log("::> action saveOrSendSubmissionPackage")
  return async (dispatch, getState) => {
    const urlForMedia = APIS.SUBMISSION.SAVE_SUBMISSION;
    //Initial call to check version should not update   store
    //Only a successful save/send should update mediaVersion on store
    let update = await dispatch({
      type: ActionTypes.SAVE_SUBMISSION_MEDIA_STAGE,
      payload: submissionPackage
    });
    let response = await dispatch(getMediaVersions(submissionPackage.mediaGroup.id, false));

    let _isPacakgeInValidState = await dispatch(isPacakgeInValidState(submissionPackage, response.data.mediaGroupVersionInfoVO));

    if (!_isPacakgeInValidState) {
      dispatch({
        type: ActionTypes.TOGGLE_LOADING_PACKAGE,
        payload: false
      });
      transcodingActive(false);

      toast.error('Files are still being transcoded. Cannot send until complete.');
      return;
    } else {
      submissionPackage = updateSubmissionPackage(submissionPackage, response.data.mediaGroupVersionInfoVO);
    }

    let _isOutdated = await dispatch(isOutdated(submissionPackage, response.data.mediaGroupVersionInfoVO));

    if (!_isOutdated) {
      try {
        response = await axios.post(urlForMedia, submissionPackage);
      } catch (error) {
        response.error = error;
        toast.error('Error while submitting package, please refresh to update content');
      }

      if (response.error == null) {
        let groupID = response.data.mediasUploaded.mediaGroup.id;
        let status = response.status;

        let result = {};
        result.status = status;
        result.groupID = groupID;

        let saveSuccess = await dispatch({
          type: ActionTypes.SAVE_SUBMISSION_MEDIA_SUCCESS,
          payload: result
        });

        let statusURL = APIS.SUBMISSION.UPDATE_STATUS;
        let payload = {};

        payload.authToken = getState().authUser.authToken;
        payload.userName = getState().authUser.userName;
        payload.userId = getState().authUser.submitterUserInfo.submitterUser.id;
        payload.groupId = groupID;
        payload.sessionId = getState().authUser.submitterUserInfo.sessionID;

        //todo keep track of success/failure of every media
        //on updateStatus, a statusID is returned which might
        //need to be reflected in UI

        let statusResponse = await axios.post(statusURL, payload);

        if (statusResponse.error == null) {
          let resla = await Promise.all([
            dispatch(dashboardActions.fetchInbox(parseInt(getState().userSettings.data.renderDashboard, 10))),
            // dispatch(dashboardActions.fetchStaticData()) // commented due to the static data is already exists in redux store
          ]);

          let load = await dispatch({
            type: ActionTypes.TOGGLE_LOADING_PACKAGE,
            payload: false
          });

          if (sendOrSave === constants.SEND) {
            Utils.cleanupOnSend(dispatch, getState);

            toast.success(getState().localizedLabels.notifications.packageSendSuccess);
          } else {
            Utils.cleanupOnSave();

            Utils.editPackageStoreUpdate(dispatch, getState, response);

            toast.success(getState().localizedLabels.notifications.packageSaveSuccess);

            if (getState().mediaVersion.transcoding === true) {
              toast.info('Changes to your Submissions are temporarily prevented while your files are being transcoded');
            }
          }

          //Only let a "successful save/send" user should get the updated mediaVersion
          load = await dispatch(getMediaVersions(groupID, true));
          return Promise.resolve(groupID);
        } else {
          let resla = await Promise.all([
            dispatch({
              type: ActionTypes.TOGGLE_LOADING_PACKAGE,
              payload: false
            }),
            dispatch({
              type: ActionTypes.SAVE_SUBMISSION_MEDIA_ERROR,
              payload: 500
            })
          ]);
          return Promise.resolve();
        }
      } else {
        let resl = await Promise.all([
          dispatch({
            type: ActionTypes.TOGGLE_LOADING_PACKAGE,
            payload: false
          }),
          dispatch({
            type: ActionTypes.SAVE_SUBMISSION_MEDIA_ERROR,
            payload: response
          })
        ]);
        return Promise.resolve();
      }
    } else {
      let isAlreadySent = await dispatch(isPackageAlreadySent());
      dispatch({
        type: ActionTypes.TOGGLE_LOADING_PACKAGE,
        payload: false
      });
      if (isAlreadySent) {
        toast.error(getState().localizedLabels.notifications.concurrentSendEditError);
      } else {
        toast.error(getState().localizedLabels.notifications.concurrentSaveEditError);
      }
      return Promise.resolve();
    }
  };
};

export const isPackageAlreadySent = () => {
  //console.log("::> action isPackageAlreadySent")
  return async (dispatch, getState) => {
    var isSent = false;

    if (!getState().mediaVersion.data.mediaGroupVersionInfoVO) {
      return false;
    }

    _.forEach(getState().mediaVersion.data.mediaGroupVersionInfoVO.mediaVersionInfos, function (m) {
      if (m.draftStatus === 0) {
        isSent = true;
        return false;
      }
    });
    return isSent;
  };
};

export const cancelCreatePackage = () => {
  //console.log("::> action cancelCreatePackage")
  return async (dispatch, getState) => {
    //clear everything from store
    Utils.cleanupOnCancel(dispatch, getState);
  };
};

export const updatePurchaseOrderStatus = (poStatus) => {
  //return undefined
  //console.log("::> action updatePurchaseOrderStatus")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_PO_STATUS,
      payload: poStatus
    });
  };
};

export const updatePurchaseOrderNumber = (poNumber) => {
  //return undefined
  //console.log("::> action updatePurchaseOrderNumber")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_PO_NUMBER,
      payload: poNumber
    });
  };
};

export const selectProduct = (product) => {
  //console.log("::> action selectProduct")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SELECT_PRODUCT,
      payload: product
    });
  };
};

export const updateMissingInfoMessageForSave = () => {
  //console.log("::> action updateMissingInfoMessageForSave")
  return async () => {
    // dispatch({
    //     type: ActionTypes.SAVE_SUBMISSION_ERROR,
    //     payload: message
    // });
  };
};

export const toggleCanSavePackage = () => {
  //console.log("::> action toggleCanSavePackage")
  return async () => {};
};

export const reviewerAssignments = (broadcasters) => {
  //console.log("::> action reviewerAssignments")
  return async (dispatch, getState) => {
    const url = APIS.SUBMISSION.REVIEWER_ASSIGNMENT;

    let _broadcasters = getState().staticData.data.submitterUserStaticInfo.submitterUserStaticData.broadcasters;

    var productID = getState().packages.product.value;

    let broadcasterIDSet = new Set();
    _.forEach(broadcasters, function (r) {
      broadcasterIDSet.add(r.broadcasterID);
    });

    let departmentIds = [];
    _.forEach(_broadcasters, function (b) {
      if (broadcasterIDSet.has(b.broadcasterID)) {
        _.forEach(b.departments, function (department) {
          departmentIds.push(department.departmentID);
        });
      }
    });

    const payload = {
      userName: getState().authUser.userName,
      authToken: getState().authUser.authToken,
      request: {
        productId: productID,
        departments: {
          departmentId: departmentIds
        }
      }
    };

    return axios
      .post(url, payload)
      .then(function (response) {
        const { reviewerAssignments, productCategories } = response.data;

        dispatch({
          type: ActionTypes.REVIEWER_ASSIGNMENT_SUCCESS,
          payload: {
            reviewerAssignments,
            productCategories
          }
        });
      })
      .catch(function (error) {
        dispatch({
          type: ActionTypes.REVIEWER_ASSIGNMENT_FAILURE,
          payload: {}
        });
      });
  };
};

export const onSaveReviewerAssignments = () => {
  //console.log("::> action onSaveReviewerAssignments")
  return async (dispatch, getState) => {
    const url = APIS.SUBMISSION.UPDATE_REVIEWER_ASSIGNMENT;

    const { reviewerAssignments } = getState().reviewerAssignments.data;

    let departments = getState().recipients.allowedRecipients;

    let _reviewerassignments = [];
    _.forEach(departments, function (department) {
      if (department.saveAsDefault) {
        let ra = reviewerAssignments.filter((p) => p.departmentId === department.departmentID);
        ra[0].userGenerated = true;
        ra[0].reviewerId = department.defaultReviewerUser.id;
        _reviewerassignments.push(ra[0]);
      }
    });

    if (_reviewerassignments.length > 0) {
      const payload = {
        userName: getState().authUser.userName,
        authToken: getState().authUser.authToken,
        updateReviewerAssignmentRequest: {
          reviewerAssignments: _reviewerassignments
        }
      };

      return axios
        .put(url, payload)
        .then(function (response) {
          let _allowedRecipients = getState().recipients.allowedRecipients;

          _.forEach(_allowedRecipients, function (department) {
            let { reviewerAssignments } = getState().reviewerAssignments.data;

            let reviewerassignment = reviewerAssignments.filter((p) => p.departmentId === department.departmentID);
            let user = department.defaultReviewerUser;

            _.forEach(department.broadcasterUsers, function (broadcasterUser) {
              if (reviewerassignment[0].serverDefaultReviewerId !== broadcasterUser.value && broadcasterUser.value !== user.value) {
                broadcasterUser.label = broadcasterUser.firstName.concat(' ').concat(broadcasterUser.lastName);
              }

              if (
                reviewerassignment[0].serverDefaultReviewerId !== reviewerassignment[0].reviewerId &&
                reviewerassignment[0].reviewerId === broadcasterUser.value
              ) {
                broadcasterUser.label = broadcasterUser.firstName.concat(' ').concat(broadcasterUser.lastName).concat(' *');
              }
            });

            if (reviewerassignment[0].serverDefaultReviewerId !== reviewerassignment[0].reviewerId) {
              user.label = user.firstName.concat(' ').concat(user.lastName).concat(' *');
            }
          });

          dispatch({
            type: ActionTypes.ALLOWED_RECIPIENTS,
            payload: _allowedRecipients
          });
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            dispatch({
              type: ActionTypes.SIGN_IN_FAILURE,
              payload: {
                authorized: false,
                authToken: '',
                userName: '',
                hasError: false,
                errorMessage: ''
              }
            });
          }
        });
    }
  };
};

export const getMediaVersions = (mediaGroupID, shouldUpdateStore) => {
  //console.log("::> action getMediaVersions")
  return async (dispatch, getState) => {
    const url = APIS.SUBMISSION.GET_MEDIA_VERSION;

    const payload = {
      userName: getState().authUser.userName,
      authToken: getState().authUser.authToken,
      groupId: mediaGroupID
    };

    return axios
      .post(url, payload)
      .then(function (response) {
        if (shouldUpdateStore) {
          dispatch({
            type: ActionTypes.MEDIA_GROUP_VERSION_SUCCESS,
            payload: response.data
          });
        }

        return response;
      })
      .catch(function (error) {
        dispatch({
          type: ActionTypes.MEDIA_GROUP_VERSION_ERROR,
          payload: error.data
        });
      });
  };
};

export const clearMediaVersionInfo = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.MEDIA_GROUP_VERSION_DELETE,
      payload: ''
    });
  };
};

export const clearPackageStore = () => {
  //console.log("::> action clearPackageStore")
  return async (dispatch, getState) => {
    Utils.cleanupOnCancel(dispatch, getState);
  };
};

export const onUpdateRelatedCodeWarning = (payload) => {
  //console.log("::> action onUpdateRelatedCodeWarning")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.RELATED_CODE_WARNING,
      payload: payload
    });
  };
};

export const onClearRelatedCodeWarning = () => {
  //console.log("::> action onUpdateRelatedCodeWarning")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_RELATED_CODE_WARNING,
      payload: ''
    });
  };
};
export const toggleLoadingPackage = (value) => {
  //console.log("::> action toggleLoadingPackage")
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_LOADING_PACKAGE,
      payload: value
    });
  };
};

export const createNewProduct = (checkProductDuplicatePayload, submitterId, agencyId, selCategoryId) => {
  return async (dispatch, getState) => {
    const url = APIS.PRODUCT.CREATE;

    const payload = {
      userName: getState().authUser.userName,
      authToken: getState().authUser.authToken,
      action: '3',
      categoryId: selCategoryId,
      sessionId: getState().authUser.submitterUserInfo.sessionID,
      submitterId: submitterId,
      agencyId: agencyId,
      agencies: getState().staticData.data.submitterUserStaticInfo.submitterUserStaticData.agencies,
      productUpdate: checkProductDuplicatePayload
    };

    payload.productUpdate.product.status = 1;

    return axios
      .post(url, payload)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: ActionTypes.SIGN_IN_FAILURE,
            payload: {
              authorized: false,
              authToken: '',
              userName: '',
              hasError: false,
              errorMessage: ''
            }
          });
        }
        return error;
      });
  };
};

export const unselectAllRecipients = () => (dispatch) => {
  dispatch({ type: ActionTypes.UNSELECT_ALL_RECIPIENTS });
};

export const forwardMultiple = (selectedMedias, withAttachments) => {
  return async (dispatch, getState) => {
    const { mediaGroups } = getState().inbox.data.submitterUserInboxData;
    selectedMedias.forEach((selectedMedia) => {
      const mediaGroup = mediaGroups.find(({ id }) => selectedMedia === id);
      Utils.loadPackageForEditingOrForward(dispatch, getState, mediaGroup, constants.PACKAGE_FORWARD, withAttachments);
    });
  };
};
