import ErrorTypes from '../actions/ErrorTypes';
import axios from 'axios';
import APIS from 'src/constants/api';

export const logError = (error, type, requestPayload, headers) => {
  return async (dispatch, getState) => {
    let errorLog = {
      userName: getState().authUser.userName,
      authToken: getState().authUser.authToken,

      logClientErrorRequestId: error.id,
      errorCode: error.id,
      httpStatusCode: error.response ? error.response.status : '',
      errorMessage: error.message,
      errorType: type,
      endPointURL: error.request ? error.request.responseURL : '',
      requestPayload: JSON.stringify(requestPayload),
      responsePayload: JSON.stringify(error),
      requestHeaders: headers,
      responseHeaders: error.response ? error.response.headers : null
    };

    let url = APIS.LOG_ERROR;

    axios.post(url, errorLog);

    // Remove this for now since it was creating an infinite loop in some components that listened to errors, or their childs.

    // dispatch({
    // 	type: ErrorTypes.APP_ERROR,
    // 	payload: errorLog
    // });
  };
};
