import ActionTypes from '../actions/ActionTypes';

export const UserProfileReducer = (state = { error: '', data: '' }, action) => {
  switch (action.type) {
    case ActionTypes.USER_PROFILE_SUCCESS: {
      return {
        ...state,
        error: null,
        data: action.payload
      };
    }

    case ActionTypes.USER_PROFILE_FAILURE: {
      return {
        ...state,
        error: 'Error saving user profile',
        data: action.payload
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        data: {},
        error: ''
      };
    }
  }

  return state;
};
