import { datadogRum } from '@datadog/browser-rum';

const dataDogDatacenter = 'us';
const dataDogSite = 'datadoghq.com';

export default {
  rumSetup: () => {
    if (process.env.REACT_APP_RUM_ID) {
      datadogRum.init({
        applicationId: process.env.REACT_APP_RUM_ID,
        clientToken: process.env.REACT_APP_RUM_TOKEN,
        site: dataDogSite,
        service: process.env.REACT_APP_RUM_SERVICE,
        env: process.env.REACT_APP_RUM_ENV,
        sampleRate: 100,
        trackInteractions: true,
        datacenter: dataDogDatacenter
      });
    }
  }
};
