import ActionTypes from '../actions/ActionTypes';

export const UserSettingsReducer = (state = { error: '', data: '', timezones: '', languages: '', theme: '' }, action) => {
  switch (action.type) {
    case ActionTypes.USER_SETTING_SUCCESS: {
      return {
        ...state,
        error: null,
        data: action.payload
      };
    }

    case ActionTypes.USER_SETTING_FAILURE: {
      return {
        ...state,
        error: 'Error loading user settings',
        data: action.payload
      };
    }

    case ActionTypes.LOAD_TIMEZONES: {
      return {
        ...state,
        error: '',
        timezones: action.payload.select
      };
    }

    case ActionTypes.LOAD_LANGUAGE_OPTIONS: {
      return {
        ...state,
        error: '',
        languages: action.payload.select
      };
    }

    case ActionTypes.TOGGLE_APP_THEME: {
      return {
        ...state,
        theme: action.payload
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        error: '',
        data: '',
        timezones: '',
        languages: '',
        theme: ''
      };
    }
  }

  return state;
};

export const AppVersionReducer = (
  state = {
    data: {},
    appVersion: ''
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.APP_VERSION: {
      return {
        ...state,
        data: action.payload,
        appVersion: action.payload.appVersion
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        data: {},
        appVersion: ''
      };
    }
  }

  return state;
};
