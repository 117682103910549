import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Routes
import * as ROUTES from 'src/constants/Routes';

// Components
import { Card, CardHeader, CardBody, CardFooter } from '@d3sw/one-ui-components';
import MessagePreview from './MessagePreview';

// Styles
import './Messages.scss';
import './MessagesDropdown.scss';

class MessagesDropdown extends Component {
  state = {
    unreadMessageCount: 0,
    messages: []
  };

  componentDidMount = () => {
    this.setState({
      unreadMessageCount: this.props.unreadMessagesCount,
      messages: this.props.messages.messagesList.filter(({ unread }) => unread).slice(0, 5)
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.unreadMessagesCount !== this.props.unreadMessagesCount) {
      this.setState({
        unreadMessageCount: this.props.unreadMessagesCount,
        messages: this.props.messages.messagesList.filter(({ unread }) => unread).slice(0, 5)
      });
    }
  }

  render() {
    return (
      <Card className="message-dropdown message-dropdown--no-padding">
        <CardHeader>
          {this.state.messages.length > 0 && <React.Fragment>New Messages</React.Fragment>}
          {!this.state.messages.length > 0 && <React.Fragment>No New Messages</React.Fragment>}
        </CardHeader>
        {this.state.messages.length > 0 && (
          <CardBody>
            <ul className="message-dropdown__list">
              {this.state.messages.map((message, index) => (
                <MessagePreview key={index} message={message} />
              ))}
            </ul>
          </CardBody>
        )}
        <CardFooter className="message-dropdown__item">
          <Link to={ROUTES.MESSAGES}>Go to Messages</Link>
        </CardFooter>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  messages: state.messages
});

export default connect(mapStateToProps)(MessagesDropdown);
