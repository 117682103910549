// Framework
import React from 'react';
import { connect } from 'react-redux';

// Components
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, LoadingSpinner } from '@d3sw/one-ui-components';

// CSS
import './CustomModal.scss';

const theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';

const CustomModal = ({
  cancelFunction,
  children,
  className,
  confirmFunction,
  disabled,
  footerId,
  leftButtonLabel,
  rigthButtonLabel,
  size,
  show,
  title,
  toggle,
  withButtons
}) => (
  <Modal show={show} toggle={toggle} className={`${className} ${theme}-theme`} width={size === 'large' ? '1000px' : '500px'}>
    {title && <ModalHeader>{title}</ModalHeader>}
    <ModalBody>{children}</ModalBody>
    {withButtons && (
      <ModalFooter id={footerId}>
        <Button id="customModal-buttons_leftButton" className="ml-auto" onClick={cancelFunction ? cancelFunction : toggle} type="secondary">
          {leftButtonLabel}
        </Button>
        <Button id="customModal-buttons_rightButton" disabled={disabled} onClick={confirmFunction} type="primary">
          {rigthButtonLabel}
        </Button>
      </ModalFooter>
    )}
  </Modal>
);

CustomModal.defaultProps = {
  cancelFunction: null,
  className: '',
  disabled: false,
  footerId: '',
  leftButtonLabel: 'Cancel',
  rigthButtonLabel: 'Confirm',
  title: '',
  size: 'small',
  show: false,
  withButtons: false,
  confirmFunction: () => {},
  toggle: () => {}
};

const mapStateToProps = (state) => ({
  theme: state.userSettings.theme
});

export default connect(mapStateToProps)(CustomModal);
