//this is hard coded in legacy client

export const DELIVERED = 0; // received but not active yet
export const ACTIVE = 1;
export const APPROVED = 2;
export const REJECTED = 3;
export const DISMISSED = 4;
export const VIEWED = 5;
export const SUBMITTED = 6; // submitted by the broadcaster
export const TRANSMITTED = 7; // sent over to the colo server
export const CONFIRMED_TRANSMISSION = 8; // saved at the colo and confirmed
export const TMP_DOWNLOADED = 9; // file downloaded to hard drive during sync
export const DELETED = 10;
export const DELETED_TRANSMITTED = 11;
export const DEACTIVATED = 12;
export const RECALLED = 13;
export const METADATA_SYNCED_TO_SUBMITTER = 14;
export const FILE_UPLOADED = 15;
export const REACTIVATED_BY_FOLLOWUP = 16;
export const FILE_DELETED = 20;
export const FILE_AVAILABLE = 21;
export const FILE_ARCHIVED = 22;

export const NBC_TRANSFER_SERVER_ID = 2;
export const TELEMUNDO_TRANSFER_SERVER_ID = 3;

export const portalIngestedFilesDate = new Date(2023, 5, 2); // June 2nd

// MVU-5612 a multideparmtent reviewer should be able to review packages
export const VALID_MEDIA_RECIPIENT_STATUSES = {
    '1': 'Active',
    '16': 'Reactivated Msg	Message change'
}

export const portalIngestedFiles = [
  {
    serverTransferId: TELEMUNDO_TRANSFER_SERVER_ID,
    dateRanges: [{ start: new Date(2019, 9, 16), end: new Date(2021, 5, 2) }]
  },
  {
    serverTransferId: NBC_TRANSFER_SERVER_ID
  }
];

export const SUBTRACT_TIME_BEFORE_TOKEN_EXPIRY = 300;
export const STATIC_IMAGES_BASE_URL = 'https://static-mediavu.bydeluxe.com/images/';

export const loadMediaByMediaGroupErrorMessage = ['Error while downloading asset', 'Missing media information'];

export const broadcastersWithClaims = ['6003', '3', '6013', '6066'];

export const broadcastersWithInternals = ['4', '6014', '6003', '3', '6066'];

export const CUT_TYPES = [
  {
    id: '0',
    text: 'Rough Cut'
  },
  {
    id: '1',
    text: 'Final Cut'
  },
  {
    id: '2',
    text: 'Concept'
  },
  {
    id: '3',
    text: 'N/A'
  }
];

export const BASE_64_IMAGE_PREFIX = 'data:image/jpeg;base64,';
export const BASE_64_PNG_IMAGE_PREFIX = 'data:image/png;base64,';

export const FRAME_WIDTH = 87;
export const FRAME_HEIGHT = 58;

export const MEDIA_FORMATS = [
  'm4v',
  'mov',
  'mp4',
  'avi',
  'flv',
  'mpeg',
  'mpg',
  'wmv',
  'mp3',
  'aac',
  'flac',
  'aiff',
  'm4a',
  'ogg',
  'wav',
  'wma'
];

// This is no good, but it was REQUIRED.... so.
export const missingNBCportalMediaIds = [
  '404556',
  '404555',
  '167713',
  '167712',
  '167711',
  '167710',
  '167709',
  '167708',
  '124963',
  '116355',
  '113203',
  '113202',
  '113201',
  '113200',
  '113199',
  '113198',
  '113197',
  '113196',
  '113195',
  '113194',
  '112863',
  '112862',
  '112861',
  '112860',
  '112859',
  '112858',
  '112857',
  '112856',
  '112855',
  '112854',
  '108352',
  '108351',
  '107751',
  '107749',
  '100247',
  '100246',
  '100245',
  '100244',
  '100243',
  '100242',
  '100241',
  '100240',
  '100239',
  '100238',
  '100237',
  '100236',
  '100235',
  '100234',
  '100233',
  '100232',
  '100231',
  '100230',
  '100229',
  '100228',
  '100227',
  '100226',
  '100225',
  '100224',
  '100223',
  '100222',
  '100221',
  '100220',
  '100219',
  '100218',
  '100217',
  '100216',
  '100215',
  '100214',
  '100213',
  '100212',
  '100211',
  '100210',
  '100209',
  '100208',
  '100207',
  '100206',
  '100205',
  '100202',
  '100201',
  '100200',
  '100199',
  '100198',
  '100197',
  '100196',
  '100195',
  '100194',
  '100193',
  '100192',
  '100191',
  '100190',
  '100189',
  '100188',
  '100187',
  '100186',
  '100185',
  '100184',
  '100183',
  '100182',
  '100181',
  '100180',
  '100179',
  '100177',
  '100176',
  '100175',
  '100174',
  '100173',
  '100170',
  '100169',
  '100168',
  '100167',
  '100166',
  '100165',
  '100164',
  '100163',
  '100162',
  '100161',
  '100160',
  '100159',
  '100158',
  '100157',
  '100156',
  '100155',
  '100154',
  '100153',
  '100152',
  '100151',
  '100150',
  '100149',
  '100148',
  '100147',
  '100146',
  '100145',
  '100144',
  '100141',
  '100140',
  '100139',
  '100138',
  '100137',
  '100136',
  '100135',
  '100134',
  '100133',
  '100132',
  '100131',
  '100130',
  '100129',
  '100128'
];
