// Framework
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// Store & Actions
import { clearNewMessage, fetchMessages, sendNewMessage, uploadFileAttachment, updateSendMessage } from '../../../actions/MessageActions';

// Components
import { Form, FormGroup } from 'react-bootstrap';
import { Button, Checkbox, Input, Textarea, Panel, toast } from '@d3sw/one-ui-components';
import DragAndDrop from 'src/components/DragAndDrop/DragAndDropWithoutBugs';
import CancelConfirm from '../../alerts/CancelConfirm';
import FindRecipients from './FindRecipients';
import RequestMaterials from '../../viewer/Ratings/RequestMaterials';

// Utils
import { parseRequestedMaterials } from '../../viewer/Ratings/RatingsUtils';
import { messageAttachment } from '../../../utils/AcceptedFileTypes';
import * as Utils from '../../../utils/Utils';

// CSS
import './NewMessage.scss';

const MAX_ATTACHMENTS = 15;

class NewMessage extends Component {
  state = {
    attachedFiles: [],
    attachmentRequests: [],
    availableRecipients: [],
    changesMade: false,
    content: '',
    isChecked: true,
    isSendingMessage: false,
    minimize: false,
    recipients: [],
    relatedTo: '',
    showCancelConfirm: false,
    subject: '',
    submitterGroupId: '',
    title: 'New Message'
  };

  broadcasterType = '';
  requestMaterialsOptions = [];

  componentDidMount = () => {
    if (this.props.authUser.reviewerUser) {
      this.broadcasterType = Object.keys(this.props.authUser.broadcasterUsersInfo)[0];
      this.requestMaterialsOptions = parseRequestedMaterials(this.broadcasterType, this.props.staticData);
    }
    this.setState((prevState) => ({
      ...prevState,
      ...this.props.newMessage,
      content: this.props.newMessage.quote ? `\r\r----\r ${this.props.quote}` : '',
      subject: this.props.newMessage.subject
    }));
  };

  componentDidUpdate({ newMessage }) {
    const { selectedRecipients, content, relatedTo, subject, submitterGroupId, title } = this.props.newMessage;
    if (newMessage.submitterGroupId !== submitterGroupId) {
      this.setState({
        content,
        subject,
        title,
        relatedTo,
        recipients: selectedRecipients,
        availableRecipients: selectedRecipients,
        submitterGroupId
      });
    }
  }

  addRecipients = (recipients) => {
    this.setState({
      changesMade: true,
      recipients:  (recipients ?? []).map(({ userId }) => userId)
    });
  };

  onDropSubmission = (data) => {
    const { attachedFiles } = this.state;
    const acceptedFiles = data.filter(({ type }) => messageAttachment.allowedSubmissions.findIndex((mimeType) => mimeType === type) !== -1);
    const rejectedFiles = data.filter(({ type }) => messageAttachment.allowedSubmissions.findIndex((mimeType) => mimeType === type) === -1);
    const renamedFiles = Utils.preventSpecialChars(acceptedFiles);

    if (rejectedFiles.length) {
      rejectedFiles.forEach(({ ext }) => {
        toast.error(`You cannot upload Files of type .${ext ? ext : 'undefined'}`);
      });

      if (rejectedFiles.length === data.length) {
        return;
      }
    }

    this.setState({ isSendingMessage: true });

    const files = renamedFiles.reduce((files, currentFile) => {
      files.push(currentFile);

      return files;
    }, []);

    const attachedFilesSize = Object.keys(attachedFiles).length;
    const acceptedSize = Object.keys(renamedFiles).length;

    if (attachedFilesSize + acceptedSize > MAX_ATTACHMENTS) {
      const numAcceptedFiles = attachedFilesSize + acceptedSize - MAX_ATTACHMENTS;
      const acceptedFilesLen = acceptedSize - numAcceptedFiles;
      files.splice(acceptedFilesLen);

      toast.error(this.props.localizedLabels.notifications.maxMessageAttachmentLimit);
    }

    renamedFiles.map(async (file) => {
      let fileExists = false;

      if (attachedFiles) {
        attachedFiles.map((existingFile) => {
          if (existingFile === file.name) {
            toast.error(`File already attached - ${file.name}`);
            fileExists = true;
          }
        });
      }

      if (!fileExists) {
        const response = await this.props.uploadFileAttachment(file);
        if (response !== 'Error File Upload' && response && response.data) {
          let fileName = response.data.uploadedFileNametoFilePaths[0].fileName;

          if (fileName && fileName.length > 0) {
            let attachedFiles = [];

            if (this.state.attachedFiles) {
              attachedFiles = this.state.attachedFiles;
            }

            if (attachedFiles) {
              attachedFiles.push(response.data.uploadedFileNametoFilePaths[0]);
            }

            this.setState({ attachedFiles });

            this.changesMade = true;
          }
        }
      }
      this.setState({ isSendingMessage: false });
    });
  };

  clearAttachments = () => {
    this.setState({
      attachedFiles: []
    });
  };

  removeAttachment = (e, index) => {
    this.setState(({ attachedFiles }) => ({
      attachedFiles: [...attachedFiles.slice(0, index), ...attachedFiles.slice(index + 1)]
    }));
  };

  sendMessage = async () => {
    const { attachedFiles, attachmentRequests, submitterGroupId, content, subject, recipients } = this.state;
    const { authUser } = this.props;
    if (recipients.length === 0 || recipients.includes('-99')) {
      toast.error('To is a required field');
      return;
    }

    if (subject === '' && this.props.newMessage.subject === '') {
      toast.error('Subject is a required field');
      return;
    }

    if (!content) {
      toast.error('Body is a required field');
      return;
    }

    this.setState({ isSendingMessage: true });

    let local = moment().format('YYYY-MM-DD HH:mm:ss.SSSZZ');
    const fileAttachments = [];
    let fileAttachmentRequests = [];

    if (attachedFiles) {
      attachedFiles.forEach((document) => {
        fileAttachments.push({
          fileName: document.fileName,
          fileChecksum: document.fileChecksum,
          fileSize: document.fileSize,
          destinations: {
            destination: [-1]
          },
          type: 31
        });
      });
    }

    if (attachmentRequests) {
      fileAttachmentRequests = attachmentRequests.map(({ mediaTypeId }, index) => ({
        claimId: '',
        messageId: '',
        name: '',
        requestId: '',
        mediaTypeId: mediaTypeId,
        order: `${index}`,
        statusId: '0',
        created: local
      }));
    }

    let finalBodyVal = this.state.content;

    finalBodyVal = finalBodyVal.replace(/\n/g, ' <br> ');

    const id = authUser.submitterUser
      ? authUser.submitterUserInfo.submitterUser.id
      : Object.keys(authUser.broadcasterUsersInfo)
          .map((key) => authUser.broadcasterUsersInfo[key].user)
          .slice()
          .shift().id;
    const newMessage = {
      [authUser.submitterUser ? 'senderId' : 'senderID']: id,
      body: finalBodyVal,
      subject: this.state.subject,
      created: local,
      recipients: {
        user: recipients.map((userId) => ({ [authUser.submitterUser ? 'userId' : 'userID']: userId, read: false }))
      },
      attachments: fileAttachments
    };

    if (authUser.reviewerUser) {
      newMessage.unread = true;
      newMessage.attachmentRequests = fileAttachmentRequests;
    }

    if (authUser.submitterUser) {
      newMessage.status = 0;
    }

    if (this.state.relatedTo && submitterGroupId !== '') {
      newMessage.submitterGroupID = submitterGroupId.toString();
    }

    if (this.props.rootSubmitterMessageId && this.props.previousSubmitterMessageId) {
      newMessage.previousSubmitterMessageId = this.props.previousSubmitterMessageId;
      newMessage.rootSubmitterMessageId = this.props.rootSubmitterMessageId;
    }

    const { data } = await this.props.sendNewMessage(newMessage, attachedFiles, this.broadcasterType);
    if (data) {
      newMessage.submitterMessageId = data.message.submitterMessageId || data.message.submitterMessageID;
      newMessage.broadcasterMessageId = data.message.broadcasterMessageId || data.message.broadcasterMessageID;
      newMessage.status = 1;
      this.updateSentMessageStatusWithSubmitterID(
        newMessage,
        data.message[authUser.submitterUser ? 'submitterMessageId' : 'broadcasterMessageID'],
        this.broadcasterType
      );
    }
    this.setState({
      content: '',
      subject: '',
      isSendingMessage: false
    });
  };

  updateSentMessageStatusWithSubmitterID = async (message, submitterMessageID, broadcaster) => {
    if (broadcaster) {
      this.sendMessageSucces();
    } else {
      const response = await this.props.updateSentMessageStatus(message);
      if (response && response.status === 200) {
        if (response.data.message.submitterMessageId === submitterMessageID) {
          this.sendMessageSucces();
        }
      }
    }
  };

  sendMessageSucces = () => {
    this.props.fetchMessages();

    this.closeNewMessage();
    toast.success('Your Message was sent');
    this.clearAttachments();
  };

  hasChanges = () => {
    if (this.state.changesMade) {
      this.toggleCancelConfirmModalVisibility();
    } else {
      this.setState(
        {
          changesMade: false,
          content: '',
          subject: ''
        },
        () => {
          this.closeNewMessage();
        }
      );
    }
  };

  handleChange = ({ target }) => {
    this.setState({
      changesMade: true,
      [target.name]: target.value
    });
  };

  handleRelatedToChange = ({ target }) => {
    this.setState({
      submitterGroupId: target.checked ? this.props.newMessage.submitterGroupId : '',
      isChecked: target.checked
    });
  };

  toggleCancelConfirmModalVisibility = () => {
    this.setState(({ showCancelConfirm }) => ({
      showCancelConfirm: !showCancelConfirm
    }));
  };

  closeNewMessage = () => {
    this.state.showCancelConfirm && this.toggleCancelConfirmModalVisibility();
    this.setState({ changesMade: false, attachedFiles: [], attachmentRequests: [] });
    this.props.clearNewMessage();
  };

  toggleNewMessage = () => {
    this.setState(({ minimize }) => ({
      minimize: !minimize
    }));
  };

  onRequestMaterialsChange = (attachmentRequests) => {
    this.setState({ attachmentRequests });
  };

  render() {
    // TODO: Add a flag here to check if the user has message privileges as well as the visibility to render the component
    if (!this.props.newMessage.visibility) {
      return null;
    }

    return (
      <React.Fragment>
        <CancelConfirm show={this.state.showCancelConfirm} close={this.toggleCancelConfirmModalVisibility} proceed={this.closeNewMessage} />

        <Panel
          heading={this.state.title}
          className={`dock-modal ${this.state.minimize && 'minimize'}`}
          onDoubleClick={this.toggleNewMessage}
        >
          <div className="dock-modal-header" onDoubleClick={this.toggleNewMessage}>
            <span className="minimize pull-right">
              <a onClick={this.toggleNewMessage}>
                <i className="fa fa-minus" />
              </a>
            </span>
          </div>

          <div className="dock-content">
            <div className="dock-modal-body">
              <div id="new-message">
                <Form>
                  <FormGroup>
                    <label className="ouic-form-input__label">{this.props.localizedLabels.inboxMessage.from}:</label>
                    <p>Me</p>
                  </FormGroup>

                  <FormGroup>
                    <div>{this.props.localizedLabels.inboxMessage.to}:</div>
                    <FindRecipients recipients={this.state.availableRecipients} onChange={this.addRecipients} />
                  </FormGroup>

                  <FormGroup>
                    <Input
                      id="newMessage_input_subject"
                      name="subject"
                      type="text"
                      label={`${this.props.localizedLabels.inboxMessage.subject}:`}
                      placeholder="Type your subject"
                      value={this.state.subject}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  {this.state.relatedTo && (
                    <Checkbox
                      label={this.state.relatedTo}
                      className="custom-checkbox"
                      checked={this.state.isChecked}
                      onChange={this.handleRelatedToChange}
                    />
                  )}
                  <FormGroup>
                    <Textarea
                      id="newMessage_input_message"
                      name="content"
                      rows="3"
                      placeholder="Type your message"
                      value={this.state.content}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Form>
              </div>
            </div>

            <div className="drag-and-drop-new-msg">
              {this.props.authUser.submitterUser ? (
                <FormGroup>
                  <div className="drag-label">{this.props.localizedLabels.inboxMessage.document}</div>

                  <DragAndDrop
                    id="uploadRatingsDocument"
                    className="upload-asset"
                    multiple
                    fileTypes={[
                      'image/gif','image/jpeg', '.jpeg', 'image/bmp',
                      'application/pdf',
                      'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      'application/zip', '.zip'
                    ]} 
                    files={this.state.attachedFiles || []}
                    handleDrop={(file) => this.onDropSubmission(file)}
                  />

                  {this.state.attachedFiles.length > 0 && (
                    <div className="applied-filters">
                      <div className="applied-filters-heading">
                        Attached Files <a onClick={this.clearAttachments}>clear attached files</a>
                      </div>
                      {this.state.attachedFiles.map(({ fileName }, index) => (
                        <span className="label label-default" key={index}>
                          {' '}
                          {fileName}
                          <a onClick={(e) => this.removeAttachment(e, index)} className="remove-filter">
                            &times;
                          </a>
                        </span>
                      ))}
                    </div>
                  )}
                </FormGroup>
              ) : (
                <RequestMaterials
                  requestMaterialsOptions={this.requestMaterialsOptions}
                  onChange={this.onRequestMaterialsChange}
                  value={this.state.attachmentRequests}
                  messageView
                />
              )}
            </div>

            <div className="dock-modal-footer action-buttons right">
              <Button type="link" onClick={this.hasChanges}>
                {this.props.localizedLabels.inboxMessage.cancel}
              </Button>
              <Button type="primary" onClick={this.sendMessage} disabled={this.state.isSendingMessage}>
                {this.props.localizedLabels.inboxMessage.sendMessage}
              </Button>
            </div>
          </div>
        </Panel>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.authUser,
  localizedLabels: state.localizedLabels,
  newMessage: state.newMessage,
  staticData: state.staticData
});

const mapDispatchToProps = (dispatch) => ({
  clearNewMessage: () => dispatch(clearNewMessage()),
  fetchMessages: () => dispatch(fetchMessages()),
  sendNewMessage: (message, attachedFiles, broadcasterType) => dispatch(sendNewMessage(message, attachedFiles, broadcasterType)),
  uploadFileAttachment: (file) => dispatch(uploadFileAttachment(file)),
  updateSentMessageStatus: (message, attachedFiles, broadcasterType) => dispatch(updateSendMessage(message, attachedFiles, broadcasterType))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMessage);
