const APIS = {
  // Dashboard
  DASHBOARD: {
    INBOX: 'loadInbox',
    STATIC_DATA: 'staticInfo',
    ADVANCE_SEARCH: 'advanceSearch',

    REASSIGN_REVIEWER: 'reassignPackage',
    DELETE_REVIEWER: 'deleteReviewerPackage',
    GRAB_PACKAGE: 'grabPackage',
    FRAME_THUMBNAILS: 'media/download/thumbnails'
  },
  AUTH: {
    SIGN_IN: 'authmv2usr',
    LOG_OUT: 'invalidateAuthToken',
    RENEW_TOKEN: 'renewAuthToken',
    VALID_TOKEN: 'authmv2Validate',

    PASSWORD_RESET: 'passwordReset',
    PASSWORD_USE_TOKEN: 'usePasswordReset',
    PASSWORD_CHANGE: 'passwordChanger'
  },

  USER: {
    GET_SETTINGS: 'userSettings',
    SAVE_SETTINGS: 'saveUserSettings',
    SAVE_USER_PROFILE: 'saveUserProfile',
    GET_USER_PROFILE: 'userProfile'
  },

  RESPONSES: {
    ATTACHMENT_DOWNLOAD: 'media/download/reviewDocument'
  },

  CLAIMS: {
    GET_CLAIM: 'claim',
    CREATE: 'createClaim',
    DELETE: 'deleteClaim',
    BIND_CLAIM_ATTACHMENT: 'claimAttachment'
  },

  PLAYBACK_TOKEN: 'api/clientType/groupId/mediaId/token',

  MESSAGES: {
    MARK_MESSAGE_AS_READ: 'markMessageAsRead',
    MARK_MESSAGE_AS_READ_REVIEWER: 'reviewer/markMessageAsRead',
    SEND_NEW_MESSAGE: 'sendMessage',
    SEND_NEW_MESSAGE_REVIEWER: 'reviewer/sendMessage',
    UPDATE_SENT_MESSAGE_STATUS: 'updateSentMessageStatus',
    DOWNLOAD_MESSAGE_ATTACHMENT: 'message/download/attachment',
    GET_MESSAGES: 'messageSearch'
  },

  VIEWER: {
    REVIEWER_ACTIVATE_URL: 'api/broadcaster/activate/token',
    SUBMITTER_ACTIVATE_URL: 'api/submitter/activate/token',
    DOWNLOAD_URL: 'media/download/groupId/attachment/attachmentId/fileName',
    DOWNLOAD_REVIEWER_URL: 'media/download/submission',
    DOWNLOAD_SUBMITTER_URL: 'media/download/groupId/mediaId/fileName',
    DOWNLOAD_ATTACHMENT_URL: 'media/download/attachment',
    REVIEWER_DOWNLOAD_ATTACHMENT_URL: 'media/reviewer/download/attachment',
    SUBMIT_ADCODE: 'adid/validate'
  },

  PRODUCT: {
    CREATE: 'product/createProduct',
    ACTIVATE: 'product/activate',
    DEACTIVATE: 'product/deactivate',
    EDIT_AND_REACTIVATE: 'product/updateAndActivateDeactivateProduct',
    UPDATE: 'product/updateProduct'
  },

  FRAME_COMMENT: 'frameComment',
  PLAYBACK_URL: 'api/clientType/media/token',
  LOG_ERROR: 'logError',

  REVIEW: {
    SAVE: 'review',
    SAVE_RATINGS_TEMPLATE: 'createTemplate'
  },

  ARCHIVE_REVIEWER_PACKAGE: 'archiveReviewerPackage',
  RESOURCES: 'resources',
  SUBMISSION: {
    FILE_UPLOAD: 'uploadMedias',
    SAVE_SUBMISSION: 'uploadMediasToSubmitterServer',
    RECALL_SUBMISSION: 'recallPackage',
    FORWARD_SUBMISSION: 'forwardPackage',
    UPDATE_STATUS: 'updateSubmissionStatus',
    ATTACHMENT_UPLOAD: 'uploadAttachments',
    SAVE_ATTACHMENT: 'uploadAttachmentsToSubmitterServer',
    REVIEWER_ASSIGNMENT: 'assignedReviewer',
    UPDATE_REVIEWER_ASSIGNMENT: 'updateAssignedReviewer',
    GET_MEDIA_VERSION: 'mediaGroupVersionInfo',
    GET_RELATED_CODE: 'relatedCodes'
  },
} as const;

export default APIS;
