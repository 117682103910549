import ActionTypes from '../actions/ActionTypes';

export const LogInReducer = (
  state = {
    authToken: '',
    authorized: false,
    userName: '',
    timeout: 28800000
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.SIGN_IN_SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        authToken: '',
        authorized: false,
        userName: '',
        timeout: 28800000
      };
    }
  }
  return state;
};

// TODO: Remove this reduced as it only manages local state not shared
export const passwordResetReducer = (
  state = {
    error: '',
    data: { userName: '', resetToken: '', success: false }
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        error: 'false',
        data: action.payload
      };
    }
    case ActionTypes.PASSWORD_RESET_FAILURE: {
      return {
        ...state,
        error: 'true',
        data: action.payload
      };
    }
    case ActionTypes.SIGN_OUT: {
      return {
        error: '',
        data: { userName: '', resetToken: '', success: false }
      };
    }
  }
  return state;
};

// TODO: Remove this reduced as it only manages local state not shared
export const newPasswordReducer = (state = { error: '', data: { changeDone: false, error: false } }, action) => {
  switch (action.type) {
    case ActionTypes.PASSWORD_CHANGED: {
      return {
        ...state,
        data: action.payload
      };
    }
    case ActionTypes.PASSWORD_CHANGE_ERROR: {
      return {
        ...state,
        error: 'true',
        data: action.payload
      };
    }
    case ActionTypes.SIGN_OUT: {
      return {
        error: '',
        data: { changeDone: false, error: false }
      };
    }
  }
  return state;
};

// TODO: Remove this reduced as it only manages local state not shared
export const usePassResetReducer = (state = { error: '', data: { tokenValid: false } }, action) => {
  switch (action.type) {
    case ActionTypes.RESET_TOKEN_CONSUMED: {
      return {
        ...state,
        data: action.payload
      };
    }
    case ActionTypes.RESET_TOKEN_USE_ERROR: {
      return {
        ...state,
        error: 'true',
        data: action.payload
      };
    }
    case ActionTypes.SIGN_OUT: {
      return {
        error: '',
        data: { tokenValid: false }
      };
    }
  }
  return state;
};
