// Framework
import React, { Component } from 'react';

// Components
import MainNavbar from './MainNavbar';
import Footer from './Footer';

export default class MainLayout extends Component {
  de2;
  render() {
    const { component: Component, route } = this.props;
    return (
      <React.Fragment>
        <MainNavbar />
        <div className="content">
          <Component route={route} />
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
