import ActionTypes from '../actions/ActionTypes';
import axios from 'axios';
import Fingerprint2 from 'fingerprintjs2';
import APIS from 'src/constants/api';

// Components
import { toast } from '@d3sw/one-ui-components';
import { SUBTRACT_TIME_BEFORE_TOKEN_EXPIRY } from 'src/utils/Constants';

let isAuthorized = null;

export const loadFingerprint = () => {
  return () => {
    const localFingerprint = localStorage.getItem('mediavu-auth-clientFingerprint');

    if (localFingerprint) {
      axios.defaults.headers.common['clientFingerprint'] = localFingerprint;
      return;
    }

    Fingerprint2.getV18((fingerprint) => {
      localStorage.setItem('mediavu-auth-clientFingerprint', fingerprint);
      axios.defaults.headers.common['clientFingerprint'] = fingerprint;
    });

    // new Fingerprint2().get(fingerprint => {
    //   localStorage.setItem('mediavu-auth-clientFingerprint', fingerprint);
    //   axios.defaults.headers.common['clientFingerprint'] = fingerprint;
    // });
  };
};

export const setLocalIP = () => {
  return () => {
    axios.defaults.headers.common['X-Forwarded-For'] = '127.0.0.1';
  };
};

export const syncWithLocalStorage = () => {
  return async (dispatch) => {
    const localUser = JSON.parse(localStorage.getItem('mediavu-auth'));
    if (localUser) {
      return dispatch(setSession(localUser));
    }
  };
};

export const onLogin = (email, password) => {
  return async (dispatch, getState) => {
    const url = APIS.AUTH.SIGN_IN;
    const payload = {
      userName: email,
      password
    };

    window.localStorage.setItem('enhanceDialog', '1');

    return axios
      .put(url, payload)
      .then(({ data }) => {
        dispatch(setSession(data));

        return { authorized: data.authorized };
      })
      .catch((err) => {
        let errorMessage = 'Email and/or Password are invalid. Please try again.';

        switch (err.code) {
          case 10:
            errorMessage = getState().localizedLabels.loginValidation.activeAccountMoreThanOne;
            break;
          case 11:
            errorMessage = getState().localizedLabels.loginValidation.nonActiveAccountMoreThanOne;
            break;
          case 12:
            errorMessage = getState().localizedLabels.loginValidation.activeAndNonActiveAccountsSameTime;
            break;
          case 13:
            errorMessage = getState().localizedLabels.loginValidation.nonActiveAccount;
            break;
        }

        if (err && err.response.data.errorCode && err.response.data.errorCode === 'MVC_41') {
          errorMessage = 'Unable to login (Error 10).  Please contact Client Services at 1-323-960-7117 or mediavu.support@bydeluxe.com';
        }

        dispatch(clearSession());

        throw new Error(errorMessage);
      });
  };
};

export const onLogout = () => {
  return async (dispatch, getState) => {
    const { authToken, userName, authorized } = getState().authUser;
    dispatch(clearSession());

    if (authorized) {
      const url = APIS.AUTH.LOG_OUT;
      const payload = { authToken, userName };

      return axios.put(url, payload);
    }
  };
};

export const onRefreshToken = () => {
  return async (dispatch, getState) => {
    const url = APIS.AUTH.RENEW_TOKEN;
    const { authToken, userName } = getState().authUser;
    const payload = { authToken, userName };

    return axios.put(url, payload).then(({ data }) => dispatch(setSession({ ...getState().authUser, data })));
  };
};

export const onIsValidToken = () => {
  return async (dispatch) => {
    const url = APIS.AUTH.VALID_TOKEN;
    const localUser = JSON.parse(localStorage.getItem('mediavu-auth'));

    if (!localUser || !localUser.authToken || isAuthorized === false) {
      dispatch(clearSession());
      return Promise.reject({ authorized: false });
    }

    if (isAuthorized === null) {
      const payload = { authToken: localUser.authToken, userName: localUser.userName };
      return axios.post(url, payload).then(({ data }) => {
        if (!data.authorized) {
          dispatch(clearSession());
        } else {
          dispatch(setSession(localUser));
        }

        return { authorized: data.authorized };
      });
    } else {
      return Promise.resolve({ authorized: isAuthorized });
    }
  };
};

export const setSession = (data) => {
  return async (dispatch, getState) => {
    const expiresIn = (data.expiresIn - SUBTRACT_TIME_BEFORE_TOKEN_EXPIRY) * 1000;
    let { refreshIntervalId } = getState().authUser;

    isAuthorized = data.authorized;

    if (refreshIntervalId) {
      clearTimeout(refreshIntervalId);
    }

    refreshIntervalId = setTimeout(() => dispatch(onRefreshToken()), expiresIn);

    axios.defaults.headers.common['authToken'] = data.authToken;

    const authUser = { ...data, refreshIntervalId, userName: data.emailId };
    localStorage.setItem('mediavu-auth', JSON.stringify(authUser));

    dispatch({
      type: ActionTypes.SIGN_IN_SUCCESS,
      payload: authUser
    });
  };
};

export const clearSession = () => {
  return async (dispatch, getState) => {
    const { refreshIntervalId } = getState().authUser;
    isAuthorized = null;
    localStorage.removeItem('mediavu-auth');
    axios.defaults.headers.common['authToken'] = null;

    if (refreshIntervalId) {
      clearTimeout(refreshIntervalId);
    }

    dispatch({
      type: ActionTypes.SIGN_OUT
    });
  };
};

export const onResetPassword = (username) => {
  return async (dispatch) => {
    const url = APIS.AUTH.PASSWORD_RESET;

    const payload = {
      emailId: username
    };
    return axios
      .post(url, payload)
      .then(function (response) {
        dispatch({
          type: ActionTypes.PASSWORD_RESET_SUCCESS,
          payload: {
            userName: username,
            success: response.data.success
          }
        });

        return response.data;
      })
      .catch(function () {
        dispatch({
          type: ActionTypes.PASSWORD_RESET_FAILURE,
          payload: {
            userName: username,
            success: false
          }
        });

        return { success: false };
      });
  };
};

export const onResetPasswordDefault = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.PASSWORD_RESET_SUCCESS,
      payload: {
        userName: '',
        resetToken: '',
        success: false
      }
    });
  };
};

export const changePassword = (token, email, pass) => {
  return async () => {
    const url = APIS.AUTH.PASSWORD_CHANGE;

    const payload = {
      passwordResetToken: token,
      newClearTextPassword: pass,
      passwordOverride: true
    };

    return axios
      .put(url, payload)
      .then(() => {
        toast.success('Your password has been successfully changed');

        return { changed: true };
      })
      .catch((error) => {
        toast.error('Error', 'There was an error processing your request. Please try again.');

        return error;
      });
  };
};

export const usePassResetToken = (token) => {
  return async (dispatch) => {
    const url = APIS.AUTH.PASSWORD_USE_TOKEN;

    const payload = {
      passwordResetToken: token
    };

    return axios
      .put(url, payload)
      .then(function (response) {
        // WARNING: This message matches an API response, with the trailing space.
        const tokenExpired = response.data.message === 'Not a valid password reset request ';

        if (tokenExpired) {
          dispatch({
            type: ActionTypes.RESET_TOKEN_EXPIRED,
            payload: {
              tokenValid: false
            }
          });

          return { tokenValid: false };
        } else
          dispatch({
            type: ActionTypes.RESET_TOKEN_CONSUMED,
            payload: {
              tokenValid: true
            }
          });

        return { tokenValid: true };
      })
      .catch(function (error) {
        if (error == 'net::ERR_CONNECTION_TIMED_OUT')
          //console.log("timeout");
          dispatch({
            type: ActionTypes.RESET_TOKEN_USE_ERROR,
            payload: {
              tokenValid: false
            }
          });

        return { tokenValid: false };
      });
  };
};

export const loadSiteMessages = () => {
  return async () => {
    const baseUrl = APIS.RESOURCES;

    return axios.get(`/${baseUrl}/demoMessages-en.json`).then(({ data }) => data.messages);
  };
};
