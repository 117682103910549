// Framework
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Routes
import * as ROUTES from 'src/constants/Routes';

// Helpers
import { Role } from '../roles';

const WithAuth = ({ component: Component, roles, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUserRole = authUser.submitterUser ? Role.Submitter : authUser.reviewerUser ? Role.Reviewer : null;

      if (!authUser.authorized) {
        const hasAuthenticated = localStorage.getItem('mediavu-auth') !== null;
        return <Redirect to={`${ROUTES.LOGOUT}?referrer=${hasAuthenticated ? 'session-expiration' : 'unauthorized-access'}`} />;
      }

      // check if route is restricted by role
      if (authUser.authorized && roles && roles.indexOf(currentUserRole) === -1) {
        return <Redirect to={{ pathname: ROUTES.DASHBOARD }} />;
      }

      return <Component {...props} />;
    }}
  />
);

const mapStateToProps = (state) => ({
  authUser: state.authUser
});

export default connect(mapStateToProps)(WithAuth);
