// Framework
import moment from 'moment';

// Helpers
import * as Utils from '../../utils/Utils';

// Submitter Utilities

export function getSubmitterProductName(productId, { accounts }) {
  const allProductNames = accounts.reduce(
    (productsList, { products }) => productsList.concat(products.map(({ id, name }) => ({ id, name }))),
    []
  );

  const product = allProductNames.find((p) => parseInt(p.id, 10) === productId);
  return product ? product.name : '';
}

export function getSubmitterAccountName(submitterId, { submitters }) {
  const account = submitters.find((s) => parseInt(s.id, 10) === submitterId);
  return account ? account.name : '';
}

export function getSubmitterAgency(agencyId, { agencies }) {
  const agency = agencies.find((a) => parseInt(a.id, 10) === agencyId);
  return agency ? agency.name : '';
}

export function getSubmitterUsername(submittingUserId, { users }) {
  const user = users.find((u) => parseInt(u.id, 10) === submittingUserId);
  return user ? `${user.firstName} ${user.lastName}` : 'unknown name';
}

/**
 *
 * @param {*} recipientsInfo comes from Utils.getRecipients
 */
export function getRecipientNames(recipientsInfo) {
  return Object.values(recipientsInfo).map(({ recipientName }) => recipientName);
}

/**
 *
 * @param {*} recipientsInfo comes from Utils.getRecipients
 */
export function getUnifiedStatus(recipientInfo, media) {
  let inProgres = 0;
  let Received = 0;
  let Reviewed = 0;
  let status;

  if (
    media.status === Utils.UPLOAD_PENDING_TRANSCODING ||
    media.status === Utils.UPLOAD_TRANSCODING_COMPLETE ||
    media.status === Utils.UPLOAD_VERIFIED
  ) {
    status = Utils.PROCESSING;
  } else if (media.draftStatus === Utils.DRAFT_WITHOUT_FILE || media.draftStatus === Utils.DRAFT_WITH_FILE) {
    status = Utils.DRAFT;
  } else {
    Object.values(recipientInfo).forEach((r) => {
      switch (r.receivedStatus) {
        case Utils.NO_CONFIRMATION:
          inProgres++;
          break;
        case Utils.METADATA_TRANSFERRED:
          inProgres++;
          break;
        case Utils.FILE_TRANSFERRED:
          Received++;
          break;
        case Utils.RESPONSE_RECEIVED:
          Reviewed++;
          break;
        default:
          break;
      }
    });

    if (inProgres > 0 && Received === 0 && Reviewed === 0) status = Utils.NOT_YET_RECEIVED;
    else if (inProgres > 0 && Received > 0 && Reviewed === 0) status = Utils.DELIVERY_IN_PROGRESS;
    else if (inProgres === 0 && Received > 0 && Reviewed === 0) status = Utils.RECEIVED;
    else if (inProgres === 0 && Received > 0 && Reviewed > 0) status = Utils.REVIEWS_IN_PROGRESS;
    else if (inProgres === 0 && Received === 0 && Reviewed > 0) status = Utils.REVIEWS_COMPLETED;
    else if (inProgres > 0 && Received >= 0 && Reviewed > 0) status = Utils.DELIVERING_BUT_HAS_REVIEWS;
    else status = Utils.OTHER;
  }

  return status;
}

export function getLatestReviewedDate(mediaArray) {
  let lastReviewedDate = '';

  mediaArray.forEach(({ broadcasterResponses }) =>
    broadcasterResponses.forEach(
      ({ reviewDate }) =>
        (lastReviewedDate =
          !lastReviewedDate && reviewDate
            ? reviewDate
            : reviewDate && moment(reviewDate).isSameOrAfter(lastReviewedDate)
            ? reviewDate
            : lastReviewedDate)
    )
  );

  return lastReviewedDate;
}

// Reviewer Utilities
export function getReviewerMovieName(movieId, { movies }) {
  const movie = movies.find((m) => parseInt(m.id, 10) === parseInt(movieId, 10));
  return movie ? movie.name : '';
}

export function getReviewerRecipient(recipient, { departments, users }) {
  const user = users.find((u) => parseInt(u.id, 10) === parseInt(recipient.user[0].id, 10)) || {};
  const departmentsString = recipient.department.reduce((departmentString, { departmentID }) => {
    const department = departments.find((d) => parseInt(d.departmentID, 10) === parseInt(departmentID, 10));
    return `${departmentString}${departmentString ? ', ' : ''}${department ? department.departmentName : ''}`;
  }, '');

  return `${user.firstName} ${user.lastName} (${departmentsString})`;
}

export function getReviewerCompany(movieId, { movies, submitters }) {
  const movie = movies.find((m) => parseInt(m.id, 10) === parseInt(movieId, 10));
  if (movie) {
    const submitter = submitters.find((s) => parseInt(s.id, 10) === parseInt(movie.productionCompanyID, 10));
    return submitter ? submitter.name : 'N/A';
  }

  return 'N/A';
}
