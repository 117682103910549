import ActionTypes from '../actions/ActionTypes';

const initialState = {
  assets: {},
  cancelDialogVisible: false,
  uploadedFiles: [],
  version: 0,
  resettingSelection: false
};
export const ReviewPerAssetReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REMOVE_ASSET_REVIEWS: {
      return {
        assets: {},
        cancelDialogVisible: false,
        uploadedFiles: [],
        version: 0
      };
    }

    case ActionTypes.SET_REVIEW_VERSION: {
      return {
        ...state,
        version: action.payload
      };
    }

    case ActionTypes.SET_ASSET_REVIEW: {
      if (!state.assets.hasOwnProperty(action.id)) {
        state.assets[action.id] = action.payload;
      }

      return { ...state };
    }

    case ActionTypes.UPDATE_ADCODE: {
      state.assets[action.id].correctedAdCode = action.payload;

      return { ...state };
    }
    case ActionTypes.UPDATE_ASSET_RATING: {
      state.assets[action.id].ratings = {
        ...state.assets[action.id].ratings,
        ...action.payload
      };

      return { ...state };
    }

    // Remove media ratings from the store
    case ActionTypes.CLEAR_ASSET_RATING: {
      state.assets[action.id].ratings = {
        ...state.assets[action.id].ratings,
        mediaRatings: [],
        comment: ''
      };

      return { ...state };
    }

    // this flag is to tell the Ratings component to change the state and reset the MediaRatings to a [], when removing the Ratings in the store.
    case ActionTypes.RESET_ASSET_RATING: {
      return { ...state, resettingSelection: action.payload };
    }

    case ActionTypes.UPDATE_ASSET_INTERNAL: {
      state.assets[action.id].internals = {
        ...state.assets[action.id].internals,
        ...action.payload
      };

      return { ...state };
    }

    case ActionTypes.UPDATE_REVIEW_UPLOADED_FILE: {
      state.uploadedFiles = [...state.uploadedFiles.slice(), action.payload];

      return { ...state };
    }

    case ActionTypes.DELETE_REVIEW_UPLOADED_FILE: {
      state.uploadedFiles = [...state.uploadedFiles.slice(0, action.payload), ...state.uploadedFiles.slice(action.payload + 1)];

      return { ...state };
    }

    case ActionTypes.APPEND_ASSET_FRAME_IMAGE: {
      const reviewId = state.assets[action.id].id;
      const frames = action.payload.filter((review) => review.reviewId === reviewId);

      if (frames.length) {
        const frameImages = frames.reduce(
          (framesArray, { frameComments }) => [
            ...framesArray,
            ...frameComments.reduce((commentsArray, { frameTime, imageData }) => [...commentsArray, { frameTime, imageData }], [])
          ],
          []
        );

        state.assets[action.id].frames = state.assets[action.id].frames
          .map((frame) => {
            const frameImage = frameImages.find(({ frameTime }) => frame.time === parseInt(frameTime.replace(/\n/gi, ''), 10));

            return {
              ...frame,
              imageData: frameImage ? frameImage.imageData : null
            };
          })
          .sort((a, b) => a.time - b.time);
      }

      return { ...state };
    }

    case ActionTypes.UPDATE_ASSET_FRAME: {
      state.assets[action.id].frames = [...state.assets[action.id].frames.slice(), action.payload];

      return { ...state };
    }

    case ActionTypes.SORT_ASSET_FRAME: {
      state.assets[action.id].frames = [...state.assets[action.id].frames].sort((a, b) => a.time - b.time);

      return { ...state };
    }

    case ActionTypes.DELETE_ASSET_FRAME: {
      state.assets[action.id].frames = [
        ...state.assets[action.id].frames.slice(0, action.payload),
        ...state.assets[action.id].frames.slice(action.payload + 1)
      ];

      return { ...state };
    }

    // adds claims to each media, input is object but array is needed.
    case ActionTypes.UPDATE_ASSET_CLAIM: {
      const parsedClaims = Object.keys(action.payload).map((key) => action.payload[key]);
      state.assets[action.id].claims = parsedClaims;
      return { ...state };
    }

    case ActionTypes.EDIT_ASSET_CLAIM: {
      const claim = action.payload.claims.claims.slice().shift();
      const index = state.assets[action.id].claims.findIndex(({ claimId }) => claimId === claim.claimId);
      const neverMinusIndex = index >= 0 ? index : 0;
      state.assets[action.id].claims[neverMinusIndex] = claim;
      return { ...state };
    }

    case ActionTypes.REMOVE_ASSET_CLAIM: {
      const claim = action.payload.claims.claims.slice().shift();
      const index = state.assets[action.id].claims.findIndex(({ claimId }) => claimId === claim.claimId);
      state.assets[action.id].claims.splice(index, 1);
      return { ...state };
    }

    case ActionTypes.EXTEND_ASSET_CLAIMS: {
      const claim = action.payload.claims.claims.slice().shift();
      state.assets[action.id].claims = state.assets[action.id].claims.concat(claim);
      return { ...state };
    }

    case ActionTypes.COPY_TO_ALL_ASSETS: {
      const { comment, mediaRatings } = state.assets[action.id].ratings;

      Object.keys(state.assets).forEach((assetKey) => {
        state.assets[assetKey].ratings.comment = comment;
        state.assets[assetKey].ratings.mediaRatings = mediaRatings.map((mediaRating) => ({ ...mediaRating }));
      });

      return { ...state };
    }

    case ActionTypes.OPEN_RATINGS_CANCEL_DIALOG:
    case ActionTypes.CLOSE_RATINGS_CANCEL_DIALOG:
      return {
        ...state,
        cancelDialogVisible: action.payload
      };

    case ActionTypes.SIGN_OUT: {
      return {
        assets: {},
        cancelDialogVisible: false,
        uploadedFiles: [],
        version: 0
      };
    }

    default:
      return state;
  }
};
