import ActionTypes from '../actions/ActionTypes';

export const toggleTour = (value) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.OPEN_CLOSE_TOUR,
      payload: value
    });
  };
};
