// Framework
import React, { Component } from 'react';
import { detectAnyAdblocker } from 'just-detect-adblock';

// Components
import CustomModal from '../Modals/CustomModal';
class AdblockDetect extends Component {
  state = {
    usingAdblock: false,
    adBlockWarningDisplay: false
  };

  componentDidMount = async () => {
    const isAdblockDetected = await detectAnyAdblocker();
    this.setState({
      usingAdblock: isAdblockDetected,
      adBlockWarningDisplay: true
    });
  };

  render() {
    return this.state.usingAdblock ? (
      <CustomModal show={this.state.adBlockWarningDisplay} toggle={() => this.setState({ adBlockWarningDisplay: false })} title="Warning">
        <div className="dialog">
          <div className="dialog-icon">
            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
          </div>
          <div className="dialog-text">
            You might have an Ad Blocker enabled. This prevents some content from being rendered. Please whitelist this site.
          </div>
        </div>
      </CustomModal>
    ) : null;
  }
}

export default AdblockDetect;
