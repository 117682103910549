export default {
  // App Management
  TOGGLE_HAS_STATIC_DATA: 'TOGGLE_HAS_STATIC_DATA',
  TOGGLE_LOADING: 'TOGGLE_LOADING',
  TOGGLE_APP_THEME: 'TOGGLE_APP_THEME',

  //Fingerprint
  GENERATE_FINGERPRINT_SUCCESS: 'GENERATE_FINGERPRINT_SUCCESS',
  GENERATE_FINGERPRINT_ERROR: 'GENERATE_FINGERPRINT_ERROR',
  CLEAR_HTTP_HEADER: 'CLEAR_HTTP_HEADER',

  LOCAL_CLIENT_IP: 'LOCAL_CLIENT_IP',

  //Authentication
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  SIGN_OUT: 'SIGN_OUT',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILURE: 'PASSWORD_RESET_FAILURE',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
  PASSWORD_CHANGE_ERROR: 'PASSWORD_CHANGE_ERROR',
  RESET_TOKEN_CONSUMED: 'RESET_TOKEN_CONSUMED',
  RESET_TOKEN_EXPIRED: 'RESET_TOKEN_EXPIRED',
  RESET_TOKEN_USE_ERROR: 'RESET_TOKEN_USE_ERROR',

  SHOW_SESSION_EXPIRATION_DIALOG: 'SHOW_SESSION_EXPIRATION_DIALOG',
  SESSION_EXPIRATION_DIALOG_INTERVAL_ID: 'SESSION_EXPIRATION_DIALOG_INTERVAL_ID',
  SESSION_EXPIRATION_DIALOG_EXPIRES_IN: 'SESSION_EXPIRATION_DIALOG_EXPIRES_IN',

  //Dashboard
  INBOX_SUCCESS: 'INBOX_SUCCESS',
  INBOX_FAILURE: 'INBOX_FAILURE',
  INBOX_STATIC_DATA_SUCCESS: 'INBOX_STATIC_DATA_SUCCESS',
  INBOX_STATIC_DATA_FAILURE: 'INBOX_STATIC_DATA_FAILURE',

  OPEN_CANCEL_DIALOG: 'OPEN_CANCEL_DIALOG',
  CLOSE_CANCEL_DIALOG: 'CLOSE_CANCEL_DIALOG',

  TOGGLE_DELETE_CONFIRMATION_DIALOG: 'TOGGLE_DELETE_CONFIRMATION_DIALOG',
  SELECTED_PACKAGE_ROW: 'SELECTED_PACKAGE_ROW',

  TOGGLE_VIEWER_PLAYER: 'TOGGLE_VIEWER_PLAYER',
  SELECTED_MEDIA_ROW: 'SELECTED_MEDIA_ROW',

  TOGGLE_REVIEWER_ASSIGN: 'TOGGLE_REVIEWER_ASSIGN',
  SELECTED_REVIEWER_MEDIA_ROW: 'SELECTED_REVIEWER_MEDIA_ROW',

  BROADCASTER_ARCHIVE_SUCCESS: 'BROADCASTER_ARCHIVE_SUCCESS',
  BROADCASTER_ARCHIVE_ERROR: 'BROADCASTER_ARCHIVE_ERROR',

  TOGGLE_CONFIRM_DELETER_REVIEWER_PACKAGE: 'TOGGLE_CONFIRM_DELETER_REVIEWER_PACKAGE',
  SELECTED_MEDIA_REVIEWER_DELETE_ROW: 'SELECTED_MEDIA_REVIEWER_DELETE_ROW',
  TOGGLE_GRAB_CONFIRMATION: 'TOGGLE_GRAB_CONFIRMATION',
  SELECTED_MEDIA_GRAB: 'SELECTED_MEDIA_GRAB',

  //Recall
  RECALL_DESTINATION_PICKER: 'RECALL_DESTINATION_PICKER',
  SELECT_RECALL_DESTINATIONS: 'SELECT_RECALL_DESTINATIONS',
  RECALL_FAILURE: 'RECALL_FAILURE',

  //Package
  REVIEWER_ASSIGNMENT_SUCCESS: 'REVIEWER_ASSIGNMENT_SUCCESS',
  REVIEWER_ASSIGNMENT_FAILURE: 'REVIEWER_ASSIGNMENT_FAILURE',
  RECIPIENTS_SUCCESS: 'RECIPIENTS_SUCCESS',
  SUBMISSION_FILE_STAGE_SUCCESS: 'SUBMISSION_FILE_STAGE_SUCCESS',

  SUBMISSION_FILE_STAGE_FAILURE: 'SUBMISSION_FILE_STAGE_FAILURE',
  SUBMISSION_FILE_UPLOAD_SUCCESS: 'SUBMISSION_FILE_UPLOAD_SUCCESS',
  SUBMISSION_FILE_UPLOAD_FAILURE: 'SUBMISSION_FILE_UPLOAD_FAILURE',
  SUBMISSION_FILE_UPLOAD_PROGRESS: 'SUBMISSION_FILE_UPLOAD_PROGRESS',
  SUBMISSION_FILE_METADATA_UPDATE: 'SUBMISSION_FILE_METADATA_UPDATE',
  SUBMISSION_FILE_DELETE_SUCCESS: 'SUBMISSION_FILE_DELETE_SUCCESS',
  SUBMISSION_FILES_CLEAR: 'SUBMISSION_FILES_CLEAR',
  SUBMISSION_FILE_APPLY_SUCCESS: 'SUBMISSION_FILE_APPLY_SUCCESS',
  SUBMISSION_FILE_APPLY_FAILURE: 'SUBMISSION_FILE_APPLY_FAILURE',
  ATTACHMENT_FILE_STAGE_SUCCESS: 'ATTACHMENT_FILE_STAGE_SUCCESS',
  ATTACHMENT_FILE_STAGE_FAILURE: 'ATTACHMENT_FILE_STAGE_FAILURE',
  ATTACHMENT_FILE_UPLOAD_SUCCESS: 'ATTACHMENT_FILE_UPLOAD_SUCCESS',
  ATTACHMENT_FILE_UPLOAD_FAILURE: 'ATTACHMENT_FILE_UPLOAD_FAILURE',
  ATTACHMENT_FILE_UPLOAD_PROGRESS: 'ATTACHMENT_FILE_UPLOAD_PROGRESS',
  ATTACHMENT_FILE_METADATA_UPDATE: 'ATTACHMENT_FILE_METADATA_UPDATE',
  ATTACHMENT_FILE_APPLY_SUCCESS: 'ATTACHMENT_FILE_APPLY_SUCCESS',
  ATTACHMENT_FILE_APPLY_FAILURE: 'ATTACHMENT_FILE_APPLY_FAILURE',
  ATTACHMENT_FILE_DELETE_SUCCESS: 'ATTACHMENT_FILE_DELETE_SUCCESS',
  ATTACHMENT_FILE_CLEAR: 'ATTACHMENT_FILE_CLEAR',

  MEDIA_GROUP_VERSION_SUCCESS: 'MEDIA_GROUP_VERSION_SUCCESS',
  MEDIA_GROUP_VERSION_ERROR: 'MEDIA_GROUP_VERSION_ERROR',
  MEDIA_GROUP_VERSION_DELETE: 'MEDIA_GROUP_VERSION_DELETE',

  RELATED_CODE_WARNING: 'RELATED_CODE_WARNING',
  CLEAR_RELATED_CODE_WARNING: 'CLEAR_RELATED_CODE_WARNING',

  DELIVERY_STATUS_TEXT_SUCCESS: 'DELIVERY_STATUS_TEXT_SUCCESS',
  DELIVERY_STATUS_TEXT_FAILURE: 'DELIVERY_STATUS_TEXT_FAILURE',

  REQUEST_STATUS_TEXT_SUCCESS: 'REQUEST_STATUS_TEXT_SUCCESS',
  REQUEST_STATUS_TEXT_FAILURE: 'REQUEST_STATUS_TEXT_FAILURE',

  UNIFIED_STATUS_TEXT_SUCCESS: 'UNIFIED_STATUS_TEXT_SUCCESS',
  UNIFIED_STATUS_TEXT_FAILURE: 'UNIFIED_STATUS_TEXT_FAILURE',

  UPLOAD_STATUS_TEXT_SUCCESS: 'UPLOAD_STATUS_TEXT_SUCCESS',
  UPLOAD_STATUS_TEXT_FAILURE: 'UPLOAD_STATUS_TEXT_FAILURE',

  DRAFT_STATUS_TEXT_SUCCESS: 'DRAFT_STATUS_TEXT_SUCCESS',
  DRAFT_STATUS_TEXT_FAILURE: 'DRAFT_STATUS_TEXT_FAILURE',

  TOGGLE_LOADING_PACKAGE: 'TOGGLE_LOADING_PACKAGE',

  //Responses
  LOAD_RESPONSE_SUCCESS: 'LOAD_RESPONSE_SUCCESS',
  LOAD_RESPONSE_ERROR: 'LOAD_RESPONSE_ERROR',
  LOCALIZED_RESPONSES: 'LOCALIZED_RESPONSES',

  //Report
  TOGGLE_REPORT_DIALOG: 'TOGGLE_REPORT_DIALOG',
  SELECTED_ROW_REPORT: 'SELECTED_ROW_REPORT',
  SELECTED_ROW_FRAME_CAPTURE: 'SELECTED_ROW_FRAME_CAPTURE',
  SELECTED_ROW_PACKAGES: 'SELECTED_ROW_PACKAGES',
  CLEAR_REPORTS: 'CLEAR_REPORTS',

  //Settings
  USER_SETTING_SUCCESS: 'USER_SETTING_SUCCESS',
  USER_SETTING_FAILURE: 'USER_SETTING_FAILURE',
  LOCALIZED_DASHBOARD: 'LOCALIZED_DASHBOARD',
  LOCALIZED_NOTIFICATIONS: 'LOCALIZED_NOTIFICATIONS',
  LOCALIZED_PACKAGE_MANAGEMENT: 'LOCALIZED_PACKAGE_MANAGEMENT',
  LOCALIZED_LOGIN_VALIDATION: 'LOCALIZED_LOGIN_VALIDATION',
  LOAD_TIMEZONES: 'LOAD_TIMEZONES',
  LOAD_LANGUAGE_OPTIONS: 'LOAD_LANGUAGE_OPTIONS',
  APP_VERSION: 'APP_VERSION',

  //Tour
  OPEN_CLOSE_TOUR: 'OPEN_CLOSE_TOUR',

  //Profile
  USER_PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
  USER_PROFILE_FAILURE: 'USER_PROFILE_FAILURE',

  //UI
  TOGGLE_POPUP: 'TOGGLE_POPUP',
  TOGGLE_RELATED_CODES: 'TOGGLE_RELATED_CODES',
  TOGGLE_RELATED_CODES_VALUE: 'TOGGLE_RELATED_CODES_VALUE',
  TOGGLE_RELATED_CODES_DESCRIPTION: 'TOGGLE_RELATED_CODES_DESCRIPTION',

  ALLOWED_DESTINATIONS: 'ALLOWED_DESTINATIONS',
  ALLOWED_RECIPIENTS: 'ALLOWED_RECIPIENTS',
  SELECT_RECIPIENT: 'SELECT_RECIPIENT',
  SELECT_ALL_RECIPIENTS: 'SELECT_ALL_RECIPIENTS',
  UNSELECT_RECIPIENT: 'UNSELECT_RECIPIENT',
  ADD_PO_STATUS: 'ADD_PO_STATUS',
  ADD_PO_NUMBER: 'ADD_PO_NUMBER',

  CLEAR_RECIPIENTS: 'CLEAR_RECIPIENTS',
  UNSELECT_ALL_RECIPIENTS: 'UNSELECT_ALL_RECIPIENTS',

  ADD_GENERAL_COMMENT: 'ADD_GENERAL_COMMENT',

  SELECT_ACCOUNT: 'SELECT_ACCOUNT',
  SELECT_PRODUCT: 'SELECT_PRODUCT',
  SELECT_RELEASE_DATE_STATUS: 'SELECT_RELEASE_DATE_STATUS',
  SELECT_RELEASE_DATE: 'SELECT_RELEASE_DATE',
  SELECT_RATING: 'SELECT_RATING',
  SET_ONE_ACCOUNT_ONLY: 'SET_ONE_ACCOUNT_ONLY',

  SAVE_SUBMISSION_MEDIA_STAGE: 'SAVE_SUBMISSION_MEDIA_STAGE',
  SAVE_SUBMISSION_MEDIA_SUCCESS: 'SAVE_SUBMISSION_MEDIA_SUCCESS',
  SAVE_SUBMISSION_MEDIA_ERROR: 'SAVE_SUBMISSION_MEDIA_ERROR',

  SAVE_SUBMISSION_ATTACHMENT_STAGE: 'SAVE_SUBMISSION_ATTACHMENT_STAGE',
  SAVE_SUBMISSION_ATTACHMENT_SUCCESS: 'SAVE_SUBMISSION_ATTACHMENT_SUCCESS',
  SAVE_SUBMISSION_ATTACHMENT_ERROR: 'SAVE_SUBMISSION_ATTACHMENT_ERROR',

  SAVE_SUBMISSION_STATUS: 'SAVE_SUBMISSION_STATUS',

  SEND_SUBMISSION_SUCCESS: 'SEND_SUBMISSION_SUCCESS',

  TOGGLE_CAN_SAVE: 'TOGGLE_CAN_SAVE',

  CLEAR_PACKAGE_CONTENT: 'CLEAR_PACKAGE_CONTENT',
  CLEAR_PACKAGE_CONTENT_EXCEPT_ACCOUNT: 'CLEAR_PACKAGE_CONTENT_EXCEPT_ACCOUNT',
  CLEAR_SAVE_SUBMISSION: 'CLEAR_SAVE_SUBMISSION',
  CLEAR_SAVE_ATTACHMENT: 'CLEAR_SAVE_ATTACHMENT',

  SAVE_MEDIA_REQUEST_ID: 'SAVE_MEDIA_REQUEST_ID',
  SAVE_ATTACHMENT_REQUEST_ID: 'SAVE_ATTACHMENT_REQUEST_ID',
  CLEAR_ALL_REQUEST_ID: 'CLEAR_ALL_REQUEST_ID',

  LOAD_DEMO_MESSAGE: 'LOAD_DEMO_MESSAGE',

  PLAYBACK_TOKEN: 'PLAYBACK_TOKEN',
  PLAYBACK_TOKEN_CLEAR: 'PLAYBACK_TOKEN_CLEAR',
  PLAYBACK_MEDIA: 'PLAYBACK_MEDIA',
  PLAYBACK_ACTIVATE: 'PLAYBACK_ACTIVATE',
  UPDATE_PLAYING_MEDIA: 'UPDATE_PLAYING_MEDIA',
  SET_PLAYER_DIMENTION_SIZE: 'SET_PLAYER_DIMENTION_SIZE',

  UPDATE_ADCODE: 'UPDATE_ADCODE',

  REVIEW_MEDIA_ID: 'REVIEW_MEDIA_ID',

  SET_PDF_FILE_VIEWER: 'SET_PDF_FILE_VIEWER',
  CLEAR_PDF_VIEWER_DATA: 'CLEAR_PDF_VIEWER_DATA',
  SET_BASE64_URL_TO_RENDER: 'SET_BASE64_URL_TO_RENDER',

  EXISTING_FRAME_COMMENT: 'EXISTING_FRAME_COMMENT',
  EXISTING_FRAME_COMMENT_TEXT: 'EXISTING_FRAME_COMMENT_TEXT',
  EXISTING_FRAME_COMMENT_IMAGES_TEXT: 'EXISTING_FRAME_COMMENT_IMAGES_TEXT',

  NEW_FRAME_COMMENT: 'NEW_FRAME_COMMENT',

  RATINGS_REVIEW: 'RATINGS_REVIEW',

  // Claims
  CLAIMS_ATTACHMENT_SUCCESS: 'CLAIMS_ATTACHMENT_SUCCESS',
  CLAIMS_ATTACHMENT_ERROR: 'CLAIMS_ATTACHMENT_ERROR',
  NEW_CLAIM_FILE_BIND_SUCCESS: 'NEW_CLAIM_FILE_BIND_SUCCESS',
  NEW_CLAIM_FILE_BIND_ERROR: 'NEW_CLAIM_FILE_BIND_ERROR',
  CLAIMS_SUBMIT_REVIEW_SUCCESS: 'CLAIMS_SUBMIT_REVIEW_SUCCESS',
  CLAIMS_SUBMIT_REVIEW_ERROR: 'CLAIMS_SUBMIT_REVIEW_ERROR',

  NEW_FRAME_COMMENT_TEXT: 'NEW_FRAME_COMMENT_TEXT',
  NEW_FRAME_COMMENT_IMAGE: 'NEW_FRAME_COMMENT_IMAGE',

  CLEAR_FRAME_COMMENTS: 'CLEAR_FRAME_COMMENTS',

  ENABLE_CAPTURE_FRAME: 'ENABLE_CAPTURE_FRAME',

  FINALIZE_FRAME_COMMENT: 'FINALIZE_FRAME_COMMENT',

  REMOVE_FRAME_COMMENT_EXISTING: 'REMOVE_FRAME_COMMENT_EXISTING',
  REMOVE_FRAME_COMMENT_IMAGES_EXISTING: 'REMOVE_FRAME_COMMENT_IMAGES_EXISTING',

  REMOVE_FRAME_COMMENT_NEW: 'REMOVE_FRAME_COMMENT_NEW',
  REMOVE_FRAME_COMMENT_IMAGES_NEW: 'REMOVE_FRAME_COMMENT_IMAGES_NEW',

  INTERNAL_REVIEWERS_PRESENT: 'INTERNAL_REVIEWERS_PRESENT',
  INTERNAL_ADDITIONAL_REVIEWERS: 'INTERNAL_ADDITIONAL_REVIEWERS',
  INTERNAL_COMMENTS: 'INTERNAL_COMMENTS',

  SUBMIT_NEW_REVIEW: 'SUBMIT_NEW_REVIEW',
  NEW_RATINGS_REVIEW: 'NEW_RATINGS_REVIEW',
  CLEAR_RATINGS_PACKAGE: 'CLEAR_RATINGS_PACKAGE',
  TOGGLE_LOADING_SAVE_RATINGS: 'TOGGLE_LOADING_SAVE_RATINGS',

  REMOVE_ATTACHED_RATINGS_FILE: 'REMOVE_ATTACHED_RATINGS_FILE',

  UPDATE_RATINGS_COMMENTS: 'UPDATE_RATINGS_COMMENTS',
  UPDATE_RATINGS_VERSION: 'UPDATE_RATINGS_VERSION',

  RATINGS_ATTACHMENT_SCUCCESS: 'RATINGS_ATTACHMENT_SCUCCESS',

  UPDATE_REVIEW_FILE_ATTACHMENT_STORE: 'UPDATE_REVIEW_FILE_ATTACHMENT_STORE',

  UPDATE_REQUESTED_DOCUMENT_LIST: 'UPDATE_REQUESTED_DOCUMENT_LIST',

  // Messages
  CREATE_NEW_MESSAGE: 'CREATE_NEW_MESSAGE',
  CLEAR_MESSAGES: 'CLEAR_MESSAGES',
  CLEAR_NEW_MESSAGE: 'CLEAR_NEW_MESSAGE',
  LOCALIZED_INBOX_MESSAGES: 'LOCALIZED_INBOX_MESSAGES',
  MARK_MESSAGE_AS_READ: 'MARK_MESSAGE_AS_READ',
  MESSAGES_SUCCESS: 'MESSAGES_SUCCESS',
  NEW_MESSAGE_SET_VISIBILITY: 'NEW_MESSAGE_SET_VISIBILITY',
  UNREAD_MESSAGE_COUNT: 'UNREAD_MESSAGE_COUNT',
  QUICK_SEARCH_SUCCESS: 'QUICK_SEARCH_SUCCESS',

  CREATE_PRODUCT_SHOW: 'CREATE_PRODUCT_SHOW',
  CREATE_PRODUCT_MODE: 'CREATE_PRODUCT_MODE',
  CLEAR_PRODUCTS_LIST: 'CLEAR_PRODUCTS_LIST',
  EDIT_PRODUCT_MODAL_SHOW: 'EDIT_PRODUCT_MODAL_SHOW',
  EDIT_PRODUCT_SELECT_PRODUCT: 'EDIT_PRODUCT_SELECT_PRODUCT',
  EDIT_PRODUCT_TITLE_CHANGE_WARNING_MODAL_VISIBLE: 'EDIT_PRODUCT_TITLE_CHANGE_WARNING_MODAL_VISIBLE',
  EDIT_PRODUCT_SELECT_DESTINATION_MODAL_VISIBLE: 'EDIT_PRODUCT_SELECT_DESTINATION_MODAL_VISIBLE',
  EDIT_PRODUCT_TITLE_CHANGE: 'EDIT_PRODUCT_TITLE_CHANGE',
  EDIT_PRODUCT_CATEGORY_CHANGE: 'EDIT_PRODUCT_CATEGORY_CHANGE',
  EDIT_PRODUCT_ABBREV_CHANGE: 'EDIT_PRODUCT_ABBREV_CHANGE',
  EDIT_PRODUCT_SELECTABLE_CATEGORY: 'EDIT_PRODUCT_SELECTABLE_CATEGORY',
  EDIT_PRODUCT_SELECTED_DESTINATION: 'EDIT_PRODUCT_SELECTED_DESTINATION',
  FORWARD_MULTIPLE: 'FORWARD_MULTIPLE',
  UPDATE_SELECTED_PLATFORM: 'UPDATE_SELECTED_PLATFORM',
  UPDATE_CERTIFICATE: 'UPDATE_CERTIFICATE',
  UPDATE_DESCRIPTORS: 'UPDATE_DESCRIPTORS',
  UPDATE_ADVERTISING_PERIOD: 'UPDATE_ADVERTISING_PERIOD',

  EDIT_PRODUCT_SET_RELEASE_DATE: 'EDIT_PRODUCT_SET_RELEASE_DATE',
  EDIT_PRODUCT_SET_RELEASE_DATE_STATUS: 'EDIT_PRODUCT_SET_RELEASE_DATE_STATUS',
  EDIT_PRODUCT_SET_RATINGS: 'EDIT_PRODUCT_SET_RATINGS',
  TOGGLE_VIDEO_GAME_MODAL_VISIBLE: 'TOGGLE_VIDEO_GAME_MODAL_VISIBLE',
  HIDE_ALL_MODALS: 'HIDE_ALL_MODALS',

  CREATE_PRODUCT_MODE_SHOW: 'CREATE_PRODUCT_MODE_SHOW',

  CREATE_PRODUCT_LOAD_ACCOUNTS: 'CREATE_PRODUCT_LOAD_ACCOUNTS',
  CREATE_PRODUCT_SELECT_ACCOUNT: 'CREATE_PRODUCT_SELECT_ACCOUNT',

  CREATE_PRODUCT_LOAD_PRODUCTS: 'CREATE_PRODUCT_LOAD_PRODUCTS',
  CREATE_PRODUCT_SELECT_PRODUCT: 'CREATE_PRODUCT_SELECT_PRODUCT',

  CREATE_PRODUCT_LOAD_CATEGORIES: 'CREATE_PRODUCT_LOAD_CATEGORIES',
  CREATE_PRODUCT_SELECT_CATEGORY: 'CREATE_PRODUCT_SELECT_CATEGORY',

  CREATE_PRODUCT_SET_ABBREVIATION: 'CREATE_PRODUCT_SET_ABBREVIATION',

  CREATE_PRODUCT_LOAD_RATINGS: 'CREATE_PRODUCT_LOAD_RATINGS',
  CREATE_PRODUCT_SET_RATINGS: 'CREATE_PRODUCT_SET_RATINGS',
  CREATE_PRODUCT_SET_RATING_REASON: 'CREATE_PRODUCT_SET_RATING_REASON',

  CREATE_PRODUCT_SET_RELEASE_DATE_STATUS: 'CREATE_PRODUCT_SET_RELEASE_DATE_STATUS',
  CREATE_PRODUCT_SET_RELEASE_DATE: 'CREATE_PRODUCT_SET_RELEASE_DATE',

  CREATE_PRODUCT_LOAD_BILLING_OPTIONS: 'CREATE_PRODUCT_LOAD_BILLING_OPTIONS',
  CREATE_PRODUCT_SET_BILLING_OPTION: 'CREATE_PRODUCT_SET_BILLING_OPTION',

  CREATE_PRODUCT_CLEAR: 'CREATE_PRODUCT_CLEAR',

  TRANCODING_IN_PORGRESS: 'TRANCODING_IN_PORGRESS',

  // Review per asset
  SET_ASSET_REVIEW: 'SET_ASSET_REVIEW',
  SET_REVIEW_VERSION: 'SET_REVIEW_VERSION',

  APPEND_REVIEW_TEMPLATE: 'APPEND_REVIEW_TEMPLATE',
  APPEND_REVIEW_IN_INBOX: 'APPEND_REVIEW_IN_INBOX',
  UPDATE_ASSET_RATING: 'UPDATE_ASSET_RATING',
  CLEAR_ASSET_RATING: 'CLEAR_ASSET_RATING',
  RESET_ASSET_RATING: 'RESET_ASSET_RATING',
  UPDATE_ASSET_INTERNAL: 'UPDATE_ASSET_INTERNAL',
  UPDATE_REVIEW_UPLOADED_FILE: 'UPDATE_REVIEW_UPLOADED_FILE',
  DELETE_REVIEW_UPLOADED_FILE: 'DELETE_REVIEW_UPLOADED_FILE',
  APPEND_ASSET_FRAME_IMAGE: 'APPEND_ASSET_FRAME_IMAGE',
  SORT_ASSET_FRAME: 'SORT_ASSET_FRAME',
  UPDATE_ASSET_FRAME: 'UPDATE_ASSET_FRAME',
  DELETE_ASSET_FRAME: 'DELETE_ASSET_FRAME',
  UPDATE_ASSET_CLAIM: 'UPDATE_ASSET_CLAIM',
  CLEAR_ASSET_CLAIM: 'CLEAR_ASSET_CLAIM',
  EDIT_ASSET_CLAIM: 'EDIT_ASSET_CLAIM',
  EXTEND_ASSET_CLAIMS: 'EXTEND_ASSET_CLAIMS',
  REMOVE_ASSET_CLAIM: 'REMOVE_ASSET_CLAIM',

  REMOVE_ASSET_REVIEWS: 'REMOVE_ASSET_REVIEWS',
  REMOVE_MEDIA_GROUP_IN_INBOX: 'REMOVE_MEDIA_GROUP_IN_INBOX',

  COPY_TO_ALL_ASSETS: 'COPY_TO_ALL_ASSETS'
};
