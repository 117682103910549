const getCurrentUserID = (authUser) => {
  if (authUser.submitterUser) {
    return authUser.submitterUserInfo.submitterUser.id;
  } else {
    const broadcasterType = Object.keys(authUser.broadcasterUsersInfo)[0];
    return authUser.broadcasterUsersInfo[broadcasterType].user.id;
  }
};

const getRecipientsNetwork = (recipientData, staticData) => {
  const { broadcasters } = staticData.data.submitterUserStaticInfo.submitterUserStaticData;

  const agencyName = broadcasters.filter((broadcaster) => {
    return broadcaster.broadcasterUsers.some((user) => parseInt(user.id) === parseInt(recipientData.userID));
  });

  return agencyName.length ? agencyName[0].broadcasterName : 'unknown name';
};

const truncatePreview = (text, maxLength) => {
  let truncatedText = text;
  if (truncatedText.length > maxLength) {
    truncatedText = truncatedText.substr(0, maxLength - 3) + '...';
  }
  return truncatedText;
};

export const stripHTMLTagsfromString = (value) => {
  value = value.replace(/<br>/g, ' \n ');
  value = value.replace(/<[^>]+>/g, '');
  value = value.replace(/&#160;/g, ' ');
  return value;
};

export const shouldEnableMessaging = (authUser, userProfile) => {
  if (authUser && userProfile) {
    if (authUser && authUser.reviewerUser) {
      return userProfile.contactable;
    } else if (authUser.submitterUserInfo) {
      const { privileges } = authUser.submitterUserInfo.submitterUser;
      return privileges.hasOwnProperty('allowedMessageDestinations') && privileges.allowedMessageDestinations !== null;
    }
  }
};

export const hasValidDestinations = (authUser, staticData, row) => {
  const [firstMedia] = row.media;
  const { allowedMessageDestinations } = authUser.submitterUserInfo.submitterUser.privileges;
  const { broadcasters } = staticData.data.submitterUserStaticInfo.submitterUserStaticData;
  if (!allowedMessageDestinations || allowedMessageDestinations === null) {
    return false;
  }

  const contacts = broadcasters.reduce((contactableUsers, { broadcasterID, broadcasterUsers }) => {
    const allowedMessageDestinationsUser = allowedMessageDestinations.filter(
      ({ broadcasterID: allowedMessageBroadcasterId }) => allowedMessageBroadcasterId === broadcasterID
    );

    const users = broadcasterUsers.filter(
      ({ broadcasterId: broadcasterID, contactable }) =>
        contactable && allowedMessageDestinationsUser.findIndex((d) => parseInt(d.broadcasterID, 10) === parseInt(broadcasterID, 10)) !== -1
    );
    return contactableUsers.concat(users);
  }, []);

  return (
    firstMedia.broadcasterResponses
      .filter((broadcasterResponse) => !!broadcasterResponse.user)
      .reduce((contactableRecipients, { user }) => {
        const recipients = contacts.filter(({ id }) => user.id === id);
        return [...contactableRecipients, ...recipients];
      }, []) > 0
  );
};

export const getSender = (authUser, staticData, message) => {
  let currentUserId = getCurrentUserID(authUser);
  let users = '';
  let broadcasters = '';
  let userName = 'unknown name';
  let submitterContacts = '';

  if (parseInt(currentUserId) === parseInt(message.senderID)) {
    return 'Me';
  }

  if (authUser.submitterUser) {
    currentUserId = authUser.submitterUserInfo.submitterUser.id;
    users = staticData.data.submitterUserStaticInfo.submitterUserStaticData.users;
    broadcasters = staticData.data.submitterUserStaticInfo.submitterUserStaticData.broadcasters;

    broadcasters.forEach((d) => {
      d.broadcasterUsers.forEach((e) => {
        if (parseInt(e.id) === parseInt(message.senderID)) {
          userName = `${e.firstName} ${e.lastName}`;
          return userName;
        }
      });
    });

    const user = users.filter((user) => parseInt(user.id) === parseInt(message.senderID));

    if (user !== undefined && user.length > 0) {
      userName = `${user[0].firstName} ${user[0].lastName}`;
      return userName;
    }
  } else {
    const broadcasterUserStaticInfo = staticData.data.broadcasterUserStaticInfo;
    Object.keys(broadcasterUserStaticInfo).forEach((element) => {
      submitterContacts = broadcasterUserStaticInfo[element].submitterContacts;
      users = broadcasterUserStaticInfo[element].users;
    });

    submitterContacts.map((submitterContact) => {
      if (message.senderID === submitterContact.id) {
        userName = `${submitterContact.firstName} ${submitterContact.lastName}`;
      }
    });

    users.map((user) => {
      if (message.senderID === user.id) {
        userName = user.fullName;
      }
    });
  }
  return userName;
};

export const getSenderNetwork = (authUser, staticData, message) => {
  const currentUserId = getCurrentUserID(authUser);

  if (authUser.submitterUser) {
    const { broadcasters } = staticData.data.submitterUserStaticInfo.submitterUserStaticData;

    const agencyName = broadcasters.filter((broadcaster) => {
      return broadcaster.broadcasterUsers.some((user) => parseInt(user.id) === parseInt(message.senderID));
    });

    return agencyName.length ? agencyName[0].broadcasterName : 'unknown network';
  }

  if (parseInt(currentUserId) === parseInt(message.senderID)) {
    return '';
  }
  return 'unknown network';
};

export const getAllRecipientNetwork = (recipients, staticData, authUser) => {
  if (recipients.user === null) {
    return [];
  }

  if (authUser.reviewerUser) {
    return [];
  }

  const allRecipientsNetwork = recipients.user.map((recipient) => {
    if (!recipient || !recipient.userID) {
      return ' ';
    }
    return getRecipientsNetwork(recipient, staticData);
  });

  return allRecipientsNetwork;
};

export const getAllRecipient = (authUser, staticData, allInboxRecipients) => {
  if (!allInboxRecipients.user) {
    return [];
  }

  let allRecipients = [];
  const currentUserId = getCurrentUserID(authUser);

  if (authUser.reviewerUser) {
    const broadcasterUserStaticInfo = staticData.data.broadcasterUserStaticInfo;
    const recipients = allInboxRecipients.user;

    let users = [];
    let submitterContacts = [];
    Object.keys(broadcasterUserStaticInfo).forEach((item) => {
      users = broadcasterUserStaticInfo[item].users;
      submitterContacts = broadcasterUserStaticInfo[item].submitterContacts;
    });

    recipients.map((recipient) => {
      let recipientID = recipient.userID;
      users.forEach((user) => {
        if (recipientID === user.id) {
          allRecipients.push({
            recipientName: recipientID === currentUserId ? 'Me' : user.fullName,
            recipientNetwork: 'undefined',
            recipientID: recipientID,
            messageRead: false
          });
        }
      });
      submitterContacts.forEach((submitterUser) => {
        if (recipientID === submitterUser.id) {
          allRecipients.push({
            recipientName: `${submitterUser.firstName} ${submitterUser.lastName}`,
            recipientNetwork: 'undefined',
            recipientID: recipientID,
            messageRead: false
          });
        }
      });
    });

    return allRecipients;
  }

  if (!allInboxRecipients.user) {
    allRecipients.push({
      recipientName: '',
      recipientNetwork: 'undefined',
      recipientID: '-1',
      messageRead: false
    });
  } else if (Object.keys(allInboxRecipients.user).length === 1) {
    if (parseInt(authUser.submitterUserInfo.submitterUser.id) === parseInt(allInboxRecipients.user[0].userID)) {
      allRecipients.push({
        recipientName: 'Me',
        recipientNetwork: ' ',
        recipientID: authUser.submitterUserInfo.submitterUser.id,
        messageRead: allInboxRecipients.user[0].unread
      });
    } else {
      const user = staticData.data.submitterUserStaticInfo.submitterUserStaticData.users.filter(
        (user) => parseInt(user.id) === parseInt(allInboxRecipients.user[0].userID)
      );
      if (user !== undefined && user.length > 0) {
        const userName = `${user[0].firstName} ${user[0].lastName}`;

        allRecipients.push({
          recipientName: userName,
          recipientNetwork: getRecipientsNetwork(allInboxRecipients.user[0], staticData),
          recipientID: allInboxRecipients.user[0].userID.toString(),
          messageRead: allInboxRecipients.user[0].unread
        });
      } else {
        const broadcasters = staticData.data.submitterUserStaticInfo.submitterUserStaticData.broadcasters;
        let userName = 'unknown name';
        broadcasters.forEach((d) => {
          d.broadcasterUsers.forEach((e) => {
            if (parseInt(e.id) === parseInt(allInboxRecipients.user[0].userID)) {
              userName = `${e.firstName} ${e.lastName}`;
            }
          });
        });

        allRecipients.push({
          recipientName: userName,
          recipientNetwork: getRecipientsNetwork(allInboxRecipients.user[0], staticData),
          recipientID: allInboxRecipients.user[0].userID.toString(),
          messageRead: allInboxRecipients.user[0].unread
        });
      }
    }
  } else {
    for (let j = 0; j < Object.keys(allInboxRecipients.user).length; j++) {
      if (parseInt(authUser.submitterUserInfo.submitterUser.id) === parseInt(allInboxRecipients.user[j].userID)) {
        allRecipients.push({
          recipientName: 'Me',
          recipientNetwork: ' ',
          recipientID: authUser.submitterUserInfo.submitterUser.id,
          messageRead: allInboxRecipients.user[j].unread
        });
      } else {
        const user = staticData.data.submitterUserStaticInfo.submitterUserStaticData.users.filter(
          (user) => user.id === allInboxRecipients.user[j].userID.toString()
        );
        if (user !== undefined && user.length > 0) {
          const userName = `${user[0].firstName} ${user[0].lastName}`;

          allRecipients.push({
            recipientName: userName,
            recipientNetwork: getRecipientsNetwork(allInboxRecipients.user[j], staticData),
            recipientID: allInboxRecipients.user[j].userID.toString(),
            messageRead: allInboxRecipients.user[j].unread
          });
        } else {
          const broadcasters = staticData.data.submitterUserStaticInfo.submitterUserStaticData.broadcasters;
          let userName = 'unknown name';
          broadcasters.forEach((d) => {
            d.broadcasterUsers.forEach((e) => {
              if (parseInt(e.id) === parseInt(allInboxRecipients.user[j].userID)) {
                userName = `${e.firstName} ${e.lastName}`;
              }
            });
          });

          allRecipients.push({
            recipientName: userName,
            recipientNetwork: getRecipientsNetwork(allInboxRecipients.user[j], staticData),
            recipientID: allInboxRecipients.user[j].userID.toString(),
            messageRead: allInboxRecipients.user[j].unread
          });
        }
      }
    }
  }

  return allRecipients;
};

export const hasAttachment = (data) => {
  if (data.attachments.length > 0 || data.attachmentRequests.length > 0) {
    return true;
  }
  return false;
};

export const getAttachments = (data) => {
  let attachmentsList = [];

  if (hasAttachment(data)) {
    for (let i = 0; i < data.attachments.length; i++) {
      attachmentsList.push({
        fileName: data.attachments[i].fileName,
        attachmentLocation: data.attachments[i].attachmentLocation,
        fileId: data.attachments[i].id || data.attachments[i].attachmentId || data.attachments[i].attachmentID
      });
    }
  }

  return attachmentsList;
};

export const getMessagePreview = (data) => {
  let message = stripHTMLTagsfromString(data.body);
  message = truncatePreview(message, 160);
  return message;
};
