import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import deluxeWhiteLogo from '../../assets/images/deluxe-white.svg';
import * as actions from '../../actions/SettingsActions';

// UTILS
import { isVersionValid } from 'src/utils/Utils';

import { EMAIL } from 'src/constants/footer';

import './footer.scss';

const moment = require('moment-timezone');

const Footer = () => {
  const { appVersion } = useSelector((state) => state.version);
  const dispatch = useDispatch();
  const currentYear = moment(new Date().UTC).format('YYYY');
  const versionText = isVersionValid(appVersion) ? `MediaVu v${appVersion}` : `MediaVu`;

  useEffect(() => {
    dispatch(actions.getChangeLog());
  }, []);

  const mailtoLink = `mailto:${EMAIL.TARGET}?subject=${EMAIL.SUBJECT}&body=${EMAIL.BODY}`;

  return (
    <footer>
      <div className="ouic-card">
        <div className="ouic-card-footer">
          <div>
            <div className="copyright">
              {versionText}&nbsp;
              <a href="https://www.bydeluxe.com" target="_blank" className="pointer">
                Deluxe
              </a>
              &nbsp; © {currentYear}
            </div>
          </div>
          <div className="contact-support">
            <a href={mailtoLink}>Contact Support</a>
            <a href="https://www.bydeluxe.com" target="_blank">
              <img className="logo" src={deluxeWhiteLogo} alt="deluxe logo" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
