import axios from 'axios';
import ActionTypes from '../actions/ActionTypes';
import * as errorActions from '../actions/ErrorActions';
import ErrorTypes from '../actions/ErrorTypes';

import { toast } from '@d3sw/one-ui-components';
import APIS from 'src/constants/api';

export const openProfileCancelDialog = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.OPEN_CANCEL_DIALOG,
      payload: true
    });
  };
};

export const closeProfileCancelDialog = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CLOSE_CANCEL_DIALOG,
      payload: false
    });
  };
};

export const saveUserProfile = (profile) => {
  return async (dispatch, getState) => {
    const url = APIS.USER.SAVE_USER_PROFILE;
    const { authToken, userName } = getState().authUser;

    const payload = {
      ...profile,
      authToken,
      userName
    };

    return axios
      .put(url, payload)
      .then((response) => {
        toast.success(getState().localizedLabels.notifications.userProfileSaveSuccess);

        dispatch({
          type: ActionTypes.USER_PROFILE_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        toast.error(error.response.data.error);

        dispatch({
          type: ActionTypes.USER_PROFILE_FAILURE,
          payload: error.response.data
        });

        dispatch(errorActions.logError(error, ErrorTypes.PROFILE_FAILURE, payload, error.response.headers));
      });
  };
};

export const getUserProfile = () => {
  return async (dispatch, getState) => {
    const url = APIS.USER.GET_USER_PROFILE;
    const { authToken, emailId: emailID, userName } = getState().authUser;

    const payload = {
      authToken,
      emailID,
      userName
    };

    return axios
      .post(url, payload)
      .then((response) => {
        dispatch({
          type: ActionTypes.USER_PROFILE_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error.response && error.response.headers) {
          dispatch(errorActions.logError(error, ErrorTypes.PROFILE_FAILURE, payload, error.response.headers));
        }
      });
  };
};
