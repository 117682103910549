import ActionTypes from '../actions/ActionTypes';

export const PdfViewer = (
  state = {
    visible: false,
    fileToView: {}
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_PDF_FILE_VIEWER: {
      return {
        visible: action.payload.visible,
        fileToView: action.payload.fileToView
      };
    }

    case ActionTypes.CLEAR_PDF_VIEWER_DATA: {
      return {
        visible: false,
        fileToView: {}
      };
    }
  }

  return state;
};
