import ActionTypes from '../actions/ActionTypes';
import * as moment from 'moment';

export const RecallReducer = (
  state = {
    showPicker: false,
    recallDestinations: [],
    selectedDestinations: [],
    mediaGroup: '',
    error: ''
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.RECALL_DESTINATION_PICKER: {
      return {
        ...state,
        showPicker: action.payload.isVisible,
        recallDestinations: action.payload.recallDestinations,
        mediaGroup: action.payload.mediaGroup,
        error: ''
      };
    }

    case ActionTypes.SELECT_RECALL_DESTINATIONS: {
      return {
        ...state,
        selectedDestinations: action.payload,
        error: ''
      };
    }
    case ActionTypes.RECALL_FAILURE: {
      return {
        ...state,
        error: action.payload
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        showPicker: false,
        recallDestinations: [],
        selectedDestinations: [],
        mediaGroup: '',
        error: ''
      };
    }
  }

  return state;
};

export const InboxReducer = (
  state = {
    error: null,
    data: {}
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.INBOX_SUCCESS: {
      return {
        ...state,
        error: null,
        data: action.payload
      };
    }
    case ActionTypes.INBOX_FAILURE: {
      return {
        ...state,
        error: 'Error loading the Inbox data',
        data: action.payload
      };
    }
    case ActionTypes.APPEND_REVIEW_IN_INBOX: {
      const newState = { ...state };
      const mediaGroupIndex = newState.data.broadcasterDocuments[action.payload.broadcasterType].mediaGroups.findIndex(
        (mediaGroup) => mediaGroup.id === action.payload.mediaUpdate.mediaGroup.mediaGroupId
      );

      if (mediaGroupIndex !== -1) {
        const mediaGroup = newState.data.broadcasterDocuments[action.payload.broadcasterType].mediaGroups[mediaGroupIndex];

        action.payload.mediaUpdate.mediaGroup.media.forEach((payloadMedia) => {
          const mediaIndex = mediaGroup.media.findIndex((media) => media.id === payloadMedia.mediaId);

          if (mediaIndex !== -1) {
            mediaGroup.media[mediaIndex].reviews.push({
              ...payloadMedia.reviews[0],
              reviewed: moment().format('YYYY-MM-DD HH:mm:ss.SS')
            });
          }
        });
      }
      return {
        ...newState
      };
    }
    case ActionTypes.REMOVE_MEDIA_GROUP_IN_INBOX: {
      const newState = {
        ...state,
        data: {
          ...state.data,
          broadcasterDocuments: {
            ...state.data.broadcasterDocuments,
            [action.payload.broadcasterType]: {
              ...state.data.broadcasterDocuments[action.payload.broadcasterType],
              mediaGroups: state.data.broadcasterDocuments[action.payload.broadcasterType].mediaGroups.filter(
                (mediaGroup) => mediaGroup.id !== action.payload.mediaGroupId
              )
            }
          }
        }
      };

      return { ...newState };
    }
    case ActionTypes.SIGN_OUT: {
      return {
        data: {},
        error: null
      };
    }
    // no default
  }
  return state;
};

export const StaticDataReducer = (
  state = {
    error: null,
    data: ''
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.INBOX_STATIC_DATA_SUCCESS: {
      return {
        ...state,
        error: null,
        data: action.payload
      };
    }
    case ActionTypes.INBOX_STATIC_DATA_FAILURE: {
      return {
        ...state,
        error: 'Error loading the Submitter static data',
        data: action.payload
      };
    }
    case ActionTypes.APPEND_REVIEW_TEMPLATE: {
      const newState = { ...state };
      const templateIndex = newState.data.broadcasterUserStaticInfo[action.broadcasterType].templates.findIndex(
        ({ templateID }) => templateID === action.payload.templateID
      );
      if (templateIndex !== -1) {
        newState.data.broadcasterUserStaticInfo[action.broadcasterType].templates[templateIndex] = action.payload;
      } else {
        newState.data.broadcasterUserStaticInfo[action.broadcasterType].templates.push(action.payload);
      }
      return {
        ...newState
      };
    }
    case ActionTypes.SIGN_OUT: {
      return {
        data: {},
        error: null
      };
    }
    // no default
  }
  return state;
};

// TODO: Remove this reducer as its only managing local component visibility
export const ToggleDeleteConfirmationReducers = (state = { visible: false, value: null }, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_DELETE_CONFIRMATION_DIALOG: {
      return {
        ...state,
        visible: action.payload
      };
    }
    case ActionTypes.SELECTED_PACKAGE_ROW: {
      return {
        ...state,
        value: action.payload
      };
    }
    case ActionTypes.SIGN_OUT: {
      return { visible: false, value: null };
    }
  }
  return state;
};

// TODO: Remove this reducer as its only managing local component visibility
export const ViewerPlayerReducers = (state = { visible: false, value: null }, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_VIEWER_PLAYER: {
      return {
        ...state,
        visible: action.payload
      };
    }
    case ActionTypes.SELECTED_MEDIA_ROW: {
      return {
        ...state,
        value: action.payload
      };
    }
    case ActionTypes.SIGN_OUT: {
      return { visible: false, value: null };
    }
  }
  return state;
};

// TODO: Remove this reducer as its only managing local component visibility
export const ReviewerAssignReducers = (state = { visible: false, value: null }, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_REVIEWER_ASSIGN: {
      return {
        ...state,
        visible: action.payload
      };
    }
    case ActionTypes.SELECTED_REVIEWER_MEDIA_ROW: {
      return {
        ...state,
        value: action.payload
      };
    }
    case ActionTypes.SIGN_OUT: {
      return { visible: false, value: null };
    }
  }
  return state;
};

// TODO: Remove this reducer as its only managing local component visibility
export const DeleteReviewerPackageReducers = (state = { visible: false, value: null }, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_CONFIRM_DELETER_REVIEWER_PACKAGE: {
      return {
        ...state,
        visible: action.payload
      };
    }
    case ActionTypes.SELECTED_MEDIA_REVIEWER_DELETE_ROW: {
      return {
        ...state,
        value: action.payload
      };
    }
    case ActionTypes.SIGN_OUT: {
      return { visible: false, value: null };
    }
  }
  return state;
};

// TODO: Remove this reducer as its only managing local component visibility
export const GrabReducers = (state = { visible: false, value: null }, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_GRAB_CONFIRMATION: {
      return {
        ...state,
        visible: action.payload
      };
    }
    case ActionTypes.SELECTED_MEDIA_GRAB: {
      return {
        ...state,
        value: action.payload
      };
    }
    case ActionTypes.SIGN_OUT: {
      return { visible: false, value: null };
    }
  }
  return state;
};
