// Framework
import { Component } from 'react';

// Components
import { FormGroup, Col } from 'react-bootstrap';
import { Dropdown, Button, Label } from '@d3sw/one-ui-components';

class RequestMaterials extends Component {
  onChange = ({ id: mediaTypeId, label, value }) => {
    const requestedMaterials = this.props.value.slice();
    const isInArrayIndex = requestedMaterials.findIndex((r) => r.mediaTypeId === mediaTypeId);

    if (isInArrayIndex === -1) {
      requestedMaterials.push({ mediaTypeId, label, value });

      this.props.onChange(requestedMaterials);
    }
  };

  resetSelection = () => {
    this.props.onChange([]);
  };

  removeAt = (index) => {
    this.props.onChange([...this.props.value.slice(0, index), ...this.props.value.slice(index + 1)]);
  };

  render() {

    return (
      <Col md={12} lg={this.props.messageView ? 12 : 6} pl={0}>
        <FormGroup className="request-materials-container">
          <div className="request-materials-label">Request Materials</div>
          <Dropdown
            id="request-materials"
            placeholder="Select Materials"
            options={this.props.requestMaterialsOptions}
            onChange={this.onChange}
            disabled={this.props.disabled}
            hideSelectedOptions={true}
          />
          {!!this.props.value.length && (
            <div className="applied-filters">
              <div>
                Requested Materials &nbsp;
                <Button type="link" onClick={this.resetSelection}>
                  remove all
                </Button>
              </div>
              {this.props.value.map((requestedMaterial, index) => (
                <Label key={index} type="primary" onRemove={() => this.removeAt(index)}>
                  {requestedMaterial.label}
                </Label>
              ))}
            </div>
          )}
        </FormGroup>
      </Col>
    );
  }
}

RequestMaterials.defaultProps = {
  onChange: () => {},
  requestMaterialsOptions: [],
  value: [],
  disabled: false
};

export default RequestMaterials;
