export default {
  APP_ERROR: 'APP_ERROR',

  PROFILE_FAILURE: 'PROFILE_FAILURE',
  INBOX_FAILURE: 'INBOX_FAILURE',
  STATIC_INFO_FAILURE: 'STATIC_INFO_FAILURE',
  RECALL_FAILURE: 'RECALL_FAILURE',
  DELETE_FAILURE: 'DELETE_FAILURE',
  EDIT_DRAFT_FAILURE: 'EDIT_DRAFT_FAILURE',
  REASSIGN_FAILURE: 'REASSIGN_FAILURE',
  GRAB_FAILURE: 'GRAB_FAILURE',
  ARCHIVE_FAILURE: 'ARCHIVE_FAILURE',
  DELETE_REVIEWER_FAILURE: 'DELETE_REVIEWER_FAILURE',

  MAIN_NAVBAR: 'MAIN_NAVBAR',
  DATAGRID_TABLE: 'DATAGRID_TABLE',
  SUB_NAV: 'SUB_NAV',
  FILTER: 'FILTER',
  TOUR: 'TOUR',

  HEADER_ERROR: 'HEADER_ERROR'
};
