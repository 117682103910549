import ActionTypes from '../actions/ActionTypes';

export const NewMessageReducers = (
  state = {
    content: '',
    previousSubmitterMessageId: '',
    quote: '',
    relatedTo: '',
    rootSubmitterMessageId: '',
    selectedRecipients: [],
    selectedSenders: [],
    subject: '',
    submitterGroupId: '',
    title: 'New Message',
    visibility: false
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.CREATE_NEW_MESSAGE: {
      return {
        ...action.payload,
        visibility: true
      };
    }

    case ActionTypes.NEW_MESSAGE_SET_VISIBILITY: {
      return {
        ...state,
        visibility: action.payload
      };
    }

    case ActionTypes.CLEAR_NEW_MESSAGE:
    case ActionTypes.SIGN_OUT: {
      return {
        content: '',
        previousSubmitterMessageId: '',
        quote: '',
        relatedTo: '',
        rootSubmitterMessageId: '',
        selectedRecipients: [],
        selectedSenders: [],
        subject: '',
        submitterGroupId: '',
        title: 'New Message',
        visibility: false
      };
    }
  }

  return state;
};
