export const ROOT = '/';

// Auth Routes
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const FORGOT_PASSWORD = '/forgot-password';
export const NEW_PASSWORD = '/newpassword';

// Common Pages
export const DASHBOARD = '/dashboard';
export const ADVANCED_SEARCH = '/search';
export const MESSAGES = '/messages';

export const MESSAGE_DROP_DOWN = '/messages_drop_down';

// Submitter Specific Pages
export const CREATE_PACKAGE = '/package-management/new';
export const EDIT_PACKAGE = '/package-management/edit';
export const FORWARD_PACKAGE = '/package-management/forward';
export const RESPONSES = '/responses';
export const VIEWER = '/viewer/view';

// Reviewer Specific Pages
export const PLAY = '/review';
export const CLAIMS = '/claims';

// Settings Pages
export const USER_SETTINGS = '/user-settings/';
export const USER_PROFILE = '/user-profile/';

// Error Pages
export const ERROR_403 = '/403';
