// Framework
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import { Dropdown } from '@d3sw/one-ui-components';

class FindRecipients extends Component {
  state = {
    users: [],
    selectedUsers: []
  };

  componentDidUpdate = ({ recipients }) => {
    if (recipients.length !== this.props.recipients.length) {
      const { authUser, staticData } = this.props;
      let allContactableUsers = [];

      if (Object.keys(this.state.users).length > 0) {
        return;
      }

      if (authUser.reviewerUser) {
        const broadcasterUserStaticInfo = staticData.data.broadcasterUserStaticInfo;

        allContactableUsers = Object.keys(broadcasterUserStaticInfo)
          .map((item) => broadcasterUserStaticInfo[item].submitterContacts)
          .flat()
          .filter(({ contactable, status }) => contactable === 'true' && status === '1')
          .map(({ id, firstName, lastName }) => ({
            userId: id,
            label: `${firstName} ${lastName}`
          }))
          .sort((a, b) => 2 * (a.label.toLowerCase() > b.label.toLowerCase()) - 1);
      } else {
        const { broadcasters } = this.props.staticData.data.submitterUserStaticInfo.submitterUserStaticData;

        allContactableUsers = broadcasters
          .filter(({ broadcasterID }) => this.ifBroadcasterContactable(broadcasterID))
          .reduce(
            (contactableUsers, { broadcasterUsers, departments }) =>
              contactableUsers.concat(
                broadcasterUsers
                  .filter(({ contactable }) => contactable)
                  .map((b) => ({ ...b, deptNames: departments.map(({ departmentName }) => departmentName).join(', ') }))
              ),
            []
          )
          .reduce((accum, { id, firstName, lastName, deptNames, status }) => {
            if (status === '1') {
              accum.push({
                userId: id,
                label: `${firstName} ${lastName} (${deptNames})`
              });
            }
            return accum;
          }, [])
          .sort((a, b) => 2 * (a.label.toLowerCase() > b.label.toLowerCase()) - 1);
      }

      const formatAllSelUsers = allContactableUsers.map(({ userId, label }) => ({ value: label, label, userId }));
      const selectedUsers = this.props.recipients.map((recipientId) =>
        allContactableUsers.find(({ userId }) => userId.toString() === recipientId.toString())
      );

      this.setState({
        users: formatAllSelUsers,
        selectedUsers
      });
    }
  };

  ifBroadcasterContactable = (id) => {
    const { allowedMessageDestinations } = this.props.authUser.submitterUserInfo.submitterUser.privileges;
    return allowedMessageDestinations.some(({ broadcasterID }) => broadcasterID.toString() === id.toString());
  };

  handleChange = (value) => {
    this.setState({
      selectedUsers: value
    });
    this.props.onChange(value);
  };

  render() {
    return (
      <Dropdown
        className="custom-select"
        name="select-type"
        placeholder="Start typing to select recipient(s)"
        isMulti
        isClearable
        isSearchable
        options={this.state.users}
        value={this.state.selectedUsers}
        onChange={this.handleChange}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  authUser: state.authUser,
  staticData: state.staticData
});

export default connect(mapStateToProps)(FindRecipients);
