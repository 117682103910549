import _ from 'lodash';

export const getMovieByID = (movieId, broadcasterType, staticData) => {
  const movie = staticData.data.broadcasterUserStaticInfo[broadcasterType].movies.find((movie) => movie.id === movieId);
  return movie || {};
};

// TODO: Remove this and make a new one in viewer/Claims/ClaimsUtils.js
export const getAccountNameByID = (id, staticData) => {
  let name = '';

  _.forEach(staticData.data.broadcasterUserStaticInfo, (dept) => {
    _.forEach(dept.submitters, (submitter) => {
      if (submitter.id === id) name = submitter.name;
    });
  });

  return name;
};
