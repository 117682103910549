/*
 * We combine all reducers into a single object before updated data is dispatched (sent) to store
 * Your entire applications state (store) is just whatever gets returned from all your reducers
 * */

import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import * as authenticationReducers from './AuthenticationReducers';
import * as packageReducers from './PackageReducers';
import * as dashboardReducers from './DashboardReducers';
import * as settingsReducers from './SettingsReducers';
import * as profileReducers from './ProfileReducers';
import * as localizationReducers from './LocalizationReducers';
import * as responseReducers from './ResponseReducers';
import * as reportReducers from './ReportReducers';
import * as tourReducers from './TourReducers';
import * as playbackReducers from './PlaybackReducers';
import * as reviewPerAssetReducers from './ReviewPerAssetReducers';
import * as errorReducer from './ErrorReducers';
import * as messageReducers from './MessageReducers';
import * as newMessageReducers from './NewMessageReducers';
import * as productReducers from './CreateProductReducers';
import * as pdfViewer from './PdfViewerReducer';

const CombineReducers = combineReducers({
  //Auth
  authUser: authenticationReducers.LogInReducer,
  resetData: authenticationReducers.passwordResetReducer,
  newPassData: authenticationReducers.newPasswordReducer,
  usePassReset: authenticationReducers.usePassResetReducer,

  //Dashboard
  recall: dashboardReducers.RecallReducer,
  inbox: dashboardReducers.InboxReducer,
  staticData: dashboardReducers.StaticDataReducer,
  deletedPackage: dashboardReducers.ToggleDeleteConfirmationReducers,

  viewerPlayer: dashboardReducers.ViewerPlayerReducers,
  reviewerAssign: dashboardReducers.ReviewerAssignReducers,

  deleteReviewerPackage: dashboardReducers.DeleteReviewerPackageReducers,

  grab: dashboardReducers.GrabReducers,

  // Messages
  messages: messageReducers.MessageReducers,
  newMessage: newMessageReducers.NewMessageReducers,

  //Package
  recipients: packageReducers.RecipientsReducer,
  packages: packageReducers.PackageReducer,
  uploadSubmissionFile: packageReducers.UploadSubmissionFileReducer,
  uploadAttachmentFile: packageReducers.UploadAttachmentFileReducer,
  popup: packageReducers.PopupToggleReducer,
  cancelDialog: packageReducers.CancelPackageDialogReducer,

  relatedCodeWarning: packageReducers.RelatedCodeWarningReducer,

  relatedCodesPopup: packageReducers.RelatedCodesToggleReducer,
  saveMediaRequestID: packageReducers.SaveMediaRequestIDReducer,
  saveSubmissionReducer: packageReducers.SaveSubmissionReducer,
  reviewerAssignments: packageReducers.ReviewerAssignmentsReducer,
  mediaVersion: packageReducers.MediaVersionReducer,

  packageActionButtons: packageReducers.PackageActionButtonsReducer,

  loadingPackage: packageReducers.LoadingPackageReducer,

  createProduct: productReducers.CreateProductReducers,

  //Responses
  responses: responseReducers.ResponsesReducer,

  //Report
  report: reportReducers.ReportReducers,

  //Settings
  userSettings: settingsReducers.UserSettingsReducer,
  version: settingsReducers.AppVersionReducer,
  localizedLabels: localizationReducers.LocalizationReducer,

  //Tour
  tour: tourReducers.TourReducer,

  //Site messages

  //Profile
  userProfile: profileReducers.UserProfileReducer,

  //Playback
  playback: playbackReducers.PlaybackReducer,

  //Review
  reviewPerAsset: reviewPerAssetReducers.ReviewPerAssetReducer,

  //Routing
  routing: routerReducer,

  //Error
  error: errorReducer.ErrorReducer,

  //PDF Viewer file
  pdfViewer: pdfViewer.PdfViewer
});

export default CombineReducers;
