import CombineReducers from 'src/reducers/CombineReducers';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import promise from 'redux-promise';

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25
});

export const store = createStore(CombineReducers, composeEnhancers(applyMiddleware(thunk, promise)));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
