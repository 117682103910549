import ActionTypes from '../actions/ActionTypes';

export const LocalizationReducer = (
  state = {
    notifications: '',
    dashboardColumns: '',
    packageManagement: '',
    loginValidation: '',
    deliveryStatus: '',
    requestStatus: '',
    uploadStatus: '',
    unifiedStatus: '',
    draftStatus: '',
    responses: '',
    demoMessages: '',
    profileMessages: '',
    inboxMessage: ''
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.LOCALIZED_DASHBOARD:
      return {
        ...state,
        dashboardColumns: action.payload
      };
    case ActionTypes.LOCALIZED_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      };
    case ActionTypes.LOCALIZED_PACKAGE_MANAGEMENT:
      return {
        ...state,
        packageManagement: action.payload
      };
    case ActionTypes.LOCALIZED_LOGIN_VALIDATION:
      return {
        ...state,
        loginValidation: action.payload
      };
    case ActionTypes.DELIVERY_STATUS_TEXT_SUCCESS:
      return {
        ...state,
        deliveryStatus: action.payload
      };
    case ActionTypes.REQUEST_STATUS_TEXT_SUCCESS:
      return {
        ...state,
        requestStatus: action.payload
      };
    case ActionTypes.UPLOAD_STATUS_TEXT_SUCCESS:
      return {
        ...state,
        uploadStatus: action.payload
      };
    case ActionTypes.UNIFIED_STATUS_TEXT_SUCCESS:
      return {
        ...state,
        unifiedStatus: action.payload
      };
    case ActionTypes.DRAFT_STATUS_TEXT_SUCCESS:
      return {
        ...state,
        draftStatus: action.payload
      };
    case ActionTypes.LOAD_DEMO_MESSAGE:
      return {
        ...state,
        error: '',
        demoMessages: action.payload
      };
    case ActionTypes.LOCALIZED_RESPONSES:
      return {
        ...state,
        responses: action.payload
      };
    case ActionTypes.LOCALIZED_INBOX_MESSAGES:
      return {
        ...state,
        inboxMessage: action.payload
      };
  }
  return state;
};
