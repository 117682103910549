import React, { Component } from 'react';
import { UploadIcon } from '@d3sw/one-ui-components/dist/Icons/UploadIcon';
import { toast } from '@d3sw/one-ui-components/dist/toast/toast';
import { v4 } from 'uuid';
import { doesSubmissionAlreadyExists } from '../../actions/PackageActions';
import './DragAndDrop.scss';

const bytesToMbs = (bytes) => (bytes / (1024 * 1024)).toFixed(2);

export class DragAndDrop extends Component {
  id = v4();
  dragCounter = 0;
  dropRef = React.createRef();
  state = { dragging: false };
  files = this.props;

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  handleDrag = (e) => this.preventDefault(e);

  handleDragIn = (e) => {
    this.preventDefault(e);
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };

  handleDragOut = (e) => {
    this.preventDefault(e);
    this.dragCounter--;
    this.dragCounter === 0 && this.setState({ dragging: false });
  };

  handleDrop = (e) => {
    this.preventDefault(e);
    if(this.props.disabled) return;

    const { fileTypes, maxMB, multiple, files: existingFiles } = this.props;
    const droppedFiles = e.dataTransfer ? e.dataTransfer.files : e.target.files;

    let invalidFiles = [];
    let tooLargeFiles = [];
    let duplicateFiles = [];
    let okFiles = [];

    if (droppedFiles && droppedFiles.length) {
      for (let i = 0; i < droppedFiles.length; i++) {
        const file = droppedFiles[i];
        const isTooBig = maxMB && bytesToMbs(file.size) > maxMB;
        const isInvalidType = fileTypes && !fileTypes.includes(file.type);
        const isDuplicate = doesSubmissionAlreadyExists(file, existingFiles);
        const shouldAdd = !isTooBig && !isInvalidType && !isDuplicate && (multiple || !existingFiles || !existingFiles.length);

        if (isTooBig) {
          tooLargeFiles.push(file);
        } else if (isInvalidType) {
          invalidFiles.push(file);
        } else if (isDuplicate) {
          duplicateFiles.push(file);
        } else if (shouldAdd) {
          okFiles.push(file);
        }
      }

      tooLargeFiles.length && toast.error(getLargeFileMessage(tooLargeFiles, maxMB), 'error');
      invalidFiles.length && toast.error(getInvalidFileTypeMessage(invalidFiles, fileTypes), 'error');
      duplicateFiles.length && toast.error(getDuplicateFileTypeMessage(duplicateFiles), 'error');

      this.setState({ dragging: false }, () => {
        try {
          e.dataTransfer && e.dataTransfer.clearData();
        } catch (err) {
          // This just needs to be caught for Firefox environments.
        }
        this.dragCounter = 0;
        this.props.handleDrop(
          okFiles.map((file) => ({
            file: file,
            name: file.name.split('.').slice(0, -1).join('.'),
            ext: file.name.split('.')[file.name.split('.').length - 1],
            type: file.type
          }))
        );
        okFiles = [];
      });
    }
  };

  preventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  reset = (e) => {
    e.dataTransfer && e.dataTransfer.clearData();
    this.dragCounter = 0;
  };

  render = () => {
    const { dragging } = this.state;
    const { maxMB, multiple, files, disabled } = this.props;

    return (
      <div className="ouic-drop-area" ref={this.dropRef}>
        <form className={`ouic-drag-and-drop ${dragging ? 'dragging' : ''} ${files && files.length ? 'dropped' : ''} ${disabled ? 'disabled' : ''}`}>
          <UploadIcon />
          <p>Drag and drop here to upload</p>
          <input
            type="file"
            id={this.id}
            className="browse"
            data-testid="drag-and-drop-input"
            multiple={multiple}
            onChange={this.handleDrop}
          />
          <p>
            or{' '}
            <label htmlFor={this.id} data-testid="drag-and-drop-label">
              Browse
            </label>
            {maxMB && <span className="text-muted mb-limit">({maxMB}MB limit)</span>}
          </p>
        </form>
      </div>
    );
  };
}

const getLargeFileMessage = (files, maxMB) => {
  return `${files
    .map((x) => x.name)
    .join(', ')
    .replace(/, ([^,]*)$/, ' and $1')} could not be added because ${files.length ? 'are' : 'is'} larger than ${maxMB}MB.`;
};

const getInvalidFileTypeMessage = (files, types = []) => {
  return `${files
    .map((x) => x.name)
    .join(', ')
    .replace(/, ([^,]*)$/, ' and $1')} could not be added because ${
    files.length ? 'are' : 'is'
  } not valid file types. Valid types are ${types.join(', ')}.`;
};

const getDuplicateFileTypeMessage = (files) => {
  return `Duplicate file names have been ignored - ${files.map((x) => x.name).join(', ')}`;
};

export default DragAndDrop;
