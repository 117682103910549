import React, { Component } from 'react';

// components
import CustomModal from '../Modals/CustomModal';

class CancelConfirm extends Component {
  render() {
    return (
      <CustomModal
        show={this.props.show}
        toggle={this.props.close}
        withButtons
        leftButtonLabel="No, go back"
        rigthButtonLabel="Yes, proceed"
        confirmFunction={this.props.proceed}
      >
        <div className="dialog">
          <div className="dialog-icon">
            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
          </div>
          <div className="dialog-text">
            <h4>Are you sure you want to cancel?</h4>
            <p>All unsaved changes will be lost if you proceed.</p>
          </div>
        </div>
      </CustomModal>
    );
  }
}

export default CancelConfirm;
