export const messageAttachment = {
  allowedSubmissions: [
    'application/msword', //.doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'text/csv', //.csv
    'application/vnd.ms-excel', //.xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //.xlsx
    'application/pdf', //.pdf
    'text/plain', // .txt
    'text/xml', //.xml
    'application/xml', //.xml
    'image/jpeg', // .jpeg .jpg
    'image/gif', // .gif
    'application/vnd.ms-powerpoint', // .ppt
    'application/zip' //.zip
  ]
};
