import { sortBy } from 'lodash';

const getExtension = (file) => file.substr(file.indexOf('.')).toLowerCase();

const orderByAssetType = (assetType, fileName) => {
  const extension = getExtension(fileName);

  return assetType.includes(extension);
};

const getSubmissionAssetName = (asset) => {
  if (asset && asset.mediaExportName != asset.name) {
    return asset.name;
  }

  if (asset && asset.mediaExportName && asset.name.includes('.')) {
    return asset.name;
  }

  if (asset && asset.mediaExportName && asset.mediaExportName.includes('.')) {
    return asset.mediaExportName;
  }

  if (asset && asset.mediaExportName && asset.fileName.includes('.')) {
    return asset.fileName;
  }

  // In case the asset does not have any extension we should return an empty string
  return '';
};

export const sortSubmissionAssets = (assets) => {
  const videoExtensions = ['.mov', '.mp4', '.avi', '.flv', '.mpeg', '.mpg', '.wmv', '.m4v'];
  const audioExtension = ['.mp3', '.aac', '.flac', '.aiff', '.m4a', '.ogg', '.wav', '.wma'];

  const videoFiles = assets.filter((asset) => orderByAssetType(videoExtensions, getSubmissionAssetName(asset)));
  const audioFiles = assets.filter((asset) => orderByAssetType(audioExtension, getSubmissionAssetName(asset)));

  const nonAudioOrVideoFiles = assets.filter((asset) => {
    const extension = getExtension(getSubmissionAssetName(asset));
    return !videoExtensions.includes(extension) && !audioExtension.includes(extension);
  });

  const sortAlphabetically = (asset) => getSubmissionAssetName(asset).toLowerCase();

  const result = [
    ...sortBy(videoFiles, sortAlphabetically),
    ...sortBy(audioFiles, sortAlphabetically),
    ...sortBy(nonAudioOrVideoFiles, sortAlphabetically)
  ];

  return result;
};

export const sortAttachmentAssets = (assets = []) => {
  const documentExtensions = ['.doc', '.docx', '.xls', '.xlsx', '.pdf', '.zip'];
  const imageExtensions = ['.jpg', '.jpeg', '.gif', '.bmp'];

  const documentFiles = assets.filter((asset) => orderByAssetType(documentExtensions, asset.fileName));
  const imagesFiles = assets.filter((asset) => orderByAssetType(imageExtensions, asset.fileName));

  const nonDocumentAndImageFiles = assets.filter((asset) => {
    const extension = getExtension(asset.fileName);
    return !documentExtensions.includes(extension) && !imageExtensions.includes(extension);
  });

  const sortAlphabetically = (asset) => asset.fileName.toLowerCase();

  const result = [
    ...sortBy(documentFiles, sortAlphabetically),
    ...sortBy(imagesFiles, sortAlphabetically),
    ...sortBy(nonDocumentAndImageFiles, sortAlphabetically)
  ];

  return result;
};
