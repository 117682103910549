import ActionTypes from '../actions/ActionTypes';

export const MessageReducers = (
  state = {
    unreadCount: 0,
    messageCount: 0,
    pageNumber: 1,
    totalPages: 1,
    messagesList: []
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.UNREAD_MESSAGE_COUNT: {
      return {
        ...state,
        unreadCount: action.payload
      };
    }
    case ActionTypes.MESSAGES_SUCCESS: {
      const { unreadCount, messageCount, pageNumber, totalPages, messagesList } = action.payload;
      return {
        unreadCount,
        messageCount,
        pageNumber,
        totalPages,
        messagesList: pageNumber === 1 ? messagesList : [...state.messagesList, ...messagesList]
      };
    }
    case ActionTypes.MARK_MESSAGE_AS_READ: {
      const { id } = action.payload;
      const index = state.messagesList.findIndex(({ messageID }) => parseInt(messageID, 10) === parseInt(id, 10));
      return {
        ...state,
        messagesList: state.messagesList.map((message, i) => (i === index ? { ...message, unread: false } : message)),
        unreadCount: state.unreadCount - 1
      };
    }
    case ActionTypes.CLEAR_MESSAGES: {
      return {
        unreadCount: 0,
        messageCount: 0,
        pageNumber: 1,
        totalPages: 1,
        messagesList: []
      };
    }
    case ActionTypes.SIGN_OUT: {
      return {
        unreadCount: 0,
        messageCount: 0,
        pageNumber: 1,
        totalPages: 1,
        messagesList: []
      };
    }
  }

  return state;
};
